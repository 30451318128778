import React, { useEffect, useState } from 'react'
import { 
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import { 
    Button, Dialog, DialogContent, DialogTitle, DialogActions, FormControl, CircularProgress,
    Checkbox, FormControlLabel, Tab, Tabs
} from "@material-ui/core"; 
import { API } from "aws-amplify";
import { useForm, Controller, Control } from "react-hook-form";
import { Candidate, ActionsInfo, CmsActionsInfo, CandidateActionsInfo } from '../../../types/CandidateTypes';
import TabPanel from '../../../components/TabPanel';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        actionDialogTabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            minWidth: 175
        },
        actionDialogTabPanel: {
            padding: 32
        }
    })
);

interface ActionsDialogProps {
    open: boolean,
    handleDialog: (open: boolean) => void
    user?: any | null
    candidateId: string
    getCandidateDetails: () => void
    cmsActions: Array<CmsActionsInfo>
    candidateActions: Array<CandidateActionsInfo>
}

interface ActionsForm {
    form: Array<CmsActionsInfo>
}

interface CandidateActionsForm {
    actionsToAdd: Array<CandidateActionsInfo>;
    actionsToDelete: Array<CandidateActionsInfo>
}

export function ActionsDialog({ open, handleDialog, user, candidateId, getCandidateDetails, cmsActions, candidateActions }: ActionsDialogProps) {

    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false) 
    const [error, setError] = useState<string>()

    const [selectedTab, setSelectedTab] = useState<number>(0)


    const { handleSubmit, control, setValue, reset } = useForm<ActionsForm>({
        shouldUnregister: false
    });

    useEffect(() => {
        if(cmsActions.length > 0 && open) {
            setSelectedTab(0)
            //binds data to form
            reset({ form: cmsActions })
        }
    }, [cmsActions, open])

    const onSubmit = (data: ActionsForm) => {
        if(user) {

            let newCandidateActions: CandidateActionsForm = {
                actionsToAdd: Array<CandidateActionsInfo>(),
                actionsToDelete: Array<CandidateActionsInfo>()
            }

            data.form.forEach((group: CmsActionsInfo) => {
                group.actions.forEach((action: ActionsInfo) => {
                    if(action.selected) {
                       if(!candidateActions.some((a: CandidateActionsInfo) => a.label === action.label && a.group === action.group)) {
                            let newAction: CandidateActionsInfo = {
                                sk: action.sk,
                                group: action.group,
                                label: action.label,
                                route: action.route,
                                description: action.description,
                                completed: false,
                            }
                            newCandidateActions.actionsToAdd.push(newAction)
                       } 
                    } else {
                        if(candidateActions.some((a: CandidateActionsInfo) => a.label === action.label && a.group === action.group)) {
                            let newAction: CandidateActionsInfo = {
                                sk: action.sk,
                                group: action.group,
                                label: action.label,
                                route: action.route,
                                description: action.description,
                                completed: false
                            }
                            newCandidateActions.actionsToDelete.push(newAction)
                       }
                    }
                })
            })

            console.log(newCandidateActions)

            setLoading(true)
            let params = {
                body: {
                    data: newCandidateActions,
                    user: user.username,
                    id: candidateId
                }
            }
            API.post("", "/candidates/actions/update", params)
            .then(() => {
                setLoading(false)
                handleDialog(false)
                getCandidateDetails()
            })
            .catch((error: any) => {
                setLoading(false)
                setError("Error: Unable to process request")
                console.log("Error: Unable to process request", error)
            })
        } else {
            setError("Error: Unable to process request")
        }
    }

    return (
        <Dialog fullWidth maxWidth={'md'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Candidate Actions
            </DialogTitle>
            <DialogContent>
                <div style={{display: "flex"}}>
                    <Tabs 
                        className={classes.actionDialogTabs}
                        orientation="vertical"
                        variant="scrollable"
                        value={selectedTab} 
                        onChange={(_, newValue) => setSelectedTab(newValue)}>

                        {cmsActions.map((group: CmsActionsInfo) => (
                            <Tab label={group.group} key={group.group} />
                        ))}
                    </Tabs>
                    
                    <div>
                        {cmsActions.map((tab: CmsActionsInfo, i: number) => (
                            <ActionsDialogTab 
                                key={i} 
                                tab={tab} 
                                i={i} 
                                selectedTab={selectedTab} 
                                control={control} 
                                setValue={setValue}
                                open={open}/>
                        ))}
                    </div>
                </div>
                
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red", marginRight: 4}}>{error}</span>
                }
                <Button color="primary" style={{width: 80}} onClick={() => handleDialog(false)}>
                    Cancel
                </Button>
                {loading ? 
                    <Button variant="contained" color="primary" style={{width: 80}}>
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button variant="contained" color="primary" style={{width: 80}} onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

interface ActionsDialogTabProps {
    selectedTab: number;
    i: number;
    tab: CmsActionsInfo;
    control: Control<ActionsForm>;
    setValue: any;
    open: boolean;
}

function ActionsDialogTab({ selectedTab, i, tab, control, setValue, open }: ActionsDialogTabProps) {

    const classes = useStyles()

    const [selectAll, setSelectAll] = useState<boolean>(false)

    useEffect(() => {
        if(tab && open) {
            for(let j = 0; j < tab.actions.length; j++) {
                if(tab.actions[j].selected === false) {
                    setSelectAll(true)
                    break
                }
            }
        }
    }, [tab, open])

    const handleSelectAll = (checked: boolean) => {
        if(checked) {
            setSelectAll(false)
            for(let j = 0; j < tab.actions.length; j++) {
                setValue(`form[${i}].actions[${j}].selected`, true)
            }
        } else {
            setSelectAll(true)
            for(let j = 0; j < tab.actions.length; j++) {
                setValue(`form[${i}].actions[${j}].selected`, false)
            }
        }
    }

    return (
        <TabPanel value={selectedTab} index={i} className={classes.actionDialogTabPanel}>
            <FormControlLabel
                control={
                    <Checkbox color="primary" checked={!selectAll} onChange={(_, checked) => handleSelectAll(checked)} disabled={i > 0} />
                }
                label={
                    <strong>Select All</strong>
                }
            />

            <FormControl style={{width: "100%"}}>
                {i === 0 &&
                    <FormControlLabel
                        control={
                            <Checkbox color="primary" checked={true} disabled={true} />
                        }
                        label="Registration Process"
                    />
                }
            
           
                {tab.actions.map((v: ActionsInfo, j: number) => (
                    <React.Fragment key={v.label}>
                        {v.display &&
                            <Controller
                                render={({ onChange, value }) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                onChange(e.target.checked)
                                            }} 
                                            checked={value}
                                            disabled={i > 0 || v.completed}/>
                                        } 
                                        label={v.label} />
                                )}
                                name={`form[${i}].actions[${j}].selected`}
                                control={control}
                                defaultValue={false}
                            />
                        }   
                    </React.Fragment>
                ))}
            </FormControl>

        </TabPanel>
    )
}