import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {
    Card, CardHeader, CardContent, Button, IconButton, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, TextField
} from "@material-ui/core";
import AddNoteDialog from '../dialogs/AddNotesDialog';
import { Add, GetApp } from '@material-ui/icons';
import { API, Storage } from "aws-amplify";
import { CandidateDetailDto, CandidateNoteInfo as CandidateNoteInfo } from '../../../types/CandidateTypes';
import { FormatDateTime } from '../../../utils';
import useFetch from '../../../hooks/api/useFetch';
import { Controller, useForm } from 'react-hook-form';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        downloadIcon: {
            '&.MuiIconButton-root': {
                padding: 0
            },
            marginLeft: 4
        },
        formInput: {
            width: "100%", 
            marginBottom: 16
        },
        label: {
            marginBottom: 6
        },
    })
);

interface CandidateNotesTabProps {
    active: boolean
    candidateId: string | undefined
    user: any | null
    // notes: Array<CandidateNoteInfo>
    // getNotes: () => void
    // loading: boolean
    // setLoading: (loading: boolean) => void;
}

interface AddNoteForm {
    category: string
    note: string
    filename: string
    fileUrl: string
}


export default function CandidateNotesTab({ active, candidateId, user }: CandidateNotesTabProps) {

    const url = `/candidates/${candidateId}/notes`
    const classes = useStyles()

    const [openAddNoteDialog, setOpenAddNoteDialog] = useState<boolean>(false)
    const [error, setError] = useState<string>();

    const { data, loading, error:fetchError, reFetch } = useFetch<Array<CandidateNoteInfo>>(url)
    
    const { handleSubmit, control, errors, setValue, reset } = useForm<AddNoteForm>({
        shouldUnregister: false
    });

    const onOpenDialogClick = ()=>{
        reset()
        setOpenAddNoteDialog(true)
    }

    const handleClose = () => {
        setOpenAddNoteDialog(false);
        
      };
      
      console.log(user)

      const onSubmit = (data: AddNoteForm)=> {
        if (user && candidateId) {

            var params = {
                body: {
                    ...data,
                    candidateId: candidateId,
                    username:user.username
                }
            }
            API.post("", url, params)
            .then(() => {
                setOpenAddNoteDialog(false)
                reFetch()
            })
            .catch((error: any) => {
                console.log(error)
                setError(error)
            }).finally(()=>{
                
            })
        }
    }


    if (loading) return <div>Loading...</div>

    if (fetchError) console.log(fetchError)

    return (
        <div>
            <div style={{ display: "flex", marginTop: 8 }}>
                <div style={{ flexGrow: 1 }}></div>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onOpenDialogClick} >
                        <Add />
                        Add Note
                    </Button>
                </div>
            </div>

            {data && data.map((note: CandidateNoteInfo) => (
                <Card style={{ marginTop: 16 }} key={note.id}>
                    <CardContent>
                        <div>
                            {note.note}
                        </div>
                        <div style={{ display: "flex", marginTop: 16 }}>
                            <div style={{ flexGrow: 1 }}>
                                {/* {note.fileUrl &&
                                    <>
                                        <span>Uploaded File: <strong>{note.filename}</strong></span>
                                        <IconButton className={classes.downloadIcon} onClick={() => downloadFile(note.fileUrl)}>
                                            <GetApp />
                                        </IconButton>
                                    </>
                                } */}
                            </div>
                            <div>
                                {`${note.createdBy} - ${FormatDateTime(note.createdAt)}`}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            ))}

            {/* <AddNoteDialog 
                openDialog={openAddNoteDialog} 
                handleNoteDialog={setOpenAddNoteDialog}
                user={user}
                candidateId={candidateId}
                getNotes={reFetch} /> */}

            <Dialog fullWidth maxWidth={'sm'} open={openAddNoteDialog} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1 }}>
                            Add Note
                        </div>
                    </div>

                </DialogTitle>
                <DialogContent>


                    <FormControl
                        error={Boolean(errors.note)}
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>Note detail</FormLabel>

                        <Controller
                            as={
                                <TextField
                                    placeholder="Enter note detail"
                                    fullWidth
                                    variant="filled"
                                    multiline
                                    rows={10}
                                    error={errors.note ? true : false}
                                    helperText={errors.note?.message} />
                            }
                            name="note"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}
                        />
                    </FormControl>


                </DialogContent>
                <DialogActions>
                    {error &&
                        <span style={{ color: "red" }}>{error}</span>
                    }
                    <Button onClick={() => handleClose()} color="secondary">
                        Cancel
                    </Button>
                    {loading ?
                        <Button variant="contained" color="secondary">
                            <CircularProgress size={25} style={{ color: "white" }} />
                        </Button>
                        :
                        <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                            Add
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}
