import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import { CandidateDetailDto } from '../../types/CandidateTypes';
import { FormatDateTime } from "../../utils";

export const GenerateCandidateDataPDF = async (data: CandidateDetailDto | undefined, setAccordionExpanded: (v: boolean) => void, currentTab: number, setCurrentTab: (tab: number) => void, setOpenActionsDialog: (open: boolean) => void): Promise<boolean> => {
    if(data) {

      const pdf = new jsPDF('p', 'mm', 'a4');

      if(data.detail) {
        pdf.text(`Lowie Candidate Data - ${data.detail.firstName} ${data.detail.surname}`, 105, 20, { align: "center"} )
      }

      if(data.detail.registrationStep === 8) {

        pdf.text("Personal Information", 12.5, 40)
        const profile = document.getElementById('personal-info-div')!;
        try {
          let profileImg = await domtoimage.toPng(profile)
          pdf.addImage(profileImg, 'PNG', 10, 45, 190, 110, undefined, "FAST");    
        } catch(error) {
          console.log("Error: error loading profile", error)
          return true
        }
        
        pdf.addPage('a4', 'p')

        setAccordionExpanded(true)
        pdf.text("Additional Information", 10, 20)
        const additionalInfo = document.getElementById('additional-info-div')!;
        const additionalInfoHeight = additionalInfo.clientHeight;
        const additionalInfoWidth = additionalInfo.clientWidth
        let additionalInfoRatio = additionalInfoWidth / 190;
        let additionalInfoImgHeight = additionalInfoHeight / additionalInfoRatio
        try{
          let additionalInfoImg = await domtoimage.toPng(additionalInfo)
          pdf.addImage(additionalInfoImg, 'PNG', 10, 25, 190, additionalInfoImgHeight, undefined, "FAST");
        } catch(error) {
          console.log("Error: error loading additional info", error)
          return true
        }
        
        const rightToWork = document.getElementById('right-to-work-div')!;
        const rightToWorkHeight = rightToWork.clientHeight;
        const rightToWorkWidth = rightToWork.clientWidth
        let rightToWorkRatio = rightToWorkWidth / 190;
        let rightToWorkImgHeight = rightToWorkHeight / rightToWorkRatio
        
        let currentY = 25 + additionalInfoHeight + 10
        
        try{
          let rightToWorkImg = await domtoimage.toPng(rightToWork, { cacheBust: true })
          
          if(currentY + rightToWorkImgHeight > 260) {
            pdf.addPage('a4', 'p')
            pdf.text("Right to Work", 10, 20)
            pdf.addImage(rightToWorkImg, 'PNG', 10, 25, 190, rightToWorkImgHeight, undefined, "FAST");
            currentY = 25 + rightToWorkImgHeight + 10
          } else {
            pdf.text("Right to Work", 10, currentY)
            pdf.addImage(rightToWorkImg, 'PNG', 10, currentY + 5, 190, rightToWorkImgHeight, undefined, "FAST");
            currentY = currentY + 5 + rightToWorkImgHeight + 10
          }
        } catch(error) {
          console.log("Error: error loading right to work info", error)
          return true
        }
        
        const qualifications = document.getElementById('qualifications-div')!;
        const qualificationsHeight = qualifications.clientHeight;
        const qualificationsWidth = qualifications.clientWidth
        let qualificationsRatio = qualificationsWidth / 190;
        let qualificationsImgHeight = qualificationsHeight / qualificationsRatio

        try {
          let qualificationsImg = await domtoimage.toPng(qualifications, { cacheBust: true })

          if(currentY + qualificationsImgHeight > 260) {
            pdf.addPage('a4', 'p')
            pdf.text("Qualifications", 10, 20)
            pdf.addImage(qualificationsImg, 'PNG', 10, 25, 190, qualificationsImgHeight, undefined, "FAST");
            currentY = 25 + qualificationsImgHeight + 10
          } else {
            pdf.text("Qualifications", 10, currentY)
            pdf.addImage(qualificationsImg, 'PNG', 10, currentY + 5, 190, qualificationsImgHeight, undefined, "FAST");
            currentY = currentY + 5 + qualificationsImgHeight + 10
          }
        } catch(error) {
          console.log("Error: error loading qualifications info", error)
          return true
        }
        

        if(data.workHistory) {
          if(data.workHistory.workHistory.length > 0) {
            const workHistory = document.getElementById('work-history-div')!;
            const workHistoryHeight = workHistory.clientHeight;
            const workHistoryWidth = workHistory.clientWidth
            let workHistoryRatio = workHistoryWidth / 190;
            let workHistoryImgHeight = workHistoryHeight / workHistoryRatio
            
            try {
              let workHistoryImg = await domtoimage.toPng(workHistory)
    
              if(currentY + workHistoryImgHeight > 260) {
                pdf.addPage('a4', 'p')
                pdf.text("Work History", 10, 20)
                pdf.addImage(workHistoryImg, 'PNG', 10, 25, 190, workHistoryImgHeight, undefined, "FAST");
                currentY = 25 + workHistoryImgHeight + 10
              } else {
                pdf.text("Work History", 10, currentY)
                pdf.addImage(workHistoryImg, 'PNG', 10, currentY + 5, 190, workHistoryImgHeight, undefined, "FAST");
                currentY = currentY + 5 + workHistoryImgHeight + 10
              }
            } catch(error) {
              console.log("Error: error loading work history info", error)
              return true
            }
          }
        }

        setAccordionExpanded(false)
      }

      for(let i = 0; i < data.candidateActions.length; i++) {
        if(data.candidateActions[i].group === "General" &&
          data.candidateActions[i].label === "Industrial Test" &&
          data.candidateActions[i].completed) {
            
          setCurrentTab(0)
          setAccordionExpanded(true)
        
          const industrialTest = document.getElementById('industrial-test-div')!;
          const industrialTestHeight = industrialTest.clientHeight;
          const industrialTestWidth = industrialTest.clientWidth
          let industrialTestRatio = industrialTestWidth / 190;
          let industrialTestImgHeight = industrialTestHeight / industrialTestRatio

          try {
            let industrialTestImg = await domtoimage.toPng(industrialTest)
            pdf.addPage('a4', 'p')
            pdf.text("Industrial Test", 10, 20)
            pdf.addImage(industrialTestImg, 'PNG', 10, 25, 190, industrialTestImgHeight, undefined, "FAST");

          } catch(error) {
            console.log("Error: error loading industrial test info", error)
            return true
          }
          
          setAccordionExpanded(false)
          break
        }
      }

      for(let i = 0; i < data.candidateActions.length; i++) {
        if(data.candidateActions[i].group === "General" &&
          data.candidateActions[i].label === "Job Placement" &&
          data.candidateActions[i].completed) {

          setCurrentTab(0)
          setAccordionExpanded(true)
        
          const ird = document.getElementById('ird-div')!;
          const irdHeight = ird.clientHeight;
          const irdWidth = ird.clientWidth
          let irdRatio = irdWidth / 190;
          let irdImgHeight = irdHeight / irdRatio

          try{
            let irdImg = await domtoimage.toPng(ird)
            pdf.addPage('a4', 'p')
            pdf.text("IRD Form", 10, 20)
            pdf.addImage(irdImg, 'PNG', 10, 25, 190, irdImgHeight, undefined, "FAST");
          } catch(error) {
            console.log("Error: error loading ird form info", error)
            return true
          }

          

          const payroll = document.getElementById('payroll-div')!;
          const payrollHeight = payroll.clientHeight;
          const payrollWidth = payroll.clientWidth
          let payrollRatio = payrollWidth / 190;
          let payrollImgHeight = payrollHeight / payrollRatio

          try{
            let payrollImg = await domtoimage.toPng(payroll)
            pdf.addPage('a4', 'p')
            pdf.text("Payroll Form", 10, 20)
            pdf.addImage(payrollImg, 'PNG', 10, 25, 190, payrollImgHeight, undefined, "FAST");
          } catch(error) {
            console.log("Error: error loading payroll form info", error)
            return true
          }

          setAccordionExpanded(false)
          break
        }
      }

      if(data.applications) {
        if(data.applications.length > 0) {
          setCurrentTab(1)
          const application = document.getElementById('applications-div')!;
          const applicationHeight = application.clientHeight;
          const applicationWidth = application.clientWidth
          let applicationRatio = applicationWidth / 190;
          let applicationImgHeight = applicationHeight / applicationRatio

          try{
            let applicationImg = await domtoimage.toPng(application)
            pdf.addPage('a4', 'p')
            pdf.text("Applications", 10, 20)
            pdf.addImage(applicationImg, 'PNG', 10, 25, 190, applicationImgHeight, undefined, "FAST");
          } catch(error) {
            console.log("Error: error loading applications info", error)
            return true
          }
        }
      }

      // if(data.interviews) {
      //   if(data.interviews.length > 0) {
      //     setCurrentTab(2)
      //     setAccordionExpanded(true)
      //     //wait 100ms for form to load
      //     await new Promise(r => setTimeout(r, 100));
      //     for(let i = 0; i < data.interviews.length; i++) {
      //       const interview = document.getElementById(`interview${i}-div`)!;
      //       if(interview) {
      //         const interviewHeight = interview.clientHeight;
      //         const interviewWidth = interview.clientWidth
      //         let interviewRatio = interviewHeight / 260;
      //         let interviewImgWidth = interviewWidth / interviewRatio
  
      //         try {
      //           let interviewImg = await domtoimage.toPng(interview)
      //           pdf.addPage('a4', 'p')
      //           pdf.text(`Interview ${data.interviews[i].jobRef} - ${FormatDateTime(data.interviews[i].createdAt)}`, 10, 20)
      //           pdf.addImage(interviewImg, 'PNG', 10, 25, interviewImgWidth, 260, undefined, "FAST");
      //         } catch(error) {
      //           console.log("Error: error loading interviews info", error)
      //           return true
      //         }
      //       }
      //     }
      //     setAccordionExpanded(false)
      //   }
      // }
      pdf.save(`${data.detail.firstName}-${data.detail.surname}-lowie-data.pdf`);

      setCurrentTab(currentTab)
      setOpenActionsDialog(false)
      return false
    }
    
    return true
}