import React, { useState } from 'react'
import { 
    Button, Dialog, DialogTitle, DialogContent, DialogActions, 
    CircularProgress
} from "@material-ui/core";
import { API } from "aws-amplify";
import { SkillGroup } from '../../../types/SkillTypes';

interface DeleteGroupDialogProps {
    openDialog: boolean
    handleDeleteDialog: (open: boolean) => void
    group?: string
    skills: Array<SkillGroup>
    setSkills: (skills: Array<SkillGroup>) => void
}

export default function DeleteGroupDialog({ openDialog, handleDeleteDialog, skills, group, setSkills }: DeleteGroupDialogProps) {

    const [loading, setLoading] = useState<boolean>(false) 

    function handleDeleteGroup() {
        setLoading(true)

        if(skills.length !== 0) {
            let newGroups = skills.filter((x: SkillGroup) => x.Group !== group)
            skills = newGroups
        }
        
        const params = {
            body:{
                data: skills,
            }
        };

        API.post("", "/skills/setSkills", params)
        .then((response: any) => {
            setLoading(false)
            handleDeleteDialog(false)
            setSkills(skills)
        })
        .catch((error: any) => {
            console.log("ERROR: posting skills", error)
            setLoading(false)
        })
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleDeleteDialog(false)}>
            <DialogTitle>
                Delete Group
            </DialogTitle>
            <DialogContent>
                {group ?
                    <span>Are you sure you want to delete {group} skill group. All skills in this group will also be deleted</span>
                :
                    <span>Are you sure you want to delete this skill group. All skills in this group will also be deleted</span>
                }
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleDeleteDialog(false)}>
                    Cancel
                </Button>
                {loading ? 
                    <Button variant="contained" color="primary">
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button onClick={() => handleDeleteGroup()} variant="contained" color="primary">
                        Delete
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}