import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify, {Auth} from 'aws-amplify';

Amplify.configure({
  Auth: {
    identityPoolId: 'ap-southeast-2:47e1144a-cdae-4eac-b59f-a41ab2a09648', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'ap-southeast-2_LlGWpWyrO', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '4kjempqohrp26nn637o08srl2o', //OPTIONAL - Amazon Cognito Web Client ID
     // OPTIONAL - Hosted UI configuration
    // oauth: {
    //     domain: 'lowie-crm.auth.ap-southeast-2.amazoncognito.com',
    //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    //     redirectSignIn: 'https://localhost:3000/',
    //     redirectSignOut: 'https://localhost:3000/signout/',
    //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },
    federatedTarged: "COGNITO_USER_POOLS"
  },
  API: {
    endpoints: [
        {
            name: "",
            // endpoint: "https://1tpklpjj74.execute-api.ap-southeast-2.amazonaws.com/Prod/api",
            endpoint: "/api",
            custom_header: async () => { 
              return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
          },
          //   service: "lambda",
          region: "ap-southeast-2"
        }
    ]
  },
  Storage: {
    AWSS3: {
        bucket: 'lr-onboarding-candidate-files',
        region: 'ap-southeast-2',
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
