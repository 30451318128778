import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { 
    Divider, TableRow, TableCell, Paper,
    Button, Chip, TextField, LinearProgress
} from "@material-ui/core"; 
import { Autocomplete } from "@material-ui/lab";
import { Search, Cancel } from "@material-ui/icons"
import { useHistory } from 'react-router-dom'
import { API } from "aws-amplify";
import { SkillsDto, SkillGroup } from '../../types/SkillTypes';
import { Candidate, SkillType } from '../../types/CandidateTypes';
import CandidateTable from "./CandidateTable";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        table: {
            minWidth: 650
        },
        margin: {
            margin: theme.spacing(1),
        },
        input: {
            width: 350
        },
        skillSearchButton: {
            marginTop: 16,
            marginBottom: 8
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        skillSearchAutocomplete: {
            marginTop: 16, 
            marginBottom: 8,
            marginRight: 8,
            width: 400
        },
        selectedSkillChips: {
            display: "flex",
            flexWrap: "wrap"
        },
        selectedSkillChip: {
            marginRight: 8,
            marginBottom: 8,
            backgroundColor: "#fff9c4"
        }
    })
);

const UseFocus = (): [React.RefObject<HTMLInputElement>, () => void] => {
	const htmlElRef = useRef<HTMLInputElement>(null)
	const setFocus = () => {htmlElRef.current && htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ] 
}

export default function Candidates() {
    const classes = useStyles()

    const [candidatesList, setCandidatesList] = useState<Array<Candidate>>([])

    const [loading, setLoading] = useState<boolean>(false)
    const [skills, setSkills] = useState<Array<SkillType>>([])
    const [selectedTags, setSelectedTags] = useState<Array<string>>([])
    const [selectedSkills, setSelectedSkills] = useState<Array<SkillType>>([])
    const [skillsLoading, setSkillsLoading] = useState<boolean>(false)
    const [initalLoad, setInitalLoad] = useState<boolean>(false)

    const [skillInputValue, setSkillInputValue] = useState<string>('skill:')
    const [showSkillsAutocomplete, setShowSkillsAutocomplete] = useState<boolean>(false)
    const [showAttributeAutocomplete, setShowAttributeAutocomplete] = useState<boolean>(true)
    const [showOtherAutocomplete, setShowOtherAutocomplete] = useState<boolean>(false)
    const [selectedAttribute, setSelectedAttribute] = useState<string>()
    const [otherInputValue, setOtherInputValue] = useState<string>()

    const [skillsAutoCompleteRef, setSkillsAutoCompleteFocus] = UseFocus()
    const [attributeAutoCompleteRef, setAttributeAutoCompleteFocus] = UseFocus()
    const [otherAutoCompleteRef, setOtherAutoCompleteFocus] = UseFocus()

    const [shouldFocus, setShouldFocus] = useState<boolean>(false)

    const [skillSearch, setSkillSearch] = useState<boolean>(false)

    useEffect(() => {

        let jsonSkills = localStorage.getItem("selectedTags");
        let storedSkills: Array<string> = []
        if(jsonSkills) {
            storedSkills = JSON.parse(jsonSkills)
            setSelectedTags(storedSkills)
        }

        getSkills()

        if(storedSkills.length > 0) {
            getCandidatesBySkillSearch(storedSkills)
        }

        setInitalLoad(true)
    }, []);

    useEffect(() => {
        if(selectedTags.length === 0 && initalLoad) {
            setSelectedAttribute(undefined)
            setShowOtherAutocomplete(false)
            setShowSkillsAutocomplete(false)
            setShowAttributeAutocomplete(true)
            setShouldFocus(false)
            setLoading(true)
            setSkillSearch(false)
            API.get("", "/candidates", {})
            .then((data: Array<Candidate>) => {
                setCandidatesList(data);
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("ERROR: fetching all candidates", error);
                setLoading(false)
            })
        }
    }, [selectedTags, initalLoad])

    useEffect(() => {
        if(showSkillsAutocomplete) {
            setSkillsAutoCompleteFocus()
        }
    }, [showSkillsAutocomplete])

    useEffect(() => {
        if(showAttributeAutocomplete && shouldFocus) {
            setAttributeAutoCompleteFocus()
        }
    }, [showAttributeAutocomplete, shouldFocus])

    useEffect(() => {
        if(showOtherAutocomplete) {
            setOtherAutoCompleteFocus()
        }
    }, [showOtherAutocomplete])

    const getSkills = () => {
        setSkillsLoading(true)
        API.get("", "/skills", {})
        .then((response: SkillsDto) => {
            if(response) {
                handleSkills(response.skills)
            }
        })
        .catch((error: any) => {
            console.log("Error fetching skills", error)
            setSkillsLoading(false)
        })
    }

    const getCandidatesBySkillSearch = (skills: Array<string>) => {
        var params = {
            body: {
                data: skills
            }
        }
        setLoading(true)
        setSkillSearch(true)
        API.post("", "/candidates/search/skills", params)
        .then((data: Array<Candidate>) => {
            let list = data.sort((a,b) => (a.score > b.score) ? -1 : 1)
            // setCandidates(list);
            setCandidatesList(list);
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("ERROR: fetching candidates by skill search", error);
            setLoading(false)
        })
    }

    const getCandidates = () => {
        setLoading(true)
        API.get("", "/candidates", {})
        .then((data: Array<Candidate>) => {
            setCandidatesList(data);
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("ERROR: fetching all candidates", error);
            setLoading(false)
        })
    }

    const handleSkills = (skills: Array<SkillGroup>) => {
        let allSkills: Array<SkillType> = []
        skills.forEach((skillGroup: SkillGroup) => {
            skillGroup.Skills.forEach((skill: string) => {
                allSkills.push({skill: `skill:${skill}`, group: skillGroup.Group})
            })
        })

        setSkills(allSkills)
        setSkillsLoading(false)

        let jsonSkills = localStorage.getItem("selectedTags");
        let storedSkills: Array<string> = []
        if(jsonSkills) {
            storedSkills = JSON.parse(jsonSkills)
            let clickedSkills: Array<SkillType> = []
            storedSkills.forEach((s: string) => {
                for(let i = 0; i < allSkills.length; i++) {
                    if(allSkills[i].skill === s) {
                        clickedSkills.push(allSkills[i])
                    }
                }
            })
            setSelectedSkills(clickedSkills)
        }
    }

    const handleDeleteSkill = (skill: string) => {
        let newSkills = selectedTags.filter((s: string) => (s !== skill))
        setSelectedTags(newSkills)
        localStorage.setItem('selectedTags', JSON.stringify(newSkills))

        setSelectedSkills(s => s.filter((ss: SkillType) => ss.skill !== skill))
    }

    return (
        <div>
                <div>
                    <div className={classes.header}>
                        <h2 style={{flexGrow: 1}}>
                            Candidates
                        </h2>

                        {showAttributeAutocomplete &&
                            <Autocomplete
                                className={classes.skillSearchAutocomplete}
                                options={['skill', 'firstName', 'lastName', 'location', 'email', 'phone']}
                                value={selectedAttribute}
                                openOnFocus
                                renderInput={(params) => (
                                    <TextField 
                                        {...params}
                                        placeholder="Select attribute to search on" 
                                        variant="outlined"
                                        inputRef={attributeAutoCompleteRef}/>
                                )}
                                renderTags={(_) => ""}
                                onChange={(_, newValue, reason) => {
                                    if(reason === "select-option") {
                                        if(newValue === "skill") {
                                            setSkillInputValue("skill:")
                                            setShowSkillsAutocomplete(true)
                                            setShowAttributeAutocomplete(false)
                                        } else {
                                            if(newValue) {
                                                setSelectedAttribute(newValue)
                                                setOtherInputValue(`${newValue}:`)
                                            }
                                            setShowOtherAutocomplete(true)
                                            setShowAttributeAutocomplete(false)
                                        }
                                    }
                                }} />
                        }

                        {showOtherAutocomplete &&
                            <Autocomplete
                                className={classes.skillSearchAutocomplete}
                                freeSolo
                                disableClearable
                                options={[]}
                                inputValue={otherInputValue}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params}
                                        variant="outlined"
                                        inputRef={otherAutoCompleteRef}/>
                                )}
                                renderTags={(_) => ""}
                                onChange={(_, newValue, reason) => {
                                    if(reason === "create-option" && newValue) {
                                        //Make sure that input is valid and check that value hasn't been entered already
                                        if(newValue.includes(`${selectedAttribute}:`) &&
                                            newValue.length > (selectedAttribute!.length + 1)) {
                                            
                                            setOtherInputValue(`${selectedAttribute}:`)
                                            let insert: boolean = true;
                                            for(let i = 0; i < selectedTags.length; i++) {
                                                insert = false
                                                if(selectedTags[i] === newValue) {
                                                    break
                                                }
                                                insert = true
                                            }
                                            if(insert) {
                                                setSelectedTags(s => [...s, newValue])
                                                localStorage.setItem('selectedTags', JSON.stringify([...selectedTags, newValue]))
                                            }
                                        } else {
                                            return
                                        }
                                    }
                                }}
                                onInputChange={(_, value, reason) => {
                                    if(reason === "input") {
                                        setOtherInputValue(value)
                                        if(value.length === 0) {
                                            setSelectedAttribute(undefined)
                                            setShowOtherAutocomplete(false)
                                            setShowAttributeAutocomplete(true)
                                            setShouldFocus(true)
                                        }
                                    }
                                }}
                                />
                        }

                        {showSkillsAutocomplete &&
                            <Autocomplete
                            className={classes.skillSearchAutocomplete}
                            multiple
                            disableClearable
                            openOnFocus
                            selectOnFocus={false}
                            loading={skillsLoading}
                            options={skills}
                            value={selectedSkills}
                            inputValue={skillInputValue}
                            getOptionSelected={(option, value) => {
                                return (option.skill === value.skill && option.group === value.group)
                            }}
                            groupBy={(option) => option.group}
                            renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    variant="outlined"
                                    inputRef={skillsAutoCompleteRef}/>
                            )}
                            renderTags={(_) => ""}
                            getOptionLabel={(option) => {
                                return option.skill
                            }}
                            onChange={(_, newValue, reason) => {
                                if(reason === "select-option") {
                                    setSkillInputValue("skill:")
                                    let newSkillList: Array<string> = [];
                                    let insert: boolean = true;
                                    newValue.forEach((s: SkillType) => {
                                        for(let i = 0; i < selectedTags.length; i++) {
                                            insert = false;
                                            if(selectedTags[i] === s.skill) {
                                                break;
                                            }
                                            insert = true;
                                        }
                                        if(insert) {
                                            newSkillList.push(s.skill)
                                        }
                                    })
                                    setSelectedTags(s => [...s, ...newSkillList])
                                    setSelectedSkills(newValue)
                                    localStorage.setItem('selectedTags', JSON.stringify([...selectedTags, ...newSkillList]))
                                }
                            }}
                            onInputChange={(_, value, reason) => {
                                if (reason === "input") {
                                    setSkillInputValue(value)
                                    if(value.length === 0) {
                                        setShowSkillsAutocomplete(false)
                                        setShowAttributeAutocomplete(true)
                                        setShouldFocus(true)
                                    }
                                }
                            }}
                            renderOption={(option) => option.skill.split(":")[1]} />
                        }
                        
                        <Button 
                            className={classes.skillSearchButton} 
                            variant="contained" 
                            color="primary" 
                            style={{marginRight: 8}} 
                            onClick={() => getCandidatesBySkillSearch(selectedTags)}
                            disabled={selectedTags.length === 0}>
                            <Search />
                        </Button>

                        <Button 
                            className={classes.skillSearchButton}
                            variant="contained" 
                            color="secondary"
                            disabled={selectedTags.length === 0}
                            onClick={() => {
                                localStorage.removeItem('selectedTags');
                                setSelectedTags([])
                                setSelectedSkills([])
                                setSelectedAttribute(undefined)
                                setShowSkillsAutocomplete(false)
                                setShowOtherAutocomplete(false)
                                setShowAttributeAutocomplete(true)
                                getCandidates()
                                setShouldFocus(false)
                            }}>
                            Clear All
                        </Button>
                        
                    </div>
                    <div className={classes.selectedSkillChips}>
                        {selectedTags.length !== 0 && selectedTags.map((skill: string) => (
                            <Chip 
                                key={skill} 
                                className={classes.selectedSkillChip} 
                                label={skill}
                                deleteIcon={<Cancel style={{color: "#f57f17"}}/>}
                                onDelete={() => {
                                    handleDeleteSkill(skill)
                                }}/>
                        ))}
                    </div>

                </div>

            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }
            <CandidateTable
                candidatesList={candidatesList}
                selectedTags={selectedTags}
                loading={loading}
                skillSearch={skillSearch}
            />
        </div>
    )
}
