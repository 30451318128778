import React, { useState } from 'react'
import { 
    Grid, Accordion, AccordionSummary, Chip, AccordionDetails, Button
} from '@material-ui/core';
import {
    ExpandMore
} from "@material-ui/icons"
import { CandidateInterviewDetail } from '../../../../types/InterviewTypes';
import { FormatDateTime } from '../../../../utils';
import CandidateInterviewDialog from '../../dialogs/CandidateInterviewDialog';
import CandidateInterviewEdit from "./CandidateInterviewEdit";
import CancelInterviewDialog from '../../../applications/CancelInterviewDialog';

interface InterviewDetailProps {
    interview: CandidateInterviewDetail;
    accordionExpanded: boolean;
    candidateId: string;
    user?: any | null;
    getInterviewData: () => void
}

export default function InterviewDetail({ interview, accordionExpanded, candidateId, user, getInterviewData }: InterviewDetailProps) {

    const [expanded, setExpanded] = useState<boolean>(false)
    const [openInterviewDialog, setOpenInterviewDialog] = useState<boolean>(false);
    const [openCancelInterviewDialog, setOpenCancelInterviewDialog] = useState<boolean>(false)
    const [activeStep, setActiveStep] = useState<number>(0);

    const getStatusColor = (status: string): string => {
        let color;
        switch (status) {
            case "INVITED": 
                color = "#26c6da";
                break;
            case "COMPLETED":
                color = "#9ccc65";
                break;
            case "CANCELLED":
                color = "#ef5350";
                break;
            default:
                color = "#ef5350"
        }
        return color;
    }

    const handleAddInterview = () => {
        localStorage.removeItem('step1Form')
        localStorage.removeItem('step2Form')
        localStorage.removeItem('step3Form')
        localStorage.removeItem('step4Form')
        localStorage.removeItem('step5Form')
        localStorage.removeItem('step6Form')
        localStorage.removeItem('step7Form')
        localStorage.removeItem('step8Form')
        setActiveStep(0)
        setOpenInterviewDialog(true);
    }

    return (
        <Grid item xs={12}>
            <Accordion
                expanded={accordionExpanded || expanded}
                onChange={() => setExpanded(!expanded)}>
                <AccordionSummary style={{display: "flex"}} expandIcon={<ExpandMore />}>
                    <div style={{flexGrow: 1}}>
                        <h3>
                            {FormatDateTime(interview.scheduledInterview.interviewAt)} - {interview.scheduledInterview.interviewTitle.split(" - ")[1]}
                        </h3>
                    </div>
                    <div style={{marginTop: 16}}>
                        {interview.scheduledInterview.status === "INVITED" &&
                            <Button variant="outlined" color="primary" style={{marginRight: 8}} onClick={() => setOpenCancelInterviewDialog(true)}>
                                Cancel Interview
                            </Button>
                        }
                        <Chip
                            label={interview.scheduledInterview.status}
                            style={{backgroundColor: getStatusColor(interview.scheduledInterview.status), color: "white", fontWeight: "bold"}}
                            size="small"
                        />

                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {interview.scheduledInterview.status === "INVITED" &&
                        <div>
                            <p>This interview hasn't been completed yet.</p>
                            <Button variant="contained" color="primary" onClick={() => handleAddInterview()}>
                                Conduct Interview
                            </Button>
                        </div>
                    }
                    {(interview.scheduledInterview.status === "COMPLETED" && interview.conductedInterview) &&
                        <CandidateInterviewEdit info={interview.conductedInterview} refreshInterviewList={getInterviewData} user={user?.username} candidateId={candidateId} />
                    }
                    {interview.scheduledInterview.status === "CANCELLED" &&
                        <div>
                            <p>This interview was cancelled</p>
                        </div>
                    }
                </AccordionDetails>
            </Accordion>

            <CandidateInterviewDialog
                candidateId={candidateId}
                user={user}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                openDialog={openInterviewDialog}
                handleDialog={setOpenInterviewDialog}
                getInterviews={getInterviewData}
                interview={interview} />

            <CancelInterviewDialog
                openDialog={openCancelInterviewDialog}
                handleDialog={setOpenCancelInterviewDialog}
                user={user}
                refreshData={getInterviewData}
                interview={interview.scheduledInterview}
            />

        </Grid>
    )
}
