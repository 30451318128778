import React from 'react'
import { 
    makeStyles, createStyles
} from '@material-ui/core/styles';
import { 
    TextField, FormControl, FormLabel, FormHelperText, Typography
} from "@material-ui/core"; 
import PlacesAutocomplete from 'react-places-autocomplete';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() => 
    createStyles({
        input: {
            width: "100%"
        },
        label: {
            marginBottom: 6
        },
        addressOptions: {
            zIndex: 2,
            position: "absolute",
            width: "-webkit-fill-available"
        },
    })
);

interface Props {
    addressInput: string
    readOnly?: boolean;
    setAddressInput: (address: string) => void;
    setAddressValue: (address: string | undefined) => void;
}

export default function GoogleAddressTextField({ addressInput, readOnly, setAddressInput, setAddressValue }: Props) {

    const classes = useStyles()

    const { errors, clearErrors, setValue } = useFormContext()

    return (
        <FormControl
            error={Boolean(errors.address)}
            className={classes.input}>

            <FormLabel className={classes.label}>Address</FormLabel>

            <PlacesAutocomplete 
                value={addressInput}
                onChange={(address: string) => {
                    setAddressInput(address)
                    clearErrors("address")
                    setAddressValue(undefined)
                }}
                onSelect={(_, placeID: string) => {
                    var request = {
                        placeId: placeID
                    };
            
                    var placeRequest: Promise<google.maps.places.PlaceResult> = new Promise((resolve, reject) => {
                        new google.maps.places.PlacesService(document.createElement('div')).getDetails(request, (place, status) => {
                            if (status === google.maps.places.PlacesServiceStatus.OK) {
                                resolve(place!);
                            } else { reject() };
                        });
                    })
                    placeRequest.then((result: google.maps.places.PlaceResult) => {
                        if(result && result?.formatted_address && result?.address_components) {
                            setAddressInput(result.formatted_address)
                            setAddressValue(result.formatted_address)

                            result.address_components.forEach((c) => {
                                if(c.types.includes("street_number")) {
                                    setValue("streetNumber", c.long_name)

                                } else if(c.types.includes("route")) {
                                    setValue("street", c.long_name)

                                } else if(c.types.includes("sublocality_level_1")) {
                                    setValue("suburb", c.long_name)

                                } else if(c.types.includes("locality")) {
                                    setValue("region", c.long_name)
                                
                                } else if(c.types.includes("country")) {
                                    setValue("country", c.long_name)

                                } else if(c.types.includes("postal_code")) {
                                    setValue("postcode", c.long_name)

                                }
                            })
                        }
                    })
                    .catch((error: any) => {
                        console.log("Error: ", error)
                    })
                }}
                searchOptions={{
                    componentRestrictions: { country: 'nz' },
                    types: ['address']
                }}
                onError={(_, clearSuggestions) => {
                    clearSuggestions()
                }}>

                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <TextField
                            fullWidth
                            variant="filled"
                            {...getInputProps({
                                placeholder: 'Enter address',
                                disabled: readOnly
                            })}
                        />
                        <div className={classes.addressOptions}>
                        {loading && 
                            <div style={{backgroundColor: '#ffffff', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa"}}>
                                <Typography variant="body1">
                                    Loading ...
                                </Typography>
                            </div>
                        }

                        {suggestions.map((suggestion: any, i: number) => {
                            let style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" }
                            : { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "0px solid #fafafa" };

                            if((i === suggestions.length - 1) && !suggestion.active) {
                                style = { backgroundColor: '#ffffff', cursor: 'pointer', padding: "6px 16px", borderLeft: "2px solid #fafafa", borderRight: "2px solid #fafafa", borderBottom: "2px solid #fafafa" }
                            }

                            return (
                                <div {...getSuggestionItemProps(suggestion, { style })} key={i}>
                                    <Typography variant="body1">{suggestion.description}</Typography>
                                </div>
                            );
                        })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
            <FormHelperText>
                {errors.address && "Invalid address"}
            </FormHelperText>
        </FormControl>
    )
}
