import React, { useState, useEffect } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Grid, Button, Typography, Dialog, DialogTitle, DialogContent, 
    DialogActions, FormControl, FormLabel, TextField, CircularProgress, 
    Select, MenuItem, FormControlLabel, Checkbox, FormHelperText
} from "@material-ui/core"; 
import { API } from "aws-amplify"
import { useForm, Controller } from "react-hook-form"; 
import { IsValidDate } from '../../utils';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { format } from "date-fns";
import { InterviewInfo, InterviewTemplateInfo } from '../../types/InterviewTypes';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            marginBottom: theme.spacing(2),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface EditInterviewInviteDialogProps {
    open: boolean
    handleDialog: (open: boolean) => void
    interview: InterviewInfo | undefined
    user?: any | null
    refreshData: () => void;
}


export default function EditInterviewInviteDialog({ open, handleDialog, interview, user, refreshData }: EditInterviewInviteDialogProps) {
    
    const classes = useStyles()

    const { handleSubmit, control, errors, watch, setError, clearErrors, setValue, getValues, reset } = useForm<InterviewInfo>({
        shouldUnregister: false
    });
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setApiError] = useState<string>()
    const [interviewTemplates, setInterviewTemplates] = useState<Array<InterviewTemplateInfo>>([])
    
    useEffect(() => {
        if(open) {

            setApiError(undefined)
            API.get("", "/cms/interview-templates", {})
            .then((response: Array<InterviewTemplateInfo>) => {
                if(response) {
                    setInterviewTemplates(response)
                    reset(interview)
                }
            })
            .catch((error: any) => {
                console.log("Error: calling interview templates api", error)
            })
        }
    }, [open])

    const onSubmit = (data: InterviewInfo) => {
        if(user) {

            if(Boolean(errors.interviewAt)) {
                return
            }

            setLoading(true)

            if(interview) {
                data.sk = interview.sk
                data.firstName = interview.firstName
                data.lastName = interview.lastName
                data.email = interview.email
                data.phone = interview.phone
                data.position = interview.position
                data.type = interview.type
            }
           
            let params = {
                body: {
                    username: user.username,
                    data: data,
                }
            }

            API.post("", `/candidates/invite/update`, params)
            .then(() => {
                setLoading(false)
                handleDialog(false)
                refreshData()
            })
            .catch((error: any) => {
                console.log("Unable to update interview invite", error)
                setLoading(false)
                setApiError("Unable to update interview invite")
            })
        } else {
            setApiError("Unable to update interview invite")
        }
    }

    const handleInterviewTemplate = () => {

        let template = getValues("location")
        
        let t = interviewTemplates.find((a: InterviewTemplateInfo) => a.sk === `INTERVIEW#${template}`)
        if(t) {
            let emailMessage = t.message
            let interviewDateStr = getValues("interviewAt")

            if(IsValidDate(interviewDateStr)) {
                let date = format(new Date(interviewDateStr), 'dd MMM yyyy')
                let time = format(new Date(interviewDateStr), 'hh:mm a')
    
                emailMessage = emailMessage.replace("{NAME}", interview!.firstName)
                emailMessage = emailMessage.replace("{DATE}", date)
                emailMessage = emailMessage.replace("{TIME}", time)
                // console.log(emailMessage)
                setValue("message", emailMessage)
            }

        } else {
            setValue("message", "")
        }
    }

    return (    
        <Dialog fullWidth maxWidth={'md'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Edit Candidate Interview Invite
            </DialogTitle>
            <DialogContent>
                {(interview && interview.position) &&
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Invite {`${interview.firstName} ${interview.lastName}`} to interview for {interview.position} position. Include message to be sent below.
                    </Typography>
                }
                {(interview && interview.type === "CANDIDATE") &&
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Invite {`${interview.firstName} ${interview.lastName}`} for interview. Include message to be sent below.
                    </Typography>
                }

                <FormControl
                    style={{marginBottom: 16}}
                    error={Boolean(errors.commsRequest)}>

                    {[{"label": "Email", "name": "isSendEmail"}, {"label": "SMS", "name": "isSendSms"}].map(item => (
                        <Controller
                            key={item.label}
                            render={(props) => (
                                <FormControlLabel 
                                    control={
                                        <Checkbox onChange={e => {
                                            props.onChange(e.target.checked)
                                        }} 
                                        checked={props.value}/>
                                    } 
                                    label={item.label} />
                            )}
                            name={`commsRequest.${item.name}`}
                            control={control}
                            defaultValue={true}
                            rules={{
                                validate: () => {
                                    if(!watch("commsRequest.isSendEmail") && !watch("commsRequest.isSendSms")) {
                                        setError("commsRequest", {message: "Required"})
                                        return false
                                    }
                                    clearErrors("commsRequest")
                                    return true
                                }
                            }}
                        />
                    ))}

                    <FormHelperText>
                        {errors.commsRequest && "At least 1 is required"}
                    </FormHelperText>
                    
                </FormControl>

                {interview?.type === "CANDIDATE" &&
                    <>
                        <FormControl
                            error={Boolean(errors.interviewTitle)} 
                            className={classes.input}>

                            <FormLabel style={{marginBottom: 6}}>Title</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter interview title"
                                        fullWidth
                                        variant="filled"
                                        error={errors.interviewTitle ? true : false}
                                        helperText={errors.interviewTitle?.message}/>
                                }
                                name="interviewTitle"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel style={{marginBottom: 6}}>Notes</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter notes"
                                        fullWidth
                                        variant="filled"
                                        multiline
                                        rows={2}/>
                                }
                                name="internalNotes"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </>
                }

                <FormControl 
                    className={classes.input}
                    error={Boolean(errors.interviewAt)}>

                    <FormLabel className={classes.label}>Date</FormLabel>

                    <Controller
                        render={({onChange, value}) => (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                    <KeyboardDateTimePicker
                                        autoOk
                                        placeholder="DD/MM/YYYY hh:mm"
                                        variant="inline"
                                        format="dd/MM/yyyy hh:mm a"
                                        id="date-picker6"
                                        value={value}
                                        disablePast
                                        minutesStep={15}
                                        minDateMessage="Date must not be in the past"
                                        onChange={date => {
                                            onChange(date)
                                            handleInterviewTemplate()
                                        }}
                                        error={Boolean(errors.interviewAt)}
                                        onError={error => {
                                            if(error !== errors.interviewAt?.message) {
                                                if(error === "Invalid Date Format") {
                                                    setError("interviewAt", {message: "Invalid Date Format"})
                                                } else if (error === "Date must not be in the past") {
                                                    setError("interviewAt", {message: "Date must not be in the past"})
                                                } else if (error === "" && errors.interviewAt !== undefined) {
                                                    clearErrors("interviewAt")
                                                }
                                            }
                                        }}/>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        )}
                        name="interviewAt"
                        control={control}
                        defaultValue={null}
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.location)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>Select email template</FormLabel>

                    <Controller
                        render={({onChange, value}) => (
                            <Select 
                                displayEmpty
                                variant="filled"
                                disabled={(watch("interviewAt") && !Boolean(errors.interviewAt)) ? false : true}
                                value={value}
                                onChange={event => {
                                    let val = event.target.value as string
                                    onChange(val)
                                    clearErrors("message")
                                    handleInterviewTemplate()
                                }}>
                                <MenuItem value="">Select an option</MenuItem>
                                {interviewTemplates.map((t: InterviewTemplateInfo, i: number) => (
                                    <MenuItem key={i} value={t.templateName.toUpperCase()}>{t.templateName}</MenuItem>
                                ))}
                                <MenuItem value="CUSTOM">Custom</MenuItem>
                            </Select>
                        )}
                        name="location"
                        control={control}
                        defaultValue=""
                        rules={{
                        required: "This is required"
                        }}
                    />
                    <FormHelperText>
                        {errors.location && errors.location.message}
                    </FormHelperText>
                </FormControl>

                <FormControl
                    error={Boolean(errors.message)} 
                    style={{width: "100%"}}>

                    <FormLabel style={{marginBottom: 6}}>Message</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter message"
                                fullWidth
                                variant="filled"
                                multiline
                                rows={8}
                                error={errors.message ? true : false}
                                helperText={errors.message?.message}/>
                        }
                        name="message"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleDialog(false)}  color="secondary">
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Edit
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
