import React, { useState, useEffect } from 'react'
import { 
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import { 
    Card, CardContent, CardHeader, Grid, FormControl, FormLabel, TextField,
    Tabs, Tab, Divider, Button
} from "@material-ui/core";
import {
    Edit, Clear, Save
} from "@material-ui/icons"
import { useForm, Controller, FormProvider } from "react-hook-form";
import TabPanel from '../../../components/TabPanel';
import { API } from "aws-amplify";
import GoogleAddressTextField from '../../../components/GoogleAddressTextField';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface CompanyInfoForm {
    name: string;
    taxId: string;
    address: string;
    phone: string;
    email: string;
    fax: string;
    streetNumber: string;
    street: string;
    region: string;
    postcode: string;
    country: string;
}

interface Props {
    loading: boolean
    setLoading: (loading: boolean) => void
    user: any | null
    currentTab: number
}

export default function CompanyInfo({ loading, setLoading, user, currentTab }: Props) {

    const classes = useStyles()

    const methods = useForm<CompanyInfoForm>({
        shouldUnregister: false
    })

    const { handleSubmit, setError, control, errors, clearErrors, reset } = methods

    const [readOnly, setReadOnly] = useState<boolean>(true)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [apiError, setApiError] = useState<string>()

    //Google address
    const [addressInput, setAddressInput] = useState<string>('')
    const [addressValue, setAddressValue] = useState<string>()

    useEffect(() => {
        if(currentTab === 0) {
            getCompanyInfo()
        }
    }, [currentTab])

    const onSubmit = (data: CompanyInfoForm) => {
        if(user) {
            if(!addressValue) {
                setError("address", {message: "Invalid address"})
            } else {
                setLoading(true)

                data.address = addressValue
                console.log(data)

                let params = {
                    body: {
                        id: user.username,
                        data: data
                    }
                }

                API.post("", "/admin/company-info", params)
                .then(() => {
                    setReadOnly(true)
                    getCompanyInfo()
                })
                .catch((error: any) => {
                    setLoading(false)
                    setApiError("Error: unable to update form")
                    console.log("Error updating form", error)
                })
            }
        } else {
            setApiError("Error: unable to update form")
        }
    }

    const getCompanyInfo = () => {
        setLoading(true)
        API.get("", "/admin/company-info", {})
        .then((response: CompanyInfoForm) => {
            if(response) {
                reset(response)
                if(response.address) {
                    setAddressInput(response.address)
                    setAddressValue(response.address)
                }
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching company info", error)
            setLoading(false)
        })
    }

    return (
        <div>
            <Card style={{height: "100vh"}}>
                <CardHeader title="Company Info"/>
                <CardContent>
                    <Tabs
                        value={selectedTab} 
                        onChange={(_, newValue) => setSelectedTab(newValue)}>

                        <Tab label="Detail" />
                    </Tabs>
                    <Divider style={{marginBottom: 16}} />

                    <TabPanel value={selectedTab} index={0}>
                        <div style={{display: "flex"}}>
                            <div style={{flexGrow: 1}}></div>
                            <div>
                                <Button variant="contained" color="primary" onClick={() => setReadOnly(false)} disabled={loading}>
                                    Edit
                                    <Edit style={{marginLeft: 4}} />
                                </Button>
                            </div>
                        </div>

                        <FormProvider {...methods}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        error={Boolean(errors.name)}
                                        className={classes.input}>

                                        <FormLabel
                                            className={classes.label}>
                                            Name
                                        </FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    placeholder="Enter company name"
                                                    fullWidth
                                                    variant="filled"
                                                    error={errors.name ? true : false}
                                                    helperText={errors.name?.message}
                                                    disabled={readOnly}
                                                />
                                            }
                                            name="name"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        error={Boolean(errors.taxId)}
                                        className={classes.input}>

                                        <FormLabel
                                            className={classes.label}>
                                            Tax ID
                                        </FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    placeholder="Enter company tax ID"
                                                    fullWidth
                                                    variant="filled"
                                                    disabled={readOnly}
                                                />
                                            }
                                            name="taxId"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    
                                    <GoogleAddressTextField 
                                        addressInput={addressInput} 
                                        setAddressInput={setAddressInput} 
                                        setAddressValue={setAddressValue} 
                                        readOnly={readOnly}
                                    />

                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        error={Boolean(errors.phone)}
                                        className={classes.input}>

                                        <FormLabel
                                            className={classes.label}>
                                            Phone
                                        </FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    placeholder="Enter company phone"
                                                    fullWidth
                                                    variant="filled"
                                                    error={errors.phone ? true : false}
                                                    helperText={errors.phone?.message}
                                                    disabled={readOnly}
                                                />
                                            }
                                            name="phone"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        error={Boolean(errors.email)}
                                        className={classes.input}>

                                        <FormLabel
                                            className={classes.label}>
                                            Email
                                        </FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    placeholder="Enter company email"
                                                    fullWidth
                                                    variant="filled"
                                                    error={errors.email ? true : false}
                                                    helperText={errors.email?.message}
                                                    disabled={readOnly}
                                                />
                                            }
                                            name="email"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        error={Boolean(errors.fax)}
                                        className={classes.input}>

                                        <FormLabel
                                            className={classes.label}>
                                            Fax
                                        </FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    placeholder="Enter company fax"
                                                    fullWidth
                                                    variant="filled"
                                                    disabled={readOnly}
                                                />
                                            }
                                            name="fax"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormProvider>
                        
                        {!readOnly &&
                            <div style={{display: "flex"}}>
                                <div style={{flexGrow: 1}}></div>
                                <div>
                                    {apiError &&
                                        <span style={{color: "red", marginRight: 8}}>
                                            {apiError}
                                        </span>
                                    }
                                    <Button variant="contained" color="secondary" disabled={loading} onClick={() => {
                                        setReadOnly(true)
                                        clearErrors()
                                        setApiError(undefined)
                                        getCompanyInfo()
                                    }}>
                                        Cancel
                                        <Clear style={{marginLeft: 4}} />
                                    </Button>
                                    <Button style={{marginLeft: 8}} variant="contained" color="primary" disabled={loading} onClick={handleSubmit(onSubmit)}>
                                        Save
                                        <Save style={{marginLeft: 4}} />
                                    </Button>
                                </div>
                            </div>
                        }
                    </TabPanel>
                </CardContent>
            </Card>
            
        </div>
    )
}
