import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Divider, LinearProgress, Tabs, Tab
} from "@material-ui/core";
import TabPanel from '../../components/TabPanel';
import Skills from './tabs/Skills';
import CompanyInfo from './tabs/CompanyInfo';
import Templates from './tabs/Templates';
import Integrations from './tabs/Integrations';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        divider: {
            marginBottom: 16
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        actionDialogTabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            minWidth: 175,
            backgroundColor: "#ffffff"
        },
        actionDialogTabPanel: {
            flexGrow: 1,
            padding: "0px 16px"
        }
    })
);

interface AdminProps {
    user?: any | null;
}

export default function Admin({ user }: AdminProps) {
    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0)

    return (
        <div>
             <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Admin
                </h2>
            </div>
            {loading && <LinearProgress className={classes.progressBackground} classes={{barColorPrimary: classes.progress}} />}
            <Divider className={classes.divider}/>

            <div style={{display: "flex"}}>
                <Tabs 
                    className={classes.actionDialogTabs}
                    orientation="vertical"
                    value={selectedTab} 
                    onChange={(_, newValue) => setSelectedTab(newValue)}>
                        <Tab label="Company Info" />
                        <Tab label="Skills" />
                        <Tab label="Templates" />
                        <Tab label="Integrations" />
                </Tabs>

                <div className={classes.actionDialogTabPanel}>
                    <TabPanel value={selectedTab} index={0}>
                        <CompanyInfo loading={loading} setLoading={setLoading} user={user} currentTab={selectedTab}/>
                    </TabPanel>

                    <TabPanel value={selectedTab} index={1}>
                        <Skills loading={loading} setLoading={setLoading} user={user} selectedTab={selectedTab} />
                    </TabPanel>

                    <TabPanel value={selectedTab} index={2}>
                        <Templates loading={loading} setLoading={setLoading} user={user} selectedTab={selectedTab} />
                    </TabPanel>

                    <TabPanel value={selectedTab} index={3}>
                        <Integrations />
                    </TabPanel>
                </div>

                
            </div>
        </div>
    )
}