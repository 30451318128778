import React from 'react'
import {
    Link, Accordion, AccordionDetails, AccordionSummary
} from "@material-ui/core"
import { ExpandMore } from '@material-ui/icons';

interface Props {
    employmentContractExpanded: boolean;
    setEmploymentContractExpanded: (open: boolean) => void;
}

export default function EmploymentContractCard({ employmentContractExpanded, setEmploymentContractExpanded }: Props) {

    return (
        <div id="employment-contract">
            <Accordion style={{marginTop: 32}}
            expanded={employmentContractExpanded}
            onChange={() => setEmploymentContractExpanded(!employmentContractExpanded)}> 
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <h3>Employment Contract</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%"}}>
                        <p>By clicking next you agree to the terms and conditions stated in the Employment Contract below.</p>
                        <Link>
                            Employment Contract
                        </Link>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
