import React, { useEffect, useState } from 'react'
import { 
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import { 
    Dialog, Grid, FormControl, FormLabel, TextField, DialogTitle, 
    DialogContent, DialogActions, Button, CircularProgress
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { InterviewTemplateInfo } from '../../../types/InterviewTypes'
import { API } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface Props {
    open: boolean
    handleDialog: (open: boolean) => void;
    template: InterviewTemplateInfo | undefined
    templates: Array<InterviewTemplateInfo>
    user: any | null
    getInterviewTemplates: () => void
}

export default function TemplateDialog({ open, handleDialog, template, templates, user, getInterviewTemplates }: Props) {

    const classes = useStyles()

    const { handleSubmit, control, errors, reset, setError } = useForm<InterviewTemplateInfo>()

    const [loading, setLoading] = useState<boolean>(false)
    const [apiError, setApiError] = useState<string>()

    useEffect(() => {
        if(open) {
            if(template) {
                reset(template)
            } else {
                reset({
                    pk: "",
                    sk: "",
                    templateName: "",
                    message: "",
                    updatedAt: "",
                    updatedBy: ""
                })
            }

            setApiError(undefined)
        }
    }, [template, open])

    const onSubmit = (data: InterviewTemplateInfo) => {
        if(user) {
            if(data.templateName.toUpperCase() === "CUSTOM") {
                setError("templateName", { message: "Invalid name"})
                return
            }

            if(!template && templates.some((t: InterviewTemplateInfo) => t.templateName.toUpperCase() === data.templateName.toUpperCase())) {
                setError("templateName", { message: "Name must be unique"})
                return
            } else {
                setLoading(true)

                let params = {
                    body: {
                        templateName: data.templateName,
                        message: data.message,
                        username: user.username
                    }
                }
                API.post("", '/cms/interview-templates', params)
                .then(() => {
                    setLoading(false)
                    handleDialog(false)
                    getInterviewTemplates()
                })
                .catch((error: any) => {
                    setLoading(false)
                    setApiError("Error: unable to process template")
                    console.log("Error: unable to post template", error)
                })
            }
        } else {
            setApiError("Error: unable to process template")
        }
    }

    return (
        <Dialog fullWidth maxWidth={'md'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                {template ?
                    <>
                        Edit Template
                    </>
                :
                    <>
                        Add Template
                    </>
                }
            </DialogTitle>
            <DialogContent>
                <h4>
                    Interview placeholders
                </h4>
                <p style={{whiteSpace: "break-spaces"}}>

                    {`Use these placeholders to insert customised values for an interview:\n\n{NAME} - Candidate's name\n{DATE} - Date of interview\n{TIME} - Time of interview`}

                </p>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl
                            error={Boolean(errors.templateName)}
                            className={classes.input}>

                            <FormLabel
                                className={classes.label}>
                                Template Name
                            </FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter template name"
                                        fullWidth
                                        variant="filled"
                                        error={errors.templateName ? true : false}
                                        helperText={errors.templateName?.message}
                                    />
                                }
                                name="templateName"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl
                            error={Boolean(errors.message)}
                            className={classes.input}>

                            <FormLabel
                                className={classes.label}>
                                Message
                            </FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter message"
                                        fullWidth
                                        variant="filled"
                                        multiline
                                        rows={20}
                                        error={errors.message ? true : false}
                                        helperText={errors.message?.message}
                                    />
                                }
                                name="message"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                {apiError &&
                    <span style={{marginRight: 8, color: "red"}}>{apiError}</span>
                }
                <Button variant="contained" color="secondary" onClick={() => handleDialog(false)}>
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="primary" style={{width: 90}}>
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button variant="contained" color="primary" style={{width: 90}} onClick={handleSubmit(onSubmit)}>
                        Submit
                    </Button>
                }

            </DialogActions>
        </Dialog>
    )
}
