import React, { useState, useEffect } from 'react'
import { 
    Button, Typography, Dialog, DialogTitle, 
    DialogContent, DialogActions, CircularProgress
} from "@material-ui/core"; 
import { API } from "aws-amplify"
import { Application } from '../../types/CandidateTypes';
import { InterviewInfo } from '../../types/InterviewTypes';

interface CancelCandidateDialogProps {
    openDialog: boolean
    handleDialog: (open: boolean) => void
    application?: Application | undefined
    interview?: InterviewInfo | undefined
    user?: any | null
    refreshData: () => void;
}

export default function CancelInterviewDialog({ openDialog, handleDialog, application, user, refreshData, interview }: CancelCandidateDialogProps) {
    
    const [rejectLoading, setRejectLoading] = useState<boolean>(false)
    const [newApplicantLoading, setNewApplicantLoading] = useState<boolean>(false)
    const [cancelLoading, setCancelLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    useEffect(() => {
        if(openDialog) {
            setError(undefined)
        }
    }, [openDialog])

    const onRejectSubmit = () => {
        if(user) {
            setRejectLoading(true)

            let params = {}

            if(application) {
                params = {
                    body: {
                        type: "reject",
                        data: application,
                        user: user.username
                    }
                }
            }

            if(interview) {
                params = {
                    body: {
                        type: "reject",
                        data: interview,
                        user: user.username
                    }
                }
            }

            API.post("", "/interviews/cancel", params)
            .then(() => {
                setRejectLoading(false)
                handleDialog(false)
                refreshData()
            })
            .catch((error: any) => {
                setRejectLoading(false)
                setError("Unable to cancel interview and reject candidate")
                console.log("Error: cancelling interview", error)
            })
        } else {
            setError("Unable to cancel interview and reject candidate")
        }
    }

    const onNewApplicantSubmit = () => {
        if(user) {
            setNewApplicantLoading(true)
            
            let params = {}

            if(application) {
                params = {
                    body: {
                        type: "new applicant",
                        data: application,
                        user: user.username
                    }
                }
            }

            if(interview) {
                params = {
                    body: {
                        type: "new applicant",
                        data: interview,
                        user: user.username
                    }
                }
            }

            API.post("", "/interviews/cancel", params)
            .then(() => {
                setNewApplicantLoading(false)
                handleDialog(false)
                refreshData()
            })
            .catch((error: any) => {
                setNewApplicantLoading(false)
                setError("Unable to cancel interview and reset candidate to new applicant status")
                console.log("Error: cancelling interview", error)
            })

        } else {
            setError("Unable to cancel interview and reset candidate to new applicant status")
        }
    }

    const onCancelSubmit = () => {
        if(user) {
            setCancelLoading(true)
            
            let params = {}

            if(interview) {
                params = {
                    body: {
                        data: interview,
                        user: user.username
                    }
                }
            }

            API.post("", "/interviews/cancel", params)
            .then(() => {
                setCancelLoading(false)
                handleDialog(false)
                refreshData()
            })
            .catch((error: any) => {
                setCancelLoading(false)
                setError("Unable to cancel interview")
                console.log("Error: cancelling interview", error)
            })

        } else {
            setError("Unable to cancel interview")
        }
    }
    
    return (
        <Dialog fullWidth maxWidth={'md'} open={openDialog} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Cancel Interview
            </DialogTitle>
            <DialogContent>
                {application &&
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        You are about to cancel this interview for {`${application.firstName} ${application.lastName}`} for the {application.position} position. You can either return candidate back to new applicant status or reject candidate for the position.
                    </Typography>
                }
                {(interview && interview?.type === "CANDIDATE") &&
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Are you sure you want to cancel this interview for {`${interview.firstName} ${interview.lastName}`}.
                    </Typography>
                }
                {(interview && interview?.position) &&
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        You are about to cancel this interview for {`${interview.firstName} ${interview.lastName}`} for the {interview.position} position. You can either return candidate back to new applicant status or reject candidate for the position.
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleDialog(false)}  color="secondary">
                    Cancel
                </Button>
                {((interview && interview.position) || application) &&
                    <>
                        {rejectLoading ?
                            <Button variant="contained" color="secondary">
                                <CircularProgress size={25} style={{color: "white"}} />
                            </Button>
                        :
                            <Button onClick={() => onRejectSubmit()} variant="contained" color="secondary">
                                Reject
                            </Button>
                        }
                        {newApplicantLoading ?
                            <Button variant="contained" color="primary">
                                <CircularProgress size={25} style={{color: "white"}} />
                            </Button>
                        :
                            <Button onClick={() => onNewApplicantSubmit()} variant="contained" color="primary">
                                Back to New Applicant
                            </Button>
                        }
                    </>
                }

                {(interview && interview?.type === "CANDIDATE") &&
                    <>
                        {cancelLoading ?
                            <Button variant="contained" color="primary">
                                <CircularProgress size={25} style={{color: "white"}} />
                            </Button>
                        :
                            <Button onClick={() => onCancelSubmit()} variant="contained" color="primary">
                                Submit
                            </Button>
                        }
                    </>
                }

            </DialogActions>
        </Dialog>
    )
}
