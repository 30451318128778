import React, { useState, useEffect } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Grid, Button, Typography, Dialog, DialogTitle, DialogContent, 
    DialogActions, FormControl, FormLabel, TextField, CircularProgress, 
    Select, MenuItem, FormControlLabel, Checkbox, FormHelperText
} from "@material-ui/core"; 
import { API } from "aws-amplify"
import { useForm, Controller } from "react-hook-form"; 
import { IsValidDate } from '../../../../utils';
import { Application, Candidate } from '../../../../types/CandidateTypes';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { format } from "date-fns";
import { InterviewInfo, InterviewTemplateInfo } from '../../../../types/InterviewTypes';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            marginBottom: theme.spacing(2),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface InviteCandidateDialogProps {
    openDialog: boolean
    handleInviteDialog: (open: boolean) => void
    application: Application | undefined
    user?: any | null
    refreshData: () => void;
    editInterview: boolean
    candidateDetail?: Candidate | undefined
}

export function OnsiteCandidateDialog({ openDialog, handleInviteDialog, application, user, refreshData, editInterview, candidateDetail }: InviteCandidateDialogProps) {

    const classes = useStyles()

    const { handleSubmit, control, errors, watch, setError, clearErrors, setValue, getValues, reset } = useForm<InterviewInfo>();
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setApiError] = useState<string>()
    const [interviewTemplates, setInterviewTemplates] = useState<Array<InterviewTemplateInfo>>([])
    const [candidateInterview, setCandidateInterview] = useState<InterviewInfo>()

    useEffect(() => {
        if(openDialog) {
            setApiError(undefined)

            API.get("", "/cms/interview-templates", {})
            .then((response: Array<InterviewTemplateInfo>) => {
                if(response) {
                    setInterviewTemplates(response)
                }
            })
            .catch((error: any) => {
                console.log("Error: calling interview templates api", error)
            })
        }
        
    }, [openDialog])

    useEffect(() => {
        if(editInterview && application) {
            API.get("", `/interviews/jobRef/${application.jobRef}/candidate/${application.sk.split("#")[1]}`, {})
            .then((response: InterviewInfo) => {
                if(response) {
                    setCandidateInterview(response)
                }
                console.log(response)
            })
        }
    }, [editInterview, application])

    useEffect(() => {
        if(candidateInterview && interviewTemplates) {
            reset(candidateInterview)
        }
    }, [candidateInterview, interviewTemplates])

    function onSubmit(data: InterviewInfo) {
        if(user) {

            if(Boolean(errors.interviewAt)) {
                return
            }

            if(application) {
                data.interviewTitle = `${application.firstName} ${application.lastName} - ${application.position}`
                data.firstName = application.firstName
                data.lastName = application.lastName
                data.position = application.position
                data.email = application.email
                data.phone = application.phone
            }

            if(candidateDetail) {
                data.interviewTitle = `${candidateDetail.firstName} ${candidateDetail.surname} - ${data.interviewTitle}`
                data.firstName = candidateDetail.firstName
                data.lastName = candidateDetail.surname
                data.email = candidateDetail.email
                data.phone = candidateDetail.phone
            }
            
            setLoading(true)

            let params = {
                body: {
                    username: user.username,
                    interview: data,
                    application: application,
                    candidateDetail: candidateDetail
                }
            }

            API.post("", `/candidates/invite`, params)
            .then(() => {
                setLoading(false)
                handleInviteDialog(false)
                refreshData()
            })
            .catch((error: any) => {
                console.log("Unable to invite candidate", error)
                setLoading(false)
                setApiError("Unable to invite candidate")
            })
        } else {
            setApiError("Unable to invite candidate")
        }

    }

    const handleInterviewTemplate = () => {

        let template = getValues("location")
        
        let t = interviewTemplates.find((a: InterviewTemplateInfo) => a.sk === `INTERVIEW#${template}`)
        if(t) {
            let emailMessage = t.message
            let interviewDateStr = getValues("interviewAt")

            if(IsValidDate(interviewDateStr)) {
                let date = format(new Date(interviewDateStr), 'dd MMM yyyy')
                let time = format(new Date(interviewDateStr), 'hh:mm a')
    
                if(application) {
                    emailMessage = emailMessage.replace("{NAME}", application.firstName)
                } else if (candidateDetail) {
                    emailMessage = emailMessage.replace("{NAME}", candidateDetail.firstName)
                }
                emailMessage = emailMessage.replace("{DATE}", date)
                emailMessage = emailMessage.replace("{TIME}", time)
                // console.log(emailMessage)
                setValue("message", emailMessage)
            }

        } else {
            setValue("message", "")
        }
    }

    return (
        <Dialog fullWidth maxWidth={'md'} open={openDialog} onClose={() => handleInviteDialog(false)}>
            <DialogTitle>
                Candidate Interview 
            </DialogTitle>
            <DialogContent>
                {application &&
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Start Interview with {`${application.firstName} ${application.lastName}`} for {application.position} position.
                    </Typography>
                }
                {candidateDetail &&
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Start Interview with {`${candidateDetail.firstName} ${candidateDetail.surname}`}.
                    </Typography>
                }

                

                {candidateDetail &&
                    <>
                        <FormControl
                            error={Boolean(errors.interviewTitle)} 
                            className={classes.input}>

                            <FormLabel style={{marginBottom: 6}}>Title</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter interview title"
                                        fullWidth
                                        variant="filled"
                                        error={errors.interviewTitle ? true : false}
                                        helperText={errors.interviewTitle?.message}/>
                                }
                                name="interviewTitle"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel style={{marginBottom: 6}}>Notes</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter notes"
                                        fullWidth
                                        variant="filled"
                                        multiline
                                        rows={2}/>
                                }
                                name="internalNotes"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </>
                }

                <FormControl 
                    className={classes.input}
                    error={Boolean(errors.interviewAt)}>

                    <FormLabel className={classes.label}>Date</FormLabel>

                    <Controller
                        render={({onChange, value}) => (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid container>
                                    <KeyboardDateTimePicker
                                        autoOk
                                        placeholder="DD/MM/YYYY hh:mm"
                                        variant="inline"
                                        format="dd/MM/yyyy hh:mm a"
                                        id="date-picker6"
                                        value={value}
                                        disablePast
                                        minutesStep={15}
                                        minDateMessage="Date must not be in the past"
                                        onChange={date => {
                                            onChange(date)
                                            handleInterviewTemplate()
                                        }}
                                        error={Boolean(errors.interviewAt)}
                                        onError={error => {
                                            if(error !== errors.interviewAt?.message) {
                                                if(error === "Invalid Date Format") {
                                                    setError("interviewAt", {message: "Invalid Date Format"})
                                                } else if (error === "Date must not be in the past") {
                                                    setError("interviewAt", {message: "Date must not be in the past"})
                                                } else if (error === "" && errors.interviewAt !== undefined) {
                                                    clearErrors("interviewAt")
                                                }
                                            }
                                        }}/>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        )}
                        name="interviewAt"
                        control={control}
                        defaultValue={Date.now()}
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

                <FormControl
                    error={Boolean(errors.location)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>Select Location</FormLabel>

                    <Controller
                        render={({onChange, value}) => (
                            <Select 
                                displayEmpty
                                variant="filled"
                                defaultValue=""
                                disabled={(watch("interviewAt") && !Boolean(errors.interviewAt)) ? false : true}
                                value={value}
                                onChange={event => {
                                    let val = event.target.value as string
                                    onChange(val)
                                    clearErrors("message")
                                    handleInterviewTemplate()
                                }}>
                                <MenuItem value="">Select an option</MenuItem>
                                {interviewTemplates.map((t: InterviewTemplateInfo, i: number) => (
                                    <MenuItem key={i} value={t.templateName.toUpperCase()}>{t.templateName}</MenuItem>
                                ))}
                                <MenuItem value={"CUSTOM"}>Custom</MenuItem>
                            </Select>
                        )}
                        name="location"
                        control={control}
                        defaultValue=""
                        rules={{
                        required: "This is required"
                        }}
                    />
                    <FormHelperText>
                        {errors.location && errors.location.message}
                    </FormHelperText>
                </FormControl>

            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleInviteDialog(false)}  color="secondary">
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
                        Post Interview
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}