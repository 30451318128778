import React, { useState, useEffect } from 'react'
import { 
    Grid, Button
} from '@material-ui/core';
import { CandidateInterviewDetail } from '../../../../types/InterviewTypes';
import { API } from "aws-amplify";
import InterviewDetail from './InterviewDetail';
import { Add } from "@material-ui/icons"
import { InviteCandidateDialog } from '../../../applications/InviteCandidateDialog';
import { Candidate } from '../../../../types/CandidateTypes';
import CandidateInterviewDialog from '../../dialogs/CandidateInterviewDialog';
import { OnsiteCandidateDialog } from './OnsiteCandidateDialog';

interface CandidateInterviewsProps {
    active: boolean
    loading: boolean
    setLoading: (loading: boolean) => void;
    candidateId: string;
    user?: any | null;
    accordionExpanded: boolean
    candidateDetail: Candidate | undefined
}

export default function CandidateInterviews({ active, loading, setLoading, candidateId, user, accordionExpanded, candidateDetail }: CandidateInterviewsProps) {

    const [interviews, setInterviews] = useState<Array<CandidateInterviewDetail>>([])
    const [openInviteCandidateDialog, setOpenInviteCandidateDialog] = useState<boolean>(false)
    const [openOnsiteCandidateDialog, setOpenOnsiteCandidateDialog] = useState<boolean>(false)

    useEffect(() => {
        if(active && candidateId) {
            setLoading(true)
            API.get("", `/candidates/${candidateId}/interviews`, {})
            .then((response: Array<CandidateInterviewDetail>) => {
                if(response) {
                    //console.log(response)
                    setInterviews(response.sort((a, b) => a.scheduledInterview.interviewAt < b.scheduledInterview.interviewAt ? 1 : -1))
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching candidate interviews", error)
                setLoading(false)
            })
        }
    }, [active, candidateId])

    const getInterviewData = () => {
        setLoading(true)
        API.get("", `/candidates/${candidateId}/interviews`, {})
        .then((response: Array<CandidateInterviewDetail>) => {
            if(response) {
                console.log(response)
                setInterviews(response.sort((a, b) => a.scheduledInterview.interviewAt < b.scheduledInterview.interviewAt ? 1 : -1))
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching candidate interviews", error)
            setLoading(false)
        })
    }

    return (
        <>
            <div style={{display: "flex", marginBottom: 16, marginTop: 16}}>
                <div style={{flexGrow: 1}}></div>
                <div style={{ marginRight:8}}>
                    <Button variant="contained" color="secondary" onClick={() => setOpenOnsiteCandidateDialog(true)}>
                        <Add />
                        Start Walk-in Interview
                    </Button>
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={() => setOpenInviteCandidateDialog(true)}>
                        <Add />
                        Invite to Interview
                    </Button>
                </div>
            </div>

            {!loading &&
                <Grid container spacing={3}>
                    {interviews.length !== 0 && interviews.map((info: CandidateInterviewDetail, i: number) => (
                        <InterviewDetail key={i}
                            interview={info}
                            accordionExpanded={accordionExpanded} 
                            candidateId={candidateId} 
                            user={user} 
                            getInterviewData={getInterviewData} />
                    ))}
                </Grid>
            }

            <InviteCandidateDialog
                openDialog={openInviteCandidateDialog}
                handleInviteDialog={setOpenInviteCandidateDialog}
                user={user}
                refreshData={getInterviewData}
                application={undefined}
                editInterview={false}
                candidateDetail={candidateDetail}
            />

            <OnsiteCandidateDialog
                            openDialog={openOnsiteCandidateDialog}
                            handleInviteDialog={setOpenOnsiteCandidateDialog}
                            user={user}
                            refreshData={getInterviewData}
                            application={undefined}
                            editInterview={false}
                            candidateDetail={candidateDetail}
                        />

        </>            
    )
}
