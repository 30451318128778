import React from 'react'
import { 
  CheckCircle, PersonOutlined, EmailOutlined, Assignment, Home, Check, Timer,
  Add, Cancel, Email, Update, Clear
} from "@material-ui/icons"
import { format, parseISO } from 'date-fns';

export const FormatDateTime = (date: string): string => {
  if(IsValidDate(date)) {
    return format(parseISO(date), 'dd/MM/yyyy HH:mm')
  }
  return ""
}

export const FormatTime = (time: string) : string => {
  return format(parseISO(time), 'hh:mm a')
}

export const FormatDate = (date: string): string => {
  if(IsValidDate(date)) {
    return format(parseISO(date), 'dd/MM/yyyy')
  }
  return ""
}

export const IsValidDate = (dateString: Date | string): boolean => {
    var day = 0;
    var month = 0;
    var year = 0;
    if(dateString instanceof Date) {
      // First check for the pattern
      if(!/^\w{3} \w{3} \d{2} \d{4}/.test(dateString.toString())) {
        return false;
      }

      // Parse the date parts to integers
      var parts = dateString.toString().split(" ");
      day = parseInt(parts[2], 10);
      var monthStr = parts[1];
      year = parseInt(parts[3], 10);

      switch (monthStr) {
        case "Jan":
          month = 1
          break;
        case "Feb":
          month = 2
          break;
        case "Mar":
          month = 3
          break;
        case "Apr":
          month = 4
          break;
        case "May":
          month = 5
          break;
        case "Jun":
          month = 6
          break;
        case "Jul":
          month = 7
          break;
        case "Aug":
          month = 8
          break;
        case "Sep":
          month = 9
          break;
        case "Oct":
          month = 10
          break;
        case "Nov":
          month = 11
          break;
        case "Dec":
          month = 12
          break;
      }
    } else {
      //"2021-03-03T13:00:00.000Z"
      if(dateString) {
        if(!/^\d{4}-\d{2}-\d{2}/.test(dateString.toString())) {
          return false;
        }
  
        // Parse the date parts to integers
        parts = dateString.toString().split("-");
        day = parseInt(parts[2], 10);
        month = parseInt(parts[1], 10);
        year = parseInt(parts[0], 10);
      } else {
        return false;
      }
    }

    // Check the ranges of month and year
    if(year < 1000 || year > 3000 || month === 0 || month > 12)
        return false;

    var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

    // Adjust for leap years
    if(year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
        monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
}

const newStatusColor = "#26c6da"
const registeringStatusColor = "#7e57c2"
const registeredStatusColor = "#66bb6a"
const inviteStatusColor = "#42a5f5"
const interviewedStatusColor = "#5c6bc0"
const onboardingStatusColor = "#ffa726"
const onboardedStatusColor = "#9ccc65"
const declineStatusColor = "#ef5350"
const systemStatusColor = "#ec407a"

export function GetApplicationStatusColor(status: string) : string {
  let color;
  switch (status) {
      case "NEW APPLICANT": 
          color = newStatusColor;
          break;
      case "INVITED":
          color = inviteStatusColor;
          break;
      case "INTERVIEWED":
          color = interviewedStatusColor
          break;
      case "COMPLETED":
          color = onboardedStatusColor;
          break;
      default:
          color = declineStatusColor
  }
  return color;
}

export function GetCandidateStatusColor(status: string) : string {
  let color;
  switch (status) {
      case "New": 
          color = newStatusColor;
          break;
      case "Registering":
          color = registeringStatusColor;
          break;
      case "Registered":
          color = registeredStatusColor;
          break;
      case "Onboarding":
          color = onboardingStatusColor;
          break;
      case "Onboarded":
          color = onboardedStatusColor;
          break;
      default:
          color = declineStatusColor
  }
  return color;
}

export const GetHistoryItemIcon = (status: string) : JSX.Element => {
  let icon;
  switch(status) {
      case "NEW APPLICANT":
          icon = <PersonOutlined />
          break;
      case "REGISTRATION EMAIL":
          icon = <EmailOutlined />
          break;
      case "REGISTRATION":
          icon = <Assignment />
          break;
      case "REGISTRATION COMPLETE":
          icon = <Check />
          break;
      case "SKILL ADDED":
          icon = <CheckCircle />
          break;
      case "SKILL REMOVED":
          icon = <Cancel />
          break;
      case "INTERVIEW ADDED":
          icon = <Add />
          break;
      case "INVITED":
          icon = <Email />
          break;
      case "INVITE UPDATED":
          icon = <Update />
          break
      case "APPLICATION":
          icon = <Assignment />
          break;
      case "PROFILE UPDATED":
          icon = <Update />
          break;
      case "ADDITIONAL INFO UPDATED":
        icon = <Update />
        break;
      case "INTERVIEW UPDATED":
          icon = <Update />
          break;
      case "INTERVIEW CANCELLED":
        icon = <Clear />
        break;
      case "ACTIONS UPDATED":
          icon = <Update />
          break;
      case "CANDIDATE ONBOARDING":
          icon = <Timer />
          break;
      default:
          icon = <Home />
          break;  
  }
  return icon;
}

export const GetHistoryItemColor = (status: string) : string => {
  let color;
  switch(status) {
      case "NEW APPLICANT":
          color = newStatusColor
          break;
      case "REGISTRATION EMAIL":
          color = registeringStatusColor
          break;
      case "REGISTRATION":
          color = registeringStatusColor
          break;
      case "REGISTRATION COMPLETE":
          color = registeredStatusColor
          break;
      case "SKILL ADDED":
          color = systemStatusColor
          break;
      case "SKILL REMOVED":
          color = systemStatusColor
          break;
      case "INTERVIEW ADDED":
          color = interviewedStatusColor
          break;
      case "INVITED":
          color = inviteStatusColor
          break;
      case "INVITE UPDATED":
        color = inviteStatusColor
        break;
      case "APPLICATION":
          color = systemStatusColor
          break;
      case "PROFILE UPDATED":
          color = systemStatusColor
          break;
      case "ADDITIONAL INFO UPDATED":
        color = systemStatusColor
        break;
      case "INTERVIEW UPDATED":
          color = interviewedStatusColor
          break;
      case "INTERVIEW CANCELLED":
        color = interviewedStatusColor
        break;
      case "ACTIONS UPDATED":
          color = systemStatusColor
          break;
      case "CANDIDATE ONBOARDING":
          color = onboardingStatusColor
          break;
      default:
          color = declineStatusColor
          break;  
  }
  return color;
}