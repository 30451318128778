import React, { useState, useEffect } from "react";
import {
  TextField, FormControlLabel, FormGroup, Grid,
  Accordion, AccordionSummary, AccordionDetails, Radio, FormControl, FormLabel,
  FormHelperText, RadioGroup, Button, CircularProgress
} from "@material-ui/core"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { ExpandMore, Clear, Save, Edit } from '@material-ui/icons';
import { useForm, Controller } from "react-hook-form";
import { API } from "aws-amplify";
import { PayrollInfo } from '../../../../types/CandidateTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      '& .MuiFormGroup-root':{
        marginBottom: theme.spacing(3)
      }
    },
    submit: {
      marginTop: 32,
      width: 100
    },
    select: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
    editButton: {
      width: 100,
      marginRight: 8
    },
    actionButton: {
      marginRight: 8,
      marginBottom: 8,
      width: 100
    },
    field: {
      '&.MuiInputBase-root.Mui-disabled': {
          color: "#000000"
      },
      '&.MuiInputBase-input.Mui-disabled': {
          color: "#000000"
      }
    },
    radio: {
      '&.MuiRadio-colorSecondary.Mui-disabled': {
        color: "#f50057"
      }
    }
  }),
);


interface Props {
  data?: PayrollInfo
  refreshCandidateData: () => void
  candidate?: string
  user?: string
  dataPdf: boolean
  payrollExpanded: boolean
  setPayrollExpanded: (open: boolean) => void
}

const PayrollCard =({ data, refreshCandidateData, candidate, user, dataPdf, payrollExpanded, setPayrollExpanded }: Props) => {

    const classes = useStyles()

    const { handleSubmit, control, errors, clearErrors, setValue, watch } = useForm<PayrollInfo>({
      shouldUnregister: false
    });


    const [readOnly, setReadOnly] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const isKiwiSaver = watch("isKiwiSaver")
    
    useEffect(() => {
      if(data) {
        setValue("isKiwiSaver", data.isKiwiSaver)
        setValue("kiwiSaverPercentage", data.kiwiSaverPercentage)
        setValue("bankNumber", data.bankNumber)
        setValue("branch", data.branch)
        setValue("accountName", data.accountName)
        setValue("fullName", data.fullName)
        setValue("signature", data.signature)
        setValue("date", data.date)
      }
    }, [data, setValue])
  
    const onSave = (info: PayrollInfo) => {
      if(user && candidate) {
        setLoading(true)

        const params = {
            body: {
                data: info,
                section: "payroll",
                id: candidate,
                user: user
            }
        };
        API.post("", `/candidates/jobPlacement/forms/update`,params)
        .then(() => {
            setReadOnly(true);
            setLoading(false);
            refreshCandidateData()
        })
        .catch((error: any) => {
            console.log("ERROR updating candidate payroll form info", error);
            setLoading(false)
            setError("Error: unable to update info")
        })  
      }
    }

    return (
      <div id="payroll">
        <Accordion 
          style={{marginTop: 32}}
          expanded={payrollExpanded || dataPdf}
          onChange={() => setPayrollExpanded(!payrollExpanded)}>
          <AccordionSummary
            expandIcon={<ExpandMore />}>
            <h3>Payroll</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div>

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button variant="contained" color="primary" className={classes.editButton} onClick={() => setReadOnly(false)}>
                        Edit
                        <Edit style={{marginLeft: 4}} fontSize="small" />
                    </Button>
                </div>
            </div>

            <div id="payroll-div">
              {!dataPdf &&
                <>
                  <h3>
                    Payroll Details - New Zealand
                  </h3>	
                  <p style={{marginBottom: 16}}>
                    Please ensure that all details are correct as this is the information used to process your pay
                  </p>
                </>
              }

              <h4>
                KiwiSaver
              </h4>

              <FormControl
                error={Boolean(errors.isKiwiSaver)} 
                className={classes.input}>

                <FormLabel className={classes.label}>Are you a KiwiSaver Member</FormLabel>

                <Controller
                    render={({ onChange, value }) => (
                        <RadioGroup
                          value={value}>
                          <FormControlLabel 
                            value="Yes" 
                            control={
                              <Radio 
                                className={(value === "Yes") ? classes.radio : ""}
                                disabled={readOnly} 
                                onChange={e => {
                                  onChange(e)
                                  clearErrors("kiwiSaverPercentage")
                                  setValue("kiwiSaverPercentage", "")
                                }
                              }/>
                            }
                            label="Yes" />
                          <FormControlLabel 
                            value="No" 
                            control={
                              <Radio 
                                className={(value === "No") ? classes.radio : ""}
                                disabled={readOnly} 
                                onChange={e => {
                                  onChange(e)
                                  clearErrors("kiwiSaverPercentage")
                                  setValue("kiwiSaverPercentage", "")
                                }
                              }/>
                            } 
                            label="No" />
                        </RadioGroup>
                    )}
                    name="isKiwiSaver"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}  
                />
                <FormHelperText>
                    {errors.isKiwiSaver && errors.isKiwiSaver.message}
                </FormHelperText>
              </FormControl>

              {isKiwiSaver === "Yes" &&
                <FormControl
                  error={Boolean(errors.kiwiSaverPercentage)} 
                  className={classes.input}>

                  <FormLabel className={classes.label}>Please advise the percentage to deduct</FormLabel>

                  <Controller
                      render={({ onChange, value}) => (
                          <RadioGroup
                            onChange={(e) => onChange(e)}
                            value={value}>
                            <FormControlLabel 
                              value="4%" 
                              control={
                              <Radio 
                                className={(value === "4%") ? classes.radio : ""}
                                disabled={readOnly}/>
                              } 
                              label="4%" />
                            <FormControlLabel 
                              
                              value="6%" 
                              control={
                                <Radio 
                                  className={(value === "6%") ? classes.radio : ""}
                                  disabled={readOnly}/>
                              } 
                              label="6%" />
                            <FormControlLabel 
                              
                              value="8%" 
                              control={
                                <Radio 
                                  className={(value === "8%") ? classes.radio : ""}
                                  disabled={readOnly}/>
                              } 
                              label="8%" />
                            <FormControlLabel 
                              
                              value="10%" 
                              control={
                                <Radio 
                                  className={(value === "10%") ? classes.radio : ""}
                                  disabled={readOnly}/>
                              } 
                              label="10%" />
                            
                          </RadioGroup>
                      )}
                      name="kiwiSaverPercentage"
                      control={control}
                      defaultValue=""
                      rules={{
                          required: "This is required"
                      }}  
                  />
                  <FormHelperText>
                      {errors.kiwiSaverPercentage && errors.kiwiSaverPercentage.message}
                  </FormHelperText>
                </FormControl>
              }
        
              <h3 style={{marginBottom: 16}}>
                  Bank Account				
              </h3>

              <FormControl
                error={Boolean(errors.bankNumber)} 
                className={classes.input}>

                <FormLabel className={classes.label}>Bank Number</FormLabel>

                <Controller
                    as={
                        <TextField
                            inputProps={{
                              className: classes.field
                            }}
                            placeholder="Enter bank number"
                            fullWidth
                            variant="filled"
                            error={errors.bankNumber ? true : false}
                            helperText={errors.bankNumber?.message}
                            disabled={readOnly}/>
                    }
                    name="bankNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required"
                    }}
                />
              </FormControl>

              <FormControl
                error={Boolean(errors.branch)} 
                className={classes.input}>

                <FormLabel className={classes.label}>Branch</FormLabel>

                <Controller
                    as={
                        <TextField
                            inputProps={{
                              className: classes.field
                            }}
                            placeholder="Enter branch"
                            fullWidth
                            variant="filled"
                            error={errors.branch ? true : false}
                            helperText={errors.branch?.message}
                            disabled={readOnly}/>
                    }
                    name="branch"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required"
                    }}
                />
              </FormControl>

              <FormControl
                error={Boolean(errors.accountName)} 
                className={classes.input}>

                <FormLabel className={classes.label}>Account Name</FormLabel>

                <Controller
                    as={
                        <TextField
                            inputProps={{
                              className: classes.field
                            }}
                            placeholder="Enter account name"
                            fullWidth
                            variant="filled"
                            error={errors.accountName ? true : false}
                            helperText={errors.accountName?.message}
                            disabled={readOnly}/>
                    }
                    name="accountName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required"
                    }}
                />
              </FormControl>

              <h3>
                Candidate Decleration
              </h3>

              <p style={{marginBottom: 16}}>
                I declare that the information provided above is complete and accurate and I understand that Lowie Recruitment takes no responsibility for wages that have been deposited into an incorrect account based on the information provided by me.
              </p>
              
              <FormControl
                error={Boolean(errors.fullName)} 
                className={classes.input}>

                <FormLabel className={classes.label}>Full Name</FormLabel>

                <Controller
                    as={
                        <TextField
                            inputProps={{
                              className: classes.field
                            }}
                            placeholder="Enter full name"
                            fullWidth
                            variant="filled"
                            error={errors.fullName ? true : false}
                            helperText={errors.fullName?.message}
                            disabled={readOnly}/>
                    }
                    name="fullName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required"
                    }}
                />
              </FormControl>

              <FormControl
                error={Boolean(errors.signature)} 
                className={classes.input}>

                <FormLabel className={classes.label}>Signature</FormLabel>

                <Controller
                    as={
                        <TextField
                            inputProps={{
                              className: classes.field
                            }}
                            placeholder="Enter signature"
                            fullWidth
                            variant="filled"
                            error={errors.signature ? true : false}
                            helperText={errors.signature?.message}
                            disabled={readOnly}/>
                    }
                    name="signature"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required"
                    }}
                />
              </FormControl>

              <FormControl
                  error={Boolean(errors.date)} 
                  className={classes.input}>

                  <FormLabel className={classes.label}>Date</FormLabel>

                  <Controller
                      render={({onChange, value}) => (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container>
                                  <KeyboardDatePicker
                                      inputProps={{
                                        className: classes.field
                                      }}
                                      autoOk
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      margin="normal"
                                      id="date-picker2"
                                      value={value}
                                      onChange={date => onChange(date)} 
                                      disabled={readOnly}/>
                              </Grid>
                          </MuiPickersUtilsProvider>
                      )}
                      name="date"
                      control={control}
                      defaultValue={null}
                      rules={{
                          required: "This is required"
                      }}
                  />
                  <FormHelperText>
                      {errors.date && errors.date.message}
                  </FormHelperText>
              </FormControl>
            </div>
            
              <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}/>
                {!readOnly &&
                    <div>
                        {error &&
                            <span style={{color: "red", marginRight: 8}}>{error}</span>
                        }
                        <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => {
                            setReadOnly(true)
                            refreshCandidateData()
                            clearErrors()
                        }}>
                            Cancel
                            <Clear style={{marginLeft: 4}} fontSize="small" />
                        </Button>
                        {loading ?
                            <Button variant="contained" color="primary" className={classes.actionButton}>
                                <CircularProgress size={25} style={{color: "white"}} />
                            </Button>
                        :
                            <Button onClick={handleSubmit(onSave)} variant="contained" color="primary" className={classes.actionButton}>
                                Save
                                <Save style={{marginLeft: 4}} fontSize="small" />
                            </Button>
                        }
                    </div>
                }
              </div>
            </div>
          </AccordionDetails>
        </Accordion>  
      </div>      
    )
}
export default PayrollCard;


  