import React, { useState } from "react";
import {
  Accordion, AccordionSummary, AccordionDetails, Grid
} from "@material-ui/core"
import { ExpandMore } from "@material-ui/icons"
import { IndustrialTestInfo } from '../../../../types/CandidateTypes';

interface Props {
    data?: IndustrialTestInfo
    dataPdf: boolean
    industrialTestExpanded: boolean;
    setIndustrialTestExpanded: (open: boolean) => void;
}

export default function IndustrialTestCard({ data, dataPdf, industrialTestExpanded, setIndustrialTestExpanded }: Props) {

    return (
        <div id="industrial-test">
            <Accordion 
                style={{marginTop: 32}}
                expanded={industrialTestExpanded || dataPdf}
                onChange={() => setIndustrialTestExpanded(!industrialTestExpanded)}> 
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <h3>Industrial Test</h3>
                </AccordionSummary>
                <AccordionDetails>
                    {data && 
                        <Grid container spacing={2} id="industrial-test-div">
                            {data.data && Object.entries(data.data).sort(([q1], [q2]) => (Number(q1.match(/(\d+)/g)!![0]) - Number(q2.match(/(\d+)/g)!![0]))).map(function([_, question], index: number) {
                                return (
                                    <Grid item xs={12} md={6} key={index}>
                                        <h4>{question.question.replace(/\s.\d.\?/g, " 2?")}</h4>
                                        <p>{question.answer}</p>
                                    </Grid>
                                )})}
                        </Grid>
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
