import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { 
    makeStyles, createStyles 
} from '@material-ui/core/styles';
import { 
    Divider, LinearProgress, Tabs, Tab, Paper,
    Typography, Button, Menu, MenuItem, Snackbar
} from "@material-ui/core";
import TabPanel from '../../components/TabPanel';
import { API } from "aws-amplify";
import { Job } from './Jobs';
import { ApplicationsTable } from '../applications/ApplicationsTable';
import {
    AccountBalanceWallet, NoteAdd, FormatListBulleted,
    LocationOn, DateRange
} from "@material-ui/icons"
import { format, parseISO } from 'date-fns';
import { Application } from '../../types/CandidateTypes';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => 
    createStyles({
        header: {
            display: "flex"
        },
        table: {
            minWidth: 650,
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        classificationInfo: {
            display: 'flex', 
            alignItems: 'center', 
            flexWrap: 'wrap',
            paddingBottom: 8,
            paddingLeft: 32,
            paddingRight: 32
        },
        jobInfoIcon: {
            marginRight: 4
        },
        subHeadingDiv: {
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 16,
            paddingLeft: 32,
            paddingRight: 32
        },
        jobInfo: {
            display: 'flex', 
            alignItems: 'center', 
            flexWrap: 'wrap',
        },
    })
);

interface Props {
    user: any | null
}

interface ParamTypes {
    jobRef: string;
}

interface JobDetail {
    detail: Job,
    applicants: Array<Application>
}

export default function JobDetails({ user }: Props) {

    const classes = useStyles()
    const { jobRef } = useParams<ParamTypes>()

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [job, setJob] = useState<Job>()
    const [applicants, setApplicants] = useState<Array<Application>>([])
    const [jobLinkMenu, setJobLinkMenu] = useState<null | HTMLElement>(null)
    const [openCopiedSnackbar, setOpenCopiedSnackbar] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        API.get("", `/jobs/${jobRef}`, {})
        .then((response: JobDetail) => {
            if(response) {
                setJob(response.detail)
                setApplicants(response.applicants)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error fetching job", error)
            setLoading(false)
        })

    }, [])

    const getJobDetail = () => {
        setLoading(true)
        API.get("", `/jobs/${jobRef}`, {})
        .then((response: JobDetail) => {
            if(response) {
                setJob(response.detail)
                setApplicants(response.applicants)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error fetching job", error)
            setLoading(false)
        })
    }

    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue);
    };

    const calcSalary = (job: Job) : string => {
        if(job.SalaryMin === "" || job.SalaryMin === "0") {
            return `Not Listed`
        } else if (job.SalaryMax === "" || job.SalaryMax === "0") {
            return `Not Listed`
        } else if (parseInt(job.SalaryMin) === parseInt(job.SalaryMax)) {
            if(job.SalaryType === "annum") {
                return `$${job.SalaryMin}/p.a.`
            }
            return `$${job.SalaryMin}/${job.SalaryType}`
        }
    
        if(job.SalaryType === "annum") {
            return `$${job.SalaryMin}-${job.SalaryMax}/p.a.`
        }
        return `$${job.SalaryMin}-${job.SalaryMax}/${job.SalaryType}`
    }
    
    const calculateExpiryDate = (timestamp: string) : string => {
        let timeInSecs = parseInt(timestamp)
        let date = new Date(0);
        date.setUTCSeconds(timeInSecs);
        let expiryDate = new Date(date);
        expiryDate.setDate(expiryDate.getDate() + 30);
        return expiryDate.toDateString();
    }

    const convertTimestampToDate = (timestamp: string) : string => {
        let timeInSecs = parseInt(timestamp)
        let date = new Date(0);
        date.setUTCSeconds(timeInSecs);
        return date.toDateString();
    }

    const formatDateTime = (date: string): string => {
        return format(parseISO(date), 'HH:mm dd/MM/yyyy')
    }
    
    function getStatusColor(status: string) : string {
        let color;
        switch (status) {
            case "NEW APPLICANT": 
                color = "#26c6da";
                break;
            case "INVITED":
                color = "#42a5f5";
                break;
            case "ONBOARDING":
                color = "#ffa726";
                break;
            case "ONBOARDED":
                color = "#9ccc65";
                break;
            default:
                color = "#ef5350"
        }
        return color;
    }

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Job Detail
                </h2>
                <div style={{marginTop: 16}}>
                    <Button variant="contained" color="primary" onClick={(event: React.MouseEvent<HTMLButtonElement>) => setJobLinkMenu(event.currentTarget)}>
                        Copy Job Link
                    </Button>
                    <Menu style={{marginTop: 48}} open={Boolean(jobLinkMenu)} anchorEl={jobLinkMenu} onClose={() => setJobLinkMenu(null)}>
                        <MenuItem onClick={() => {
                            setJobLinkMenu(null)
                            navigator.clipboard.writeText(`https://app.lowie.co.nz/#/apply?jobRef=${jobRef}&referrer=Facebook`)
                            setOpenCopiedSnackbar(true)
                        }}>
                            Facebook
                        </MenuItem>
                        <MenuItem onClick={() => {
                            setJobLinkMenu(null)
                            navigator.clipboard.writeText(`https://app.lowie.co.nz/#/apply?jobRef=${jobRef}&referrer=myjobspace`)
                            setOpenCopiedSnackbar(true)
                        }}>
                            MyJobSpace
                        </MenuItem>

                        <MenuItem onClick={() => {
                            setJobLinkMenu(null)
                            navigator.clipboard.writeText(`https://app.lowie.co.nz/#/apply?jobRef=${jobRef}&referrer=Seek`)
                            setOpenCopiedSnackbar(true)
                        }}>
                            Seek
                        </MenuItem>
                        
                        <MenuItem onClick={() => {
                            setJobLinkMenu(null)
                            navigator.clipboard.writeText(`https://app.lowie.co.nz/#/apply?jobRef=${jobRef}&referrer=Trademe`)
                            setOpenCopiedSnackbar(true)
                        }}>
                            Trademe
                        </MenuItem>
                        
                        <MenuItem onClick={() => {
                            setJobLinkMenu(null)
                            navigator.clipboard.writeText(`https://app.lowie.co.nz/#/apply?jobRef=${jobRef}&referrer=Portal`)
                            setOpenCopiedSnackbar(true)
                        }}>
                            Portal
                        </MenuItem>
                    </Menu>

                    <Snackbar open={openCopiedSnackbar} autoHideDuration={6000} onClose={() => setOpenCopiedSnackbar(false)}>
                        <Alert onClose={() => setOpenCopiedSnackbar(false)} severity="success">
                            Copied to Clipboard
                        </Alert>
                    </Snackbar>
                </div>
            </div>
            <Tabs value={selectedTab} onChange={(_, newValue: number) => handleTabChange(newValue)}>
                <Tab label="Detail" />
                <Tab label="Applicants" />
            </Tabs>
            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            <TabPanel value={selectedTab} index={0}>
                {job &&
                    <div>
                        <Paper style={{marginTop: 8, marginBottom: 16}}>
                            <div style={{display: "flex", justifyContent: "space-evenly"}}>
                                <h3>No. of Applicants: {applicants.length}</h3>
                                <h3>Date posted: {convertTimestampToDate(job.timestamp)}</h3>
                            </div>
                        </Paper>
                        <Paper style={{marginTop: 8, marginBottom: 8, padding: 16}} elevation={3}>
                            <h2 style={{paddingLeft: 32}}>
                                {job.Title}
                            </h2>

                            <div className={classes.classificationInfo}> 
                                <FormatListBulleted className={classes.jobInfoIcon}/> 
                                <Typography variant="subtitle1">
                                    {job.Classification} / {job.Position}
                                </Typography>
                            </div> 
                            <div className={classes.subHeadingDiv}>
                                <div className={classes.jobInfo}> 
                                    <LocationOn className={classes.jobInfoIcon}/> 
                                    <Typography variant="subtitle1">
                                        {job.Location}
                                    </Typography>
                                </div> 
                                <div className={classes.jobInfo}> 
                                    <AccountBalanceWallet className={classes.jobInfoIcon} /> 
                                    <Typography variant="subtitle1">
                                        {calcSalary(job)}
                                    </Typography>
                                </div> 
                                <div className={classes.jobInfo}> 
                                    <NoteAdd className={classes.jobInfoIcon} /> 
                                    <Typography variant="subtitle1">
                                        {convertTimestampToDate(job.timestamp)}
                                    </Typography>
                                </div> 
                                <div className={classes.jobInfo}> 
                                    <DateRange className={classes.jobInfoIcon} /> 
                                    <Typography variant="subtitle1">
                                        {calculateExpiryDate(job.timestamp)}
                                    </Typography>
                                </div> 
                            </div>
                            <Divider />
                            <div style={{padding: 32}} dangerouslySetInnerHTML={{__html: job.AdDetail}} />
                        </Paper>
                    </div>
                    
                }

            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <div style={{marginTop: 8, marginBottom: 8}}>
                    <ApplicationsTable
                        isJob={true}
                        applications={applicants}
                        user={user}
                        refreshData={getJobDetail} status={''}                    />
                </div>                
            </TabPanel>
        </div>
    )
}
