import React, { useState, useEffect } from 'react'
import { 
    Button, Typography, Dialog, DialogTitle, 
    DialogContent, DialogActions, CircularProgress
} from "@material-ui/core"; 
import { API } from "aws-amplify"
import { Application } from '../../types/CandidateTypes';

interface OnboardCandidateDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    application: Application | undefined
    user: any | null
    refreshData: () => void
}

export default function OnboardCandidateDialog({ open, handleDialog, application, user, refreshData }: OnboardCandidateDialogProps) {
    
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    useEffect(() => {
        if(open) {
            setError(undefined)
        }
    }, [open])

    const onSubmit = () => {
        if(user && application) {
            setLoading(true)

            let params = {
                body: {
                    application: application,
                    status: "COMPLETED",
                    user: user.username
                }
            }

            API.post("", "/jobApplications/update", params)
            .then(() => {
                setLoading(false)
                handleDialog(false)
                refreshData()
            })
            .catch((error: any) => {
                setLoading(false)
                setError("Error: unable to onboard candidate")
                console.log("Error: updating job application", error)
            })
        } else {
            setError("Error: unable to onboard candidate")
        }
    }
    
    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Onboard Candidate
            </DialogTitle>
            <DialogContent>
                {application ?
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Are you sure you want to onboard {`${application.firstName} ${application.lastName}`} for the {application.position} position.
                    </Typography>
                :
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Are you sure you want to onboard this candidate.
                    </Typography>
                }
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleDialog(false)}  color="secondary">
                    No
                </Button>
                {loading ?
                    <Button variant="contained" color="primary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => onSubmit()} variant="contained" color="primary">
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
