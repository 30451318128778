import React, { useState } from 'react'
import { 
    Button, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress
} from "@material-ui/core"; 
import { API } from "aws-amplify";

interface DeleteSkillDialogProps {
    openDialog: boolean
    handleDeleteDialog: (open: boolean) => void
    skill?: string
    group?: string
    candidateId: string
    getCandidateSkills: () => void
    user?: any | null
}

export function DeleteSkillDialog({ openDialog, handleDeleteDialog, skill, group, candidateId, getCandidateSkills, user }: DeleteSkillDialogProps) {

    const [loading, setLoading] = useState<boolean>(false) 

    function handleDeleteSkill() {

        if(skill && group && user) {
            setLoading(true)
        
            const params = {
                body:{
                    data: `${group}#${skill}`,
                    candidate: candidateId,
                    user: user.username
                }
            };
    
            API.post("", "/candidates/skills/removeSkill", params)
            .then(() => {
                handleDeleteDialog(false)
                setLoading(false)
                getCandidateSkills()
            })
            .catch((error: any) => {
                console.log("ERROR: deleting skill", error)
                setLoading(false)
            })
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleDeleteDialog(false)}>
            <DialogTitle>
                Delete Skill
            </DialogTitle>
            <DialogContent>
                {skill ?
                    <span>Are you sure you want to delete {skill} skill</span>
                :
                    <span>Are you sure you want to delete this skill</span>
                }
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleDeleteDialog(false)}>
                    Cancel
                </Button>
                {loading ? 
                    <Button variant="contained" style={{backgroundColor: "#f57f17"}}>
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button onClick={() => handleDeleteSkill()} variant="contained" style={{color: "white", backgroundColor: "#f57f17"}}>
                        Delete
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}
