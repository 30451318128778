import React, { useEffect, useState } from 'react'
import { 
    Card, CardContent, CardHeader, List, ListItem, 
    ListItemText, Divider, Radio, ListItemSecondaryAction,
    Grid, Button
} from "@material-ui/core"; 
import { API } from "aws-amplify";
import { InterviewTemplateInfo } from '../../../types/InterviewTypes';
import { FormatDateTime } from '../../../utils';
import { Edit, Add, Delete } from '@material-ui/icons';
import TemplateDialog from '../dialogs/TemplateDialog';
import DeleteTemplateDialog from '../dialogs/DeleteTemplateDialog';

interface Props {
    selectedTab: number
    setLoading: (loading: boolean) => void
    loading: boolean
    user: any | null
}

export default function Templates({ selectedTab, setLoading, loading, user }: Props) {

    const [templates, setTemplates] = useState<Array<InterviewTemplateInfo>>([])
    const [selectedTemplateIdx, setSelectedTemplateIdx] = useState<number>(0)
    const [openTemplateDialog, setOpenTemplateDialog] = useState<boolean>(false)
    const [openDeleteTemplateDialog, setOpenDeleteTemplateDialog] = useState<boolean>(false)
    const [mode, setMode] = useState<string>('Add')

    useEffect(() => {
        if(selectedTab === 2) {
            setLoading(true)
            API.get("", '/cms/interview-templates', {})
            .then((response: Array<InterviewTemplateInfo>) => {
                if(response) {
                    setTemplates(response.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1))
                    setSelectedTemplateIdx(0)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("Error: fetching interview templates", error)
            })
        }
    }, [selectedTab])

    const getInterviewTemplates = () => {
        setLoading(true)
        API.get("", '/cms/interview-templates', {})
        .then((response: Array<InterviewTemplateInfo>) => {
            if(response) {
                setTemplates(response.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1))
                setSelectedTemplateIdx(0)
            }
            setLoading(false)
        })
        .catch((error: any) => {
            setLoading(false)
            console.log("Error: fetching interview templates", error)
        })
    }

    return (
        <div>
            <Card style={{height: "100vh"}}>
                <CardHeader title="Templates"/>
                <CardContent>
                    <div style={{display: "flex"}}>
                        <div style={{flexGrow: 1}}></div>
                        <div>
                            {templates.length !== 0 &&
                                <>
                                    <Button variant="contained" color="primary" disabled={loading} onClick={() => setOpenDeleteTemplateDialog(true)}>
                                        Delete
                                        <Delete style={{marginLeft: 8}} />
                                    </Button>
                                    <Button variant="contained" color="primary" style={{marginLeft: 8}} disabled={loading} onClick={() => {
                                        setMode("Edit")
                                        setOpenTemplateDialog(true)
                                    }}>
                                        Edit
                                        <Edit style={{marginLeft: 8}} />
                                    </Button>
                                </>
                            }
                            <Button variant="contained" color="secondary" style={{marginLeft: 8}} disabled={loading} onClick={() => {
                                setMode("Add")
                                setOpenTemplateDialog(true)
                            }}>
                                Add
                                <Add style={{marginLeft: 8}} />
                            </Button>
                        </div>
                    </div>
                    <div style={{display: "flex"}}>
                        <div style={{minWidth: 275}}>
                            <List>
                                {templates.length !== 0 && templates.map((template: InterviewTemplateInfo, i: number) => (
                                    <div key={template.sk}>
                                        <ListItem>
                                            <ListItemText 
                                                primary={template.templateName}
                                                secondaryTypographyProps={{
                                                    component: 'div'
                                                }}
                                                secondary={
                                                    <div>
                                                        <div>Updated By: {template.updatedBy}</div>
                                                        <div>Updated At: {FormatDateTime(template.updatedAt)}</div>
                                                    </div>
                                                }/>
                                            <ListItemSecondaryAction>
                                                <Radio
                                                    disabled={loading}
                                                    checked={i === selectedTemplateIdx}
                                                    onChange={() => {
                                                        setSelectedTemplateIdx(i)
                                                    }}/>
                                            </ListItemSecondaryAction>
                                                
                                        </ListItem>
                                        {i !== templates.length -1 &&
                                            <Divider />
                                        }
                                    </div>
                                ))}
                            </List>  
                        </div>
                        <div style={{flexGrow: 1}}>
                            {templates.length !== 0 &&
                                <Grid container style={{padding: 8}} spacing={0}>
                                    <Grid item xs={12}>
                                        <h4>Template Name</h4>
                                        <p>
                                            {templates[selectedTemplateIdx].templateName}
                                        </p>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <h4>Message</h4>
                                        <p style={{whiteSpace: "break-spaces"}}>
                                            {templates[selectedTemplateIdx].message}
                                        </p>
                                    </Grid>
                                </Grid>
                            }
                        </div>
                    </div>
                </CardContent>
            </Card>

            <TemplateDialog 
                open={openTemplateDialog}
                handleDialog={setOpenTemplateDialog} 
                template={(mode === 'Edit' && templates.length !== 0) ? templates[selectedTemplateIdx] : undefined}
                templates={templates}
                user={user}
                getInterviewTemplates={getInterviewTemplates}
            />

            <DeleteTemplateDialog
                open={openDeleteTemplateDialog}
                handleDialog={setOpenDeleteTemplateDialog}
                template={templates.length !== 0 ? templates[selectedTemplateIdx] : undefined}
                getInterviewTemplates={getInterviewTemplates}
            />
        </div>
    )
}
