import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Button, Dialog, DialogTitle, DialogContent, DialogActions, 
    FormControl, FormLabel, TextField, CircularProgress
} from "@material-ui/core"; 
import { useForm, Controller } from "react-hook-form"; 
import { API } from "aws-amplify";
import { SkillGroup } from '../../../types/SkillTypes';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        label: {
            marginBottom: 6
        }
    })
);

interface AddGroupDialogProps {
    openDialog: boolean
    handleGroupDialog: (open: boolean) => void
    skills: Array<SkillGroup>
}

interface AddSkillGroupForm {
    group: string
}

export default function AddGroupDialog({ openDialog, handleGroupDialog, skills }: AddGroupDialogProps) {

    const classes = useStyles()

    const { handleSubmit, control, errors, setError } = useForm<AddSkillGroupForm>();
    const [loading, setLoading] = useState<boolean>(false) 

    function onSubmit(data: AddSkillGroupForm) {
        if(data.group.includes('#')) {
            setError("group", {
                type: "required",
                message: "Invalid Character. Cannot contain # symbol."
              })
              return

        } else {
            setLoading(true)
    
            let newGroup: SkillGroup = {
                Group: data.group,
                Skills: []
            }
    
            skills.push(newGroup)
            
            const params = {
                body:{
                    data: skills,
                }
            };
    
            API.post("", "/skills/setSkills", params)
            .then((response: any) => {
                setLoading(false)
                handleGroupDialog(false)
            })
            .catch((error: any) => {
                console.log("ERROR: posting skills", error)
                setLoading(false)
            })
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleGroupDialog(false)}>
            <DialogTitle>
                Add Skill Group
            </DialogTitle>
            <DialogContent>
                <FormControl
                    error={Boolean(errors.group)} 
                    style={{width: "100%"}}>

                    <FormLabel className={classes.label}>Group</FormLabel>

                    <Controller
                        as={
                            <TextField
                                placeholder="Enter Skill Group"
                                fullWidth
                                variant="filled"
                                error={errors.group ? true : false}
                                helperText={errors.group?.message}/>
                        }
                        name="group"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleGroupDialog(false)}>
                    Cancel
                </Button>
                {loading ? 
                    <Button variant="contained" color="primary">
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                        Add
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}