import React, { useEffect, useState } from 'react'
import { 
    TextField, Button, Dialog, DialogContent, DialogTitle, DialogActions, FormControl,
    FormLabel, CircularProgress
} from "@material-ui/core"; 
import {
    Autocomplete
} from "@material-ui/lab";
import { API } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";
import { SkillGroup, SkillsDto } from '../../../types/SkillTypes';
import { SkillType } from '../../../types/CandidateTypes';

interface SkillsDialogProps {
    openDialog: boolean
    handleSkillDialog: (open: boolean) => void
    candidateId: string
    getCandidateSkills: () => void;
    user?: any | null;
}

interface AddSkillsForm {
    skills: Array<SkillType>;
}

export function SkillsDialog({ openDialog, handleSkillDialog, candidateId, getCandidateSkills, user }: SkillsDialogProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [skills, setSkills] = useState<Array<SkillType>>([])
    const { handleSubmit, control, errors, setError } = useForm<AddSkillsForm>({
        defaultValues: {
            skills: []
        }
    });

    useEffect(() => {
        let isMounted = true;
        
        API.get("", "/skills", {})
        .then((response: SkillsDto) => {
            if(isMounted) {
                if(response) {
                    handleSkills(response.skills)
                }
            }
        })
        .catch((error: any) => {
            console.log("Error fetching skills", error)
        })
        
        return () => {
            isMounted = false;
        }
        
    }, [])

    const handleSkills = (skills: Array<SkillGroup>) => {
        let allSkills: Array<SkillType> = []
        skills.forEach((skillGroup: SkillGroup) => {
            skillGroup.Skills.forEach((skill: string) => {
                allSkills.push({skill: skill, group: skillGroup.Group})
            })
        })

        setSkills(allSkills)

    }

    const onSubmit = (data: AddSkillsForm) => {
        if(user) {
            if(data.skills.length === 0) {
                setError("skills", {
                    type: "required",
                    message: "This is required"
                  })
                  return
            } else {
    
                let skills: Array<string> = [];
                data.skills.forEach((skill: SkillType) => {
                    skills.push(`${skill.group}#${skill.skill}`)
                })
    
                setLoading(true)
                const params = {
                    body:{
                        data: skills,
                        candidate: candidateId,
                        user: user.username
                    }
                };
    
                API.post("", "/candidates/skills/setSkills", params)
                .then((response: any) => {
                    setLoading(false)
                    handleSkillDialog(false)
                    getCandidateSkills()
                })
                .catch((error: any) => {
                    console.log("ERROR: posting skills", error)
                    setLoading(false)
                })
    
            }
        }
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleSkillDialog(false)}>
            <DialogTitle>
                Add Skills
            </DialogTitle>
            <DialogContent>

                <FormControl 
                    style={{width: "100%"}}
                    error={Boolean(errors.skills)}>

                    <FormLabel style={{marginBottom: 6}}>Add Skill</FormLabel>
                    <Controller 
                        render={(props) => (
                            <Autocomplete
                                {...props}
                                multiple
                                options={skills}
                                groupBy={(option) => option.group}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        placeholder="Add Skill" 
                                        variant="outlined"
                                        error={errors.skills ? true : false}
                                        helperText={errors.skills ? "This is required" : ""} />
                                )}
                                getOptionLabel={(option) => option.skill}
                                onChange={(_, data) => props.onChange(data)}
                            />
                        )}
                        name="skills"
                        control={control}
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
                
            </DialogContent>
            <DialogActions>
                 <Button color="primary" onClick={() => handleSkillDialog(false)}>
                     Cancel
                 </Button>
                 {loading ? 
                    <Button variant="contained" color="primary">
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                        Add
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}