import React, { useState, useEffect } from 'react'
import { 
    Button, Typography, Dialog, DialogTitle, 
    DialogContent, DialogActions, CircularProgress, FormControlLabel, Checkbox
} from "@material-ui/core"; 
import { API } from "aws-amplify"
import { Application } from '../../types/CandidateTypes';

interface DeclineCandidateDialogProps {
    openDialog: boolean
    handleDialog: (open: boolean) => void
    application: Application | undefined
    user?: any | null
    refreshData: () => void;
}

export function DeclineCandidateDialog({ openDialog, handleDialog, application, user, refreshData }: DeclineCandidateDialogProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [sendEmail, setSendEmail] = useState<boolean>(false)

    useEffect(() => {
        setError(undefined)
    }, [])

    function onSubmit() {
        if(user) {
            setLoading(true)

            let params = {
                body: {
                    id: user.username,
                    data: application,
                    sendEmail: sendEmail
                }
            }

            API.post("", `/candidates/decline`, params)
            .then(() => {
                setLoading(false)
                handleDialog(false)
                refreshData()
            })
            .catch((error: any) => {
                console.log("Unable to decline candidate", error)
                setLoading(false)
                setError("Unable to decline candidate")
            })
        } else {
            setError("Unable to decline candidate")
        }

    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Decline Candidate
            </DialogTitle>
            <DialogContent>
                {application ?
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Are you sure you want to decline {`${application.firstName} ${application.lastName}`} for the {application.position} position
                    </Typography>
                :
                    <Typography variant="body1" style={{marginBottom: 8}}>
                        Are you sure you want to decline this candidate for this position.
                    </Typography>
                }
                <FormControlLabel
        control={<Checkbox checked={sendEmail} onChange={(e)=> setSendEmail(e.target.checked)} name="sendEmail" />}
        label="Send Decline Email to Candidate"
      />
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red"}}>{error}</span>
                }
                <Button onClick={() => handleDialog(false)}  color="secondary">
                    No
                </Button>
                {loading ?
                    <Button variant="contained" color="secondary">
                        <CircularProgress size={25} style={{color: "white"}} />
                    </Button>
                :
                    <Button onClick={() => onSubmit()} variant="contained" color="secondary">
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}