import React from 'react'
import { 
    Card, CardHeader
} from "@material-ui/core"; 

export default function Integrations() {
    return (
        <div>
            <Card style={{height: "100vh"}}>
                <CardHeader title="Integrations"/>
            </Card>
        </div>
    )
}
