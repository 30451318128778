import React from 'react'
import { 
    Button, Dialog, DialogTitle, 
    DialogContent, DialogActions
} from "@material-ui/core";
import { Application } from '../../types/CandidateTypes';

interface NoCvDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    application: Application | undefined
}

export function NoCvDialog({ open, handleDialog, application }: NoCvDialogProps) {

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                No CV Found
            </DialogTitle>
            <DialogContent>
                {application ?
                    <span>No CV file was found for {application.firstName} {application.lastName}</span>
                :
                    <span>No CV file was found for this candidate</span>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDialog(false)} variant="contained" color="secondary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}