import React, { useState, useEffect } from 'react'
import { 
    makeStyles, createStyles, Theme 
} from '@material-ui/core/styles';
import { 
    Divider, Grid, Button, LinearProgress, Typography,
    FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core"; 
import { 
    PersonOutlined, EmailOutlined, TimerOutlined, Check, 
    HistoryOutlined, AssignmentOutlined
} from "@material-ui/icons"
import { API } from "aws-amplify"
import { Application, Candidate } from '../../types/CandidateTypes';
import { ApplicationList, ApplicationsTable } from './ApplicationsTable';
import CandidateTable from '../candidates/CandidateTable';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        applicationCards: {
            marginLeft: 8,
            marginRight: 8
        },
        cardNumber: {
            textAlign: "right",
            fontSize: 38,
            fontWeight: "bold"
        },
        cardDescription: {
            fontWeight: "bold"
        },
        table: {
            minWidth: 650,
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        cardContent: {
            display: 'flex', 
            alignItems: 'center', 
            flexWrap: 'wrap',
            color: "white",
            padding: 4
        },
        cardIcon: {
            marginRight: 4,
            color: "white"
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 120,
        },
        addApplicationButton: {
            marginTop: 28
        },
        input: {
            marginBottom: theme.spacing(2),
            width: "100%"
        },
        label: {
            marginBottom: 6
        }
    })
);

interface Props {
    user?: any | null
}

export default function JobApplications({ user }: Props) {
    const classes = useStyles()

    const [applications, setApplications] = useState<Array<Application>>([])
    const [candidates, setCandidates] = useState<Array<Candidate>>([])
    const [applicationsByLocation, setApplicationsByLocation] = useState<Array<Application>>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [status, setStatus] = useState<string>('new')
    const [statusText, setStatusText] = useState<string>('NEW APPLICANT')
    const [location, setLocation] = useState<string>('Auckland')

    useEffect(() => {
        setLoading(true)
        setApplications([])
        setApplicationsByLocation([])
        API.get("", `/jobApplications/${status}`, "")
        .then((response: Array<any>) => {
            if(response) {
                // const cndts = response.reduce((cndts, item) => ({
                //     ...cndts,
                //     [item.email]: [...(cndts[item.email] || []), item]
                //   }), {});

                // const cndts = response.reduce(function(rv, x) {
                //     (rv[x['email']] = rv[x['email']] || []).push(x);
                //     return rv;
                //   }, {});
                  
                //   console.log(cndts);

                if(status === "onboarding" || status === "onboarded") {
                    setCandidates(response)
                } else {
                    setApplications(response)
                    setApplicationsByLocation(response.filter((a: Application) => a.location.includes(location)))
                }
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching job applications", error)
            setLoading(false)
        })

    }, [status])

    const refreshData = () => {
        setLoading(true)
        setApplications([])
        setApplicationsByLocation([])
        API.get("", `/jobApplications/${status}`, "")
        .then((response: Array<Application>) => {
            setApplications(response)
            setApplicationsByLocation(response.filter((a: Application) => a.location.includes(location)))
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("Error: fetching job applications", error)
            setLoading(false)
        })
    }

    const handleLocationChange = (newLocation: string) => {
        setLocation(newLocation)
        setApplicationsByLocation(applications.filter((a: Application) => a.location.includes(newLocation)))
    }

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Received Applications
                </h2>
                <div>
                <FormControl className={classes.formControl}>
                        <InputLabel id="location-select-input-label">Location</InputLabel>
                        <Select
                        labelId="location-select-label"
                        id="location-select"
                        value={location}
                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleLocationChange(event.target.value as string)}>
                            <MenuItem value={'Auckland'}>Auckland</MenuItem>
                            <MenuItem value={'Wellington'}>Wellington</MenuItem>
                            <MenuItem value={'Silverdale'}>Silverdale</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }
            <div style={{display: "flex", justifyContent: "space-between", marginTop: 16}}>
                <Grid container>
                    <Grid item xs={12} style={{marginRight: 8}}>
                        <Button style={{backgroundColor: "#26c6da", width: "100%"}} onClick={() => {
                            setStatus('new')
                            setStatusText('NEW APPLICANT')
                        }}>
                            <div className={classes.cardContent}>
                                <PersonOutlined className={classes.cardIcon} fontSize="large" />
                                <Typography className={classes.cardDescription} variant="subtitle1">
                                    New Applicant
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.applicationCards}>
                        <Button style={{backgroundColor: "#42a5f5", width: "100%"}} onClick={() => {
                            setStatus('invite')
                            setStatusText('INVITED')
                        }}>
                            <div className={classes.cardContent}>
                                <EmailOutlined className={classes.cardIcon} fontSize="large" />
                                <Typography className={classes.cardDescription} variant="subtitle1">
                                    INVITED
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.applicationCards}>
                        <Button style={{backgroundColor: "#5c6bc0", width: "100%"}} onClick={() => {
                            setStatus('interviewed')
                            setStatusText('INTERVIEWED')
                        }}>
                            <div className={classes.cardContent}>
                                <AssignmentOutlined className={classes.cardIcon} fontSize="large" />
                                <Typography className={classes.cardDescription} variant="subtitle1">
                                    Interviewed
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.applicationCards}>
                        <Button style={{backgroundColor: "#ffa726", width: "100%"}} onClick={() => {
                            setStatus('onboarding')
                            setStatusText('ONBOARDING')
                        }}>
                            <div className={classes.cardContent}>
                                <TimerOutlined className={classes.cardIcon} fontSize="large" />
                                <Typography className={classes.cardDescription} variant="subtitle1">
                                    Onboarding
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.applicationCards}>
                        <Button style={{backgroundColor: "#9ccc65", width: "100%"}} onClick={() => {
                            setStatus('onboarded')
                            setStatusText('ONBOARDED')
                        }}>
                            <div className={classes.cardContent}>
                                <Check className={classes.cardIcon} fontSize="large" />
                                <Typography className={classes.cardDescription} variant="subtitle1">
                                    Onboarded
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{marginLeft: 8}}>
                        <Button style={{backgroundColor: "#ef5350", width: "100%"}} onClick={() => {
                            setStatus('expired')
                            setStatusText('EXPIRED')
                        }}>
                            <div className={classes.cardContent}>
                                <HistoryOutlined className={classes.cardIcon} fontSize="large" />
                                <Typography className={classes.cardDescription} variant="subtitle1">
                                    Expired
                                </Typography>
                            </div>
                        </Button>
                    </Grid>
                </Grid>
                
            </div>
            <div style={{marginTop: 24}}>
                <h3>{statusText}</h3>
                {status === "onboarding" || status === "onboarded" ?
                    <CandidateTable
                        candidatesList={candidates}
                        selectedTags={Array()}
                        loading={loading}
                        skillSearch={false}
                    />
                :
                    <ApplicationList
                    status={status}
                        applications={applicationsByLocation}
                        user={user}
                        refreshData={refreshData} />
                }
            </div>
        </div>
    )
}