import React, { useEffect, useState } from 'react'
import { 
    makeStyles, createStyles, Theme, withStyles
} from '@material-ui/core/styles';
import { 
    Grid, FormControl, FormLabel, FormGroup, FormControlLabel, Button,
    Checkbox, FormHelperText, TextField, Radio, RadioGroup, Slider, CircularProgress, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@material-ui/core"; 
import { 
    Edit, Save, Clear
} from "@material-ui/icons"
import { 
    CandidateInterviewDto, InterviewForm
} from '../../../../types/InterviewTypes';
import { useForm, Controller } from "react-hook-form";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { API } from "aws-amplify";
import { faCloudShowersHeavy, faHourglassEnd } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            width: "100%",
            marginBottom: theme.spacing(2)
        },
        label: {
            marginBottom: 6
        },
        iconContainer: {
            transform: 'scale(1)',
        },
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        checkboxAvailability: {
            marginTop: 22,
            marginBottom: 8
        },
        toTimePicker: {
            marginLeft: 16,
            marginRight: 32
        },
        timePickerRow: {
            display: "flex", 
            marginTop: 16
        },
        editButton: {
            width: 100
        },
        actionButton: {
            width: 100
        },
        field: {
            '&.MuiInputBase-root.Mui-disabled': {
                color: "#000000"
            },
            '&.MuiInputBase-input.Mui-disabled': {
                color: "#000000"
            }
        },
        checkbox: {
            '&.MuiCheckbox-colorSecondary.Mui-disabled': {
                color: "#f50057"
            }
        },
        radio: {
            '&.MuiRadio-colorSecondary.Mui-disabled': {
              color: "#f50057"
            }
        },
        slider: {
            "&.MuiSlider-root.Mui-disabled": {
                color: "#3f51b5"
            }
        }
    })
);

interface Props {
    info: InterviewForm
    refreshInterviewList: () => void;
    user?: string;
    candidateId: string
}

export default function CandidateInterviewEdit({ info, refreshInterviewList, user, candidateId }: Props) {

    const classes = useStyles()

    const [readOnly, setReadOnly] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    // const [referenceCheckNumber, setReferenceCheckNumber] = useState<number>()
    // const [showReferenceVerifyDialog, setShowReferenceVerifyDialog] = useState<boolean>(false)

    const { handleSubmit, control, errors, watch, setValue, clearErrors, reset } = useForm<InterviewForm>();

    useEffect(() => {
        reset(info)
    }, [info])

    const onSave = (data: InterviewForm) => {
        if(user) {
            setLoading(true)
    
            var params = {
                body: {
                    data: {...info, ...data},
                    user: user,
                    id: candidateId,
                    pk: info.pk,
                    sk: info.sk
                }
            }
    
            API.post("", '/candidates/interview/update', params)
            .then(() => {
                setReadOnly(true)
                setLoading(false)
                refreshInterviewList()
            })
            .catch((error: any) => {
                console.log("Unable to post interview", error)
                setLoading(false)
                setError("Unable to add interview")
            })
    
        } else {
            setError("Unable to add interview")
        }
    }

    // const onVerifyReferenceCheckClicked = (item: number)=>{
    //     setReferenceCheckNumber(item)
    //     setShowReferenceVerifyDialog(true)
    // }

    // const onReferenceCheckVerifiedClicked = ()=>{
    //     setShowReferenceVerifyDialog(false)
    //     // setInfo((prev:any)=> {
    //     //     return {...prev, isReference1Validated:true}
    //     // })
    //     // console.log(info!)
    //     if(referenceCheckNumber == 1 ){
    //         onSave({...info!, isReference1Validated:true})
    //     }else{
    //         onSave({...info!, isReference2Validated:true})

    //     }
        
    // }

    return (
        <div>
            <div style={{width: "100%"}}>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: 1}}></div>
                    <div>
                        <Button variant="contained" color="primary" className={classes.editButton} onClick={() => setReadOnly(false)}>
                            Edit
                            <Edit style={{marginLeft: 4}} fontSize="small" />
                        </Button>
                    </div>
                </div>
                
                <div id={`interview${info.jobRef}-div`}>
                    <div>

                        <FormControl
                            error={Boolean(errors.consultantName)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Consultant name</FormLabel>

                            <Controller
                                as={
                                    <TextField 
                                        inputProps={{
                                            className: classes.field
                                        }} 
                                        placeholder="Enter consultant name"
                                        fullWidth
                                        variant="filled"
                                        disabled={readOnly}
                                        error={errors.consultantName ? true : false}
                                        helperText={errors.consultantName?.message}/>
                                }
                                name="consultantName"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                        </FormControl>

                        <h3>Availability</h3>
                        <div style={{display: "flex"}}>
                            
                            <FormGroup>
                                <FormControl
                                    error={Boolean(errors.availMonday)}
                                    className={classes.checkboxAvailability}>

                                    <Controller
                                        render={(props) => (
                                            <FormControlLabel 
                                                label="Monday"
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} 
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(!e.target.checked) {
                                                                setValue("monAllDay", false)
                                                                setValue("monFrom", null)
                                                                setValue("monTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value}
                                                        disabled={readOnly}
                                                    />
                                                }  
                                            />
                                        )}
                                        name="availMonday"
                                        control={control}
                                        defaultValue={false}
                                        />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.availTuesday)}
                                    className={classes.checkboxAvailability}>

                                    <Controller
                                        render={(props) => (
                                            <FormControlLabel 
                                                label="Tuesday"
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} 
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(!e.target.checked) {
                                                                setValue("tueAllDay", false)
                                                                setValue("tueFrom", null)
                                                                setValue("tueTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} 
                                                        disabled={readOnly}
                                                    />
                                                }  
                                            />
                                        )}
                                        name="availTuesday"
                                        control={control}
                                        defaultValue={false}
                                        />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.availWednesday)}
                                    className={classes.checkboxAvailability}>

                                    <Controller
                                        render={(props) => (
                                            <FormControlLabel 
                                                label="Wednesday"
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} 
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(!e.target.checked) {
                                                                setValue("wedAllDay", false)
                                                                setValue("wedFrom", null)
                                                                setValue("wedTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value}
                                                        disabled={readOnly}
                                                    />
                                                }  
                                            />
                                        )}
                                        name="availWednesday"
                                        control={control}
                                        defaultValue={false}
                                        />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.availThursday)}
                                    className={classes.checkboxAvailability}>

                                    <Controller
                                        render={(props) => (
                                            <FormControlLabel 
                                                label="Thursday"
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} 
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(!e.target.checked) {
                                                                setValue("thuAllDay", false)
                                                                setValue("thuFrom", null)
                                                                setValue("thuTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} 
                                                        disabled={readOnly}
                                                    />
                                                }  
                                            />
                                        )}
                                        name="availThursday"
                                        control={control}
                                        defaultValue={false}
                                        />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.availFriday)}
                                    className={classes.checkboxAvailability}>

                                    <Controller
                                        render={(props) => (
                                            <FormControlLabel 
                                                label="Friday"
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} 
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(!e.target.checked) {
                                                                setValue("friAllDay", false)
                                                                setValue("friFrom", null)
                                                                setValue("friTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} 
                                                        disabled={readOnly}
                                                    />
                                                }  
                                            />
                                        )}
                                        name="availFriday"
                                        control={control}
                                        defaultValue={false}
                                        />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.availSaturday)}
                                    className={classes.checkboxAvailability}>

                                    <Controller
                                        render={(props) => (
                                            <FormControlLabel 
                                                label="Saturday"
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} 
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(!e.target.checked) {
                                                                setValue("satAllDay", false)
                                                                setValue("satFrom", null)
                                                                setValue("satTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} 
                                                        disabled={readOnly}
                                                    />
                                                }  
                                            />
                                        )}
                                        name="availSaturday"
                                        control={control}
                                        defaultValue={false}
                                        />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.availSunday)}
                                    className={classes.checkboxAvailability}>

                                    <Controller
                                        render={(props) => (
                                            <FormControlLabel 
                                                label="Sunday"
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} 
                                                        onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(!e.target.checked) {
                                                                setValue("sunAllDay", false)
                                                                setValue("sunFrom", null)
                                                                setValue("sunTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} 
                                                        disabled={readOnly}
                                                    />
                                                }  
                                            />
                                        )}
                                        name="availSunday"
                                        control={control}
                                        defaultValue={false}
                                        />
                                </FormControl>
                            </FormGroup>
                            <div>
                                <div style={{display: "flex"}}>
                                    <FormControl
                                        error={Boolean(errors.monFrom)}>

                                        <FormLabel className={classes.label}>From time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availMonday") === false || watch("availMonday") === undefined || watch("monAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="monFrom"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.monFrom && errors.monFrom.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        error={Boolean(errors.monTo)}
                                        className={classes.toTimePicker}>

                                        <FormLabel className={classes.label}>To time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availMonday") === false || watch("availMonday") === undefined || watch("monAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="monTo"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.monTo && "This is required"}
                                        </FormHelperText>
                                    </FormControl>
                                </div>

                                <div className={classes.timePickerRow}>
                                    <FormControl
                                        error={Boolean(errors.tueFrom)}>

                                        <FormLabel className={classes.label}>From time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availTuesday") === false || watch("availTuesday") === undefined || watch("tueAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="tueFrom"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.tueFrom && errors.tueFrom.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        error={Boolean(errors.tueTo)}
                                        className={classes.toTimePicker}>

                                        <FormLabel className={classes.label}>To time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availTuesday") === false || watch("availTuesday") === undefined || watch("tueAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="tueTo"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.tueTo && errors.tueTo.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>

                                <div className={classes.timePickerRow}>
                                    <FormControl
                                        error={Boolean(errors.wedFrom)}>

                                        <FormLabel className={classes.label}>From time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availWednesday") === false || watch("availWednesday") === undefined || watch("wedAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="wedFrom"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.wedFrom && errors.wedFrom.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        error={Boolean(errors.wedTo)}
                                        className={classes.toTimePicker}>

                                        <FormLabel className={classes.label}>To time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availWednesday") === false || watch("availWednesday") === undefined || watch("wedAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="wedTo"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.wedTo && errors.wedTo.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>

                                <div className={classes.timePickerRow}>
                                    <FormControl
                                        error={Boolean(errors.thuFrom)}>

                                        <FormLabel className={classes.label}>From time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availThursday") === false || watch("availThursday") === undefined || watch("thuAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="thuFrom"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.thuFrom && errors.thuFrom.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        error={Boolean(errors.thuTo)}
                                        className={classes.toTimePicker}>

                                        <FormLabel className={classes.label}>To time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availThursday") === false || watch("availThursday") === undefined || watch("thuAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="thuTo"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.thuTo && errors.thuTo.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>

                                <div className={classes.timePickerRow}>
                                    <FormControl
                                        error={Boolean(errors.friFrom)}>

                                        <FormLabel className={classes.label}>From time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availFriday") === false || watch("availFriday") === undefined || watch("friAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="friFrom"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.friFrom && errors.friFrom.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        error={Boolean(errors.friTo)}
                                        className={classes.toTimePicker}>

                                        <FormLabel className={classes.label}>To time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availFriday") === false || watch("availFriday") === undefined || watch("friAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="friTo"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.friTo && errors.friTo.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>

                                <div className={classes.timePickerRow}>
                                    <FormControl
                                        error={Boolean(errors.satFrom)}>

                                        <FormLabel className={classes.label}>From time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availSaturday") === false || watch("availSaturday") === undefined || watch("satAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="satFrom"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.satFrom && errors.satFrom.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        error={Boolean(errors.satTo)}
                                        className={classes.toTimePicker}>

                                        <FormLabel className={classes.label}>To time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availSaturday") === false || watch("availTuesday") === undefined || watch("satAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="satTo"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.satTo && errors.satTo.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>

                                <div className={classes.timePickerRow}>
                                    <FormControl
                                        error={Boolean(errors.sunFrom)}>

                                        <FormLabel className={classes.label}>From time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availSunday") === false || watch("availSunday") === undefined || watch("sunAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="sunFrom"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.sunFrom && errors.sunFrom.message}
                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl
                                        error={Boolean(errors.sunTo)}
                                        className={classes.toTimePicker}>

                                        <FormLabel className={classes.label}>To time</FormLabel>

                                        <Controller
                                            render={({onChange, value}) => (
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <Grid container>
                                                        <KeyboardTimePicker
                                                            inputProps={{
                                                                className: classes.field
                                                            }}
                                                            ampm
                                                            variant="inline"
                                                            value={value}
                                                            minutesStep={30}
                                                            onChange={timeStamp => onChange(timeStamp)}
                                                            disabled={readOnly || (watch("availSunday") === false || watch("availSunday") === undefined || watch("sunAllDay") === true)}/>
                                                    </Grid>
                                                </MuiPickersUtilsProvider>
                                            )}
                                            name="sunTo"
                                            control={control}
                                            defaultValue={null}
                                        />
                                        <FormHelperText>
                                            {errors.sunTo && errors.sunTo.message}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                            </div>
                            <div style={{flexGrow: 1}}>
                                <div>
                                    <FormControl
                                        error={Boolean(errors.monAllDay)}
                                        className={classes.checkboxAvailability}>

                                        <Controller
                                            render={(props) => (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            className={props.value ? classes.checkbox : ""} onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(e.target.checked) {
                                                                setValue("monFrom", null)
                                                                setValue("monTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} disabled={readOnly} />
                                                    } 
                                                    label="All Day" />
                                            )}
                                            name="monAllDay"
                                            control={control}
                                            defaultValue={false}
                                            />
                                    </FormControl>
                                </div>
                                
                                <div>
                                    <FormControl
                                        error={Boolean(errors.tueAllDay)}
                                        className={classes.checkboxAvailability}>

                                        <Controller
                                            render={(props) => (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            className={props.value ? classes.checkbox : ""} onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(e.target.checked) {
                                                                setValue("tueFrom", null)
                                                                setValue("tueTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} disabled={readOnly} />
                                                    } 
                                                    label="All Day" />
                                            )}
                                            name="tueAllDay"
                                            control={control}
                                            defaultValue={false}
                                            />
                                    </FormControl>
                                </div>

                                <div>
                                    <FormControl
                                        error={Boolean(errors.wedAllDay)}
                                        className={classes.checkboxAvailability}>

                                        <Controller
                                            render={(props) => (
                                                <FormControlLabel 
                                                control={
                                                    <Checkbox
                                                        className={props.value ? classes.checkbox : ""} onChange={e => {
                                                        props.onChange(e.target.checked)
                                                        if(e.target.checked) {
                                                            setValue("wedFrom", null)
                                                            setValue("wedTo", null)
                                                        }
                                                    }} 
                                                    checked={props.value} disabled={readOnly} />
                                                } 
                                                label="All Day" />
                                            )}
                                            name="wedAllDay"
                                            control={control}
                                            defaultValue={false}
                                            />
                                    </FormControl>
                                </div>

                                <div>
                                    <FormControl
                                        error={Boolean(errors.thuAllDay)}
                                        className={classes.checkboxAvailability}>

                                        <Controller
                                            render={(props) => (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            className={props.value ? classes.checkbox : ""} onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(e.target.checked) {
                                                                setValue("thuFrom", null)
                                                                setValue("thuTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} disabled={readOnly} />
                                                    } 
                                                    label="All Day" />
                                            )}
                                            name="thuAllDay"
                                            control={control}
                                            defaultValue={false}
                                            />
                                    </FormControl>
                                </div>

                                <div>
                                    <FormControl
                                        error={Boolean(errors.friAllDay)}
                                        className={classes.checkboxAvailability}>

                                        <Controller
                                            render={(props) => (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            className={props.value ? classes.checkbox : ""} onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(e.target.checked) {
                                                                setValue("friFrom", null)
                                                                setValue("friTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} disabled={readOnly} />
                                                    } 
                                                    label="All Day" />
                                            )}
                                            name="friAllDay"
                                            control={control}
                                            defaultValue={false}
                                            />
                                    </FormControl>
                                </div>

                                <div>
                                    <FormControl
                                        error={Boolean(errors.satAllDay)}
                                        className={classes.checkboxAvailability}>

                                        <Controller
                                            render={(props) => (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            className={props.value ? classes.checkbox : ""} onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(e.target.checked) {
                                                                setValue("satFrom", null)
                                                                setValue("satTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} disabled={readOnly} />
                                                    } 
                                                    label="All Day" />
                                            )}
                                            name="satAllDay"
                                            control={control}
                                            defaultValue={false}
                                            />
                                    </FormControl>
                                </div>

                                <div>
                                    <FormControl
                                        error={Boolean(errors.sunAllDay)}
                                        className={classes.checkboxAvailability}>

                                        <Controller
                                            render={(props) => (
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox
                                                            className={props.value ? classes.checkbox : ""} onChange={e => {
                                                            props.onChange(e.target.checked)
                                                            if(e.target.checked) {
                                                                setValue("sunFrom", null)
                                                                setValue("sunTo", null)
                                                            }
                                                        }} 
                                                        checked={props.value} disabled={readOnly} />
                                                    } 
                                                    label="All Day" />
                                            )}
                                            name="sunAllDay"
                                            control={control}
                                            defaultValue={false}
                                            />
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{marginTop: 32}}>
                        <FormControl
                            error={Boolean(errors.minimumWage)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Are you willing to work for minimum wage?</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <RadioGroup
                                        onChange={(e) => onChange(e)}
                                        value={value}>
                                        <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                    </RadioGroup>
                                )}
                                name="minimumWage"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                                
                            />

                            <FormHelperText>
                                {errors.minimumWage && errors.minimumWage.message}
                            </FormHelperText>
                        </FormControl>

                        {watch("minimumWage") === "No" &&
                            <FormControl
                                error={Boolean(errors.requestedWage)} 
                                className={classes.formInput}>

                                <FormLabel className={classes.label}>What is your preferred wage?</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                            inputProps={{
                                                className: classes.field
                                            }} 
                                            placeholder="Enter wage"
                                            fullWidth
                                            variant="filled"
                                            error={errors.requestedWage ? true : false}
                                            helperText={errors.requestedWage?.message}
                                            disabled={readOnly}/>
                                    }
                                    name="requestedWage"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "This is required"
                                    }}
                                />
                            </FormControl>
                        }

                    </div>

                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <div>
                                    <h3 style={{marginBottom: 8}}>
                                        <span>
                                            {info?.isReference1Validated &&
                                            <Chip
                                            label="Verified"
                                            style={{backgroundColor: "#9ccc65", color: "white", fontWeight: "bold"}}
                                            size="small"
                                            /> }
                                        </span>
                                        Reference 1
                                        </h3>
                                    
                                        
                                  
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Company</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Company"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref1Company"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }} 
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Position</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    placeholder="Enter Position"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"/>
                                            }
                                            name="ref1Position"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }} 
                                        />
                                    </FormControl>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Contact Person</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Contact Person"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref1Contact"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Phone</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Phone"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref1Phone"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Email</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Email"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref1Email"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <div>
                                        <FormControl
                                            error={Boolean(errors.isReference1Validated)}
                                            className={classes.checkboxAvailability}>

                                            <Controller
                                                render={(props) => (
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox
                                                                className={props.value ? classes.checkbox : ""} onChange={e => {
                                                                props.onChange(e.target.checked)
                                                                // if(e.target.checked) {
                                                                //     setValue("isReference1Validated", null)
                                                                //     setValue("friTo", null)
                                                                // }
                                                            }} 
                                                            checked={props.value} disabled={readOnly} />
                                                        } 
                                                        label="Reference 1 Check Verified" />
                                                )}
                                                name="isReference1Validated"
                                                control={control}
                                                defaultValue={false}
                                                />
                                        </FormControl>
                                    </div>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Reference Check Details</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Details"
                                                    multiline
                                                    fullWidth
                                                    rows={4}
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref1CheckDetails"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Reason for Leaving</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Reason for leaving"
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref1CheckReasonForLeaving"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Check Completed By</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Completed By"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref1CheckCompletedBy"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Start Date</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        id="date"
                                        // label="Birthday"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        variant="filled"
                                        // className={classes.textField}
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                        }}
                                    />
                                }
                                name="ref1StartDate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>End Date</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        id="date"
                                        // label="Birthday"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        variant="filled"
                                        // className={classes.textField}
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                        }}
                                    />
                                }
                                name="ref1EndDate"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <h3 style={{marginBottom: 8}}>
                                    <span>
                                            {info?.isReference2Validated &&
                                            <Chip
                                            label="Verified"
                                            style={{backgroundColor: "#9ccc65", color: "white", fontWeight: "bold"}}
                                            size="small"
                                            /> }
                                        </span>
                                        Reference 2
                                    </h3>
                                    
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Company</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Company"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref2Company"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }} 
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Position</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    placeholder="Enter Position"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"/>
                                            }
                                            name="ref2Position"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }} 
                                        />
                                    </FormControl>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Contact Person</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Contact Person"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref2Contact"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Phone</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Phone"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref2Phone"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Email</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Email"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref2Email"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <div>
                                        <FormControl
                                            error={Boolean(errors.isReference1Validated)}
                                            className={classes.checkboxAvailability}>

                                            <Controller
                                                render={(props) => (
                                                    <FormControlLabel 
                                                        control={
                                                            <Checkbox
                                                                className={props.value ? classes.checkbox : ""} onChange={e => {
                                                                props.onChange(e.target.checked)
                                                                // if(e.target.checked) {
                                                                //     setValue("isReference1Validated", null)
                                                                //     setValue("friTo", null)
                                                                // }
                                                            }} 
                                                            checked={props.value} disabled={readOnly} />
                                                        } 
                                                        label="Reference 1 Check Verified" />
                                                )}
                                                name="isReference2Validated"
                                                control={control}
                                                defaultValue={false}
                                                />
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl
                                        className={classes.formInput}>

                                        <FormLabel className={classes.label}>Availability Comments</FormLabel>

                                            <Controller
                                                as={
                                                    <TextField 
                                                        placeholder="Enter details"
                                                        fullWidth
                                                        variant="filled"
                                                        multiline
                                                        rows={4}/>
                                                }
                                                name="availabilityComments"
                                                control={control}
                                                defaultValue=""
                                            />
                                        </FormControl>
                                    </div>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Reference Check Details</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Details"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref2CheckDetails"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Reason for Leaving</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Reason for leaving"
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref2CheckReasonForLeaving"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Check Completed By</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter Completed By"
                                                    fullWidth
                                                    variant="filled"
                                                    size="small"
                                                    disabled={readOnly}/>
                                            }
                                            name="ref2CheckCompletedBy"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>

                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Start Date</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    id="date"
                                                    // label="Birthday"
                                                    type="date"
                                                    // defaultValue="2017-05-24"
                                                    variant="filled"
                                                    // className={classes.textField}
                                                    InputLabelProps={{
                                                    shrink: true,
                                                
                                                    }}
                                                />
                                            }
                                            name="ref2StartDate"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This is required"
                                            }} 
                                        />
                                    </FormControl>
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>End Date</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    id="date"
                                                    // label="Birthday"
                                                    type="date"
                                                    // defaultValue="2017-05-24"
                                                    variant="filled"
                                                    // className={classes.textField}
                                                    InputLabelProps={{
                                                    shrink: true,
                                                
                                                    }}
                                                />
                                            }
                                            name="ref2EndDate"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    
                    <div style={{marginTop: 16}}>
                        <FormControl
                            error={Boolean(errors.transport)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Do you have reliable transport?</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <RadioGroup
                                        onChange={(e) => onChange(e)}
                                        value={value}>
                                        <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                    </RadioGroup>
                                )}
                                name="transport"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}  
                            />
                            <FormHelperText>
                                {errors.transport && errors.transport.message}
                            </FormHelperText>
                        </FormControl>

                        {watch("transport") === "No" &&
                            <FormControl
                                error={Boolean(errors.transportComments)} 
                                className={classes.formInput}>

                                <FormLabel className={classes.label}>How will you get to work?</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                            inputProps={{
                                                className: classes.field
                                            }} 
                                            placeholder="Enter details"
                                            fullWidth
                                            variant="filled"
                                            error={errors.transportComments ? true : false}
                                            helperText={errors.transportComments?.message}
                                            disabled={readOnly}/>
                                    }
                                    name="transportComments"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "This is required"
                                    }}
                                />
                            </FormControl>
                        }

                        <FormControl
                            error={Boolean(errors.travelDistance)} 
                            className={classes.formInput}>

                            <FormLabel style={{marginBottom: 36}}>How far are you willing to travel to work?</FormLabel>

                            <Controller
                                render={(props) => (
                                    <Slider
                                        {...props}
                                        className={classes.slider}
                                        onChange={(_, value) => {
                                            props.onChange(value);
                                        }}
                                        valueLabelDisplay="on"
                                        max={100}
                                        min={0}
                                        step={10}
                                        disabled={readOnly}
                                    />
                                )}
                                name="travelDistance"
                                control={control}
                                defaultValue={0}
                                rules={{
                                    required: "This is required"
                                }}
                            />
                        </FormControl>

                        <FormControl
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Comments on travel distance</FormLabel>

                            <Controller
                                as={
                                    <TextField 
                                        inputProps={{
                                            className: classes.field
                                        }} 
                                        placeholder="Enter details"
                                        fullWidth
                                        variant="filled"
                                        multiline
                                        rows={4}
                                        disabled={readOnly}/>
                                }
                                name="travelDistanceComments"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.forkliftLicense)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Do you have a forklift license?</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <RadioGroup
                                        onChange={(e) => onChange(e)}
                                        value={value}>
                                        <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                    </RadioGroup>
                                )}
                                name="forkliftLicense"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText>
                                {errors.forkliftLicense && errors.forkliftLicense.message}
                            </FormHelperText>
                        </FormControl>

                        {watch("forkliftLicense") === "Yes" &&
                            <div>
                                <FormControl
                                    error={Boolean(errors.forkliftLicenseHolderDuration)} 
                                    className={classes.formInput}>

                                    <FormLabel className={classes.label}>How long have you had a forklift license?</FormLabel>

                                    <Controller
                                        as={
                                            <TextField
                                                inputProps={{
                                                    className: classes.field
                                                }} 
                                                placeholder="Enter details"
                                                fullWidth
                                                variant="filled"
                                                error={errors.forkliftLicenseHolderDuration ? true : false}
                                                helperText={errors.forkliftLicenseHolderDuration?.message}
                                                disabled={readOnly}/>
                                        }
                                        name="forkliftLicenseHolderDuration"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This is required"
                                        }}
                                    />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.shelfHeight)} 
                                    className={classes.formInput}>

                                    <FormLabel className={classes.label}>What shelf height have you worked at?</FormLabel>

                                    <Controller
                                        as={
                                            <TextField
                                                inputProps={{
                                                    className: classes.field
                                                }} 
                                                placeholder="Enter details"
                                                fullWidth
                                                variant="filled"
                                                error={errors.shelfHeight ? true : false}
                                                helperText={errors.shelfHeight?.message}
                                                disabled={readOnly}/>
                                        }
                                        name="shelfHeight"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This is required"
                                        }}
                                    />
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.doubleDeepStacking)} 
                                    className={classes.formInput}>

                                    <FormLabel className={classes.label}>Have you worked with double deep stacking?</FormLabel>

                                    <Controller
                                        render={({ onChange, value }) => (
                                            <RadioGroup
                                                onChange={(e) => onChange(e)}
                                                value={value}>
                                                <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                            </RadioGroup>
                                        )}
                                        name="doubleDeepStacking"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This is required"
                                        }}
                                    />

                                    <FormHelperText>
                                        {errors.doubleDeepStacking && errors.doubleDeepStacking.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.forkliftComments)} 
                                    className={classes.formInput}>

                                    <FormLabel className={classes.label}>Other forklift comments</FormLabel>

                                    <Controller
                                        as={
                                            <TextField
                                                inputProps={{
                                                    className: classes.field
                                                }} 
                                                placeholder="Enter details"
                                                fullWidth
                                                variant="filled"
                                                multiline
                                                rows={4}
                                                disabled={readOnly}/>
                                        }
                                        name="forkliftComments"
                                        control={control}
                                        defaultValue=""
                                    />
                                </FormControl>
                            </div>
                        }
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.pickPackingExperience)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Do you have pick/packing experience?</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <RadioGroup
                                        onChange={(e) => onChange(e)}
                                        value={value}>
                                        <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                    </RadioGroup>
                                )}
                                name="pickPackingExperience"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText>
                                {errors.pickPackingExperience && errors.pickPackingExperience.message}
                            </FormHelperText>
                        </FormControl>

                        {watch("pickPackingExperience") === "Yes" &&
                            <div>
                                <FormControl
                                    error={Boolean(errors.rfScanner)} 
                                    className={classes.formInput}>

                                    <FormLabel className={classes.label}>Have you used an RF Scanner?</FormLabel>

                                    <Controller
                                        render={({ onChange, value }) => (
                                            <RadioGroup
                                                onChange={(e) => onChange(e)}
                                                value={value}>
                                                <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                            </RadioGroup>
                                        )}
                                        name="rfScanner"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This is required"
                                        }}
                                    />

                                    <FormHelperText>
                                        {errors.rfScanner && errors.rfScanner.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    error={Boolean(errors.voicePacking)} 
                                    className={classes.formInput}>

                                    <FormLabel className={classes.label}>Have you done any voice picking?</FormLabel>

                                    <Controller
                                        render={({ onChange, value }) => (
                                            <RadioGroup
                                                onChange={(e) => onChange(e)}
                                                value={value}>
                                                <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                            </RadioGroup>
                                        )}
                                        name="voicePacking"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This is required"
                                        }}
                                    />
                                    <FormHelperText>
                                        {errors.voicePacking && errors.voicePacking.message}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl
                                    className={classes.formInput}>

                                    <FormLabel className={classes.label}>Other pick/packing experience comments</FormLabel>

                                    <Controller
                                        as={
                                            <TextField
                                                inputProps={{
                                                    className: classes.field
                                                }} 
                                                placeholder="Enter details"
                                                fullWidth
                                                variant="filled"
                                                multiline
                                                rows={4}
                                                disabled={readOnly}/>
                                        }
                                        name="packingComments"
                                        control={control}
                                        defaultValue=""
                                    />
                                </FormControl>
                            </div>
                        }
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.liftAbility)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Can you lift 25kg male and females 16kg?</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <RadioGroup
                                        onChange={(e) => onChange(e)}
                                        value={value}>
                                        <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                    </RadioGroup>
                                )}
                                name="liftAbility"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText>
                                {errors.liftAbility && errors.liftAbility.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.ppe)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Do you have PPE?</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <RadioGroup
                                        onChange={(e) => onChange(e)}
                                        value={value}>
                                        <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                    </RadioGroup>
                                )}
                                name="ppe"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText>
                                {errors.ppe && errors.ppe.message}
                            </FormHelperText>
                        </FormControl>

                        {watch("workRequest") &&
                            <FormControl
                                className={classes.formInput}>

                                <FormLabel className={classes.label}>Is there anything you do not want to do?</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                            inputProps={{
                                                className: classes.field
                                            }} 
                                            placeholder="Enter details"
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows={4}
                                            disabled={readOnly}/>
                                    }
                                    name="workRequest"
                                    control={control}
                                    defaultValue=""
                                />
                            </FormControl>
                        }
                        

                        <FormControl
                            error={Boolean(errors.medication)} 
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Do you take any prescribed medication that may inhibit you to work?</FormLabel>

                            <Controller
                                render={({ onChange, value }) => (
                                    <RadioGroup
                                        onChange={(e) => onChange(e)}
                                        value={value}>
                                        <FormControlLabel value="Yes" control={<Radio className={(value === "Yes") ? classes.radio : ""} disabled={readOnly} />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio className={(value === "No") ? classes.radio : ""} disabled={readOnly}/>} label="No" />
                                    </RadioGroup>
                                )}
                                name="medication"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }}
                            />
                            <FormHelperText>
                                {errors.medication && errors.medication.message}
                            </FormHelperText>
                        </FormControl>

                        {watch("medication") === "Yes" &&
                            <FormControl
                                error={Boolean(errors.medicationComments)}
                                className={classes.formInput}>

                                <FormLabel className={classes.label}>What prescription medication are you taking?</FormLabel>

                                <Controller
                                    as={
                                        <TextField
                                            inputProps={{
                                                className: classes.field
                                            }} 
                                            placeholder="Enter details"
                                            fullWidth
                                            variant="filled"
                                            multiline
                                            rows={4}
                                            disabled={readOnly}/>
                                    }
                                    name="medicationComments"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "This is required"
                                    }}
                                />
                            </FormControl>
                        }
                    </div>

                    <div>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Available Date</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        id="date"
                                        // label="Birthday"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        variant="filled"
                                        // className={classes.textField}
                                        InputLabelProps={{
                                        shrink: true,
                                        
                                        }}
                                    />
                                }
                                name="availableDate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>
                        <FormControl
                            className={classes.formInput}>

                            <FormLabel className={classes.label}>Additional Comments</FormLabel>

                            <Controller
                                as={
                                    <TextField 
                                        inputProps={{
                                            className: classes.field
                                        }} 
                                        placeholder="Enter details"
                                        fullWidth
                                        variant="filled"
                                        multiline
                                        rows={4}
                                        disabled={readOnly}/>
                                }
                                name="additionalComments"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                    </div>
                </div>

                <div style={{display: "flex"}}>
                    <div style={{flexGrow: 1}}/>
                        {!readOnly &&
                            <div>
                                {error &&
                                    <span style={{color: "red", marginRight: 8}}>{error}</span>
                                }
                                <Button variant="contained" color="secondary" className={classes.actionButton} style={{marginRight: 8}} onClick={() => {
                                    setReadOnly(true)
                                    clearErrors()
                                    refreshInterviewList()
                                }}>
                                    Cancel
                                    <Clear style={{marginLeft: 4}} fontSize="small" />
                                </Button>
                                {loading ?
                                    <Button variant="contained" color="primary" className={classes.actionButton}>
                                        <CircularProgress size={25} style={{color: "white"}} />
                                    </Button>
                                :
                                    <Button onClick={handleSubmit(onSave)} variant="contained" color="primary" className={classes.actionButton}>
                                        Save
                                        <Save style={{marginLeft: 4}} fontSize="small" />
                                    </Button>
                                }
                            </div>
                        }
                </div>
            </div>

            {/* <Dialog open={showReferenceVerifyDialog} onClose={() => setShowReferenceVerifyDialog(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Verify Reference Check</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to mark the Reference Check as Verified.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onReferenceCheckVerifiedClicked} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog> */}
        </div>

   
    )

}
