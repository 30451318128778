import React, { useEffect, useState } from "react";
import {
  TextField, Select, MenuItem, Radio, RadioGroup,
  FormControlLabel, FormControl, FormLabel, Grid,
  Accordion, AccordionSummary, AccordionDetails, FormHelperText,
  Button, CircularProgress
} from "@material-ui/core"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { ExpandMore, Clear, Save, Edit } from '@material-ui/icons';
import { useForm, Controller } from "react-hook-form";
import { API } from "aws-amplify";
import { IRDInfo } from '../../../../types/CandidateTypes';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      }
    },
    submit: {
      marginTop: 32,
      width: 100
    },
    select: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
    editButton: {
      width: 100,
      marginRight: 8
    },
    actionButton: {
      marginRight: 8,
      marginBottom: 8,
      width: 100
    },
    field: {
      '&.MuiInputBase-root.Mui-disabled': {
          color: "#000000"
      },
      '&.MuiInputBase-input.Mui-disabled': {
          color: "#000000"
      }
    },
    radio: {
      '&.MuiRadio-colorSecondary.Mui-disabled': {
        color: "#f50057"
      }
    }
  }),
);

interface Props {
  data?: IRDInfo
  refreshCandidateData: () => void
  candidate?: string
  user?: string
  dataPdf: boolean
  irdExpanded: boolean;
  setIrdExpanded: (open: boolean) => void
}

const IrdCard = ({ data, refreshCandidateData, candidate, user, dataPdf, irdExpanded, setIrdExpanded }: Props) => {
  const classes = useStyles()

  const { handleSubmit, control, errors, clearErrors, setValue } = useForm<IRDInfo>();

  const [readOnly, setReadOnly] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  
  useEffect(() => {
    if(data) {
      setValue("firstName", data.firstName)
      setValue("surname", data.surname)
      setValue("irdNumber", data.irdNumber)
      setValue("taxCode", data.taxCode)
      setValue("entitledToWork", data.entitledToWork)
      setValue("signature", data.signature)
      setValue("date", data.date)
    }
  }, [data, setValue])

  const onSave = (data: IRDInfo) => {
    if(user && candidate) {
      setLoading(true)
      const params = {
          body: {
              data: data,
              section: "ird",
              id: candidate,
              user: user
          }
      };
      API.post("", `/candidates/jobPlacement/forms/update`,params)
      .then(() => {
          setReadOnly(true);
          setLoading(false);
          refreshCandidateData()
      })
      .catch((error: any) => {
          console.log("ERROR updating candidate ird form info", error);
          setLoading(false)
          setError("Error: unable to update info")
      })  
    }
  }

  return (
    <div id="ird">
      <Accordion 
          style={{marginTop: 32}}
          expanded={irdExpanded || dataPdf}
          onChange={() => setIrdExpanded(!irdExpanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <h3>IRD</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div style={{display: "flex"}}>
                        <div style={{flexGrow: 1}}>
                        </div>
                        <div>
                            <Button variant="contained" color="primary" className={classes.editButton} onClick={() => setReadOnly(false)}>
                                Edit
                                <Edit style={{marginLeft: 4}} fontSize="small" />
                            </Button>
                        </div>
                    </div>

                    <div id="ird-div">
                      <h3 style={{marginBottom: 16}}>1. Your Details</h3>

                      <FormControl
                        error={Boolean(errors.firstName)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>First Name</FormLabel>

                        <Controller
                            as={
                                <TextField
                                    inputProps={{
                                      className: classes.field
                                    }}
                                    placeholder="Enter first name"
                                    fullWidth
                                    variant="filled"
                                    error={errors.firstName ? true : false}
                                    helperText={errors.firstName?.message}
                                    disabled={readOnly}/>
                            }
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "This is required"
                            }}
                        />
                      </FormControl>

                      <FormControl
                        error={Boolean(errors.surname)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>Surname</FormLabel>

                        <Controller
                            as={
                                <TextField
                                    inputProps={{
                                      className: classes.field
                                    }}
                                    placeholder="Enter surname"
                                    fullWidth
                                    variant="filled"
                                    error={errors.surname ? true : false}
                                    helperText={errors.surname?.message}
                                    disabled={readOnly}/>
                            }
                            name="surname"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "This is required"
                            }}
                        />
                      </FormControl>

                      <FormControl
                        error={Boolean(errors.irdNumber)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>IRD Number</FormLabel>

                        <Controller
                            as={
                                <TextField
                                    inputProps={{
                                      className: classes.field
                                    }}
                                    placeholder="Enter IRD number"
                                    fullWidth
                                    variant="filled"
                                    error={errors.irdNumber ? true : false}
                                    helperText={errors.irdNumber?.message || "8 digit number"}
                                    disabled={readOnly}/>
                            }
                            name="irdNumber"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "This is required"
                            }}
                        />
                      </FormControl>

                      <h3 style={{marginBottom: 16}}>2. Your Tax Code</h3>
                      
                      {!dataPdf &&
                        <>
                          <p style={{marginBottom: 16}}>
                            You must complete a separate Tax code declaration (IR330) for each
                            source of income Choose only ONE tax code Refer to the flowchart on
                            page 2 and then enter a tax code here..
                          </p>

                          <p>
                          If you're a casual agricultural worker, shearer, shearing shedhand, recognised seasonal worker, election day worker or have
                          a special tax code refer to Other tax code options at the bottom of page 2, choose your tax code and enter it in the tax code circle.

                          </p>
                        </>
                      }




                      <FormControl
                        error={Boolean(errors.taxCode)}
                        className={classes.input}>

                        <FormLabel className={classes.label}></FormLabel>

                        <Controller
                            as={
                                <Select 
                                    className={classes.field}
                                    variant="filled" 
                                    defaultValue=""
                                    displayEmpty
                                    disabled={readOnly}>
                                    <MenuItem value="">Select applicable tax code</MenuItem>
                                    <MenuItem value="M SL">M SL</MenuItem>
                                    <MenuItem value="M">M</MenuItem> 
                                    <MenuItem value="ME SL">ME SL</MenuItem>
                                    <MenuItem value="ME">ME</MenuItem>
                                    <MenuItem value="SB SL">SB SL</MenuItem>
                                    <MenuItem value="SB">SB</MenuItem>
                                    <MenuItem value="S SL">S SL</MenuItem>
                                    <MenuItem value="S">S</MenuItem>
                                    <MenuItem value="SH SL">SH SL</MenuItem>
                                    <MenuItem value="SH">SH</MenuItem>
                                    <MenuItem value="ST SL">ST SL</MenuItem>
                                    <MenuItem value="ST">ST</MenuItem>
                                    <MenuItem value="CAE">CAE</MenuItem>
                                    <MenuItem value="EDW">EDW</MenuItem>
                                    <MenuItem value="NSW">NSW</MenuItem>
                                    <MenuItem value="STC">STC</MenuItem>
                                </Select>
                            }
                            name="taxCode"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "This is required"
                          }}
                        />

                        <FormHelperText>
                            {errors.taxCode && errors.taxCode.message}
                        </FormHelperText>
                      </FormControl>
                     
                      <h3 style={{marginBottom: 16}}>3. Your entitlement to work</h3>

                      <FormControl
                        error={Boolean(errors.entitledToWork)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>
                          I am entitled under the Immigration Act 2009 to do the work that this tax code declaration relates to (tick the box that applies to you).</FormLabel>

                        <Controller
                            render={({ onChange, value}) => (
                              <RadioGroup
                                onChange={(e) => onChange(e)}
                                value={value}>
                                <FormControlLabel 
                                  value="I am a New Zealand or Australian citizen or am entitled to work indefinitely in New Zealand." 
                                  control={
                                    <Radio 
                                      className={(value === "I am a New Zealand or Australian citizen or am entitled to work indefinitely in New Zealand.") ? classes.radio : ""} 
                                      disabled={readOnly}/>
                                  } 
                                  label="I am a New Zealand or Australian citizen or am entitled to work indefinitely in New Zealand." />
                                <FormControlLabel 
                                  value="I hold a valid visa with conditions allowing work in New Zealand." 
                                  control={
                                    <Radio 
                                      className={(value === "I hold a valid visa with conditions allowing work in New Zealand.") ? classes.radio : ""} 
                                      disabled={readOnly}/>
                                  } 
                                  label="I hold a valid visa with conditions allowing work in New Zealand." />
                              </RadioGroup>
                            )}
                            name="entitledToWork"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}  
                        />
                        <FormHelperText>
                            {errors.entitledToWork && errors.entitledToWork.message}
                        </FormHelperText>
                      </FormControl>

                      <h3 style={{marginTop: 16, marginBottom: 16}}>4. Decleration</h3>

                      <FormControl
                        error={Boolean(errors.signature)} 
                        className={classes.input}>

                        <FormLabel className={classes.label}>Signature</FormLabel>

                        <Controller
                            as={
                                <TextField
                                    inputProps={{
                                      className: classes.field
                                    }}
                                    placeholder="Enter signature"
                                    fullWidth
                                    variant="filled"
                                    error={errors.signature ? true : false}
                                    helperText={errors.signature?.message}
                                    disabled={readOnly}/>
                            }
                            name="signature"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "This is required"
                            }}
                        />
                      </FormControl>

                      <FormControl
                          error={Boolean(errors.date)} 
                          className={classes.input}>

                          <FormLabel className={classes.label}>Date</FormLabel>

                          <Controller
                              render={({onChange, value}) => (
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                      <Grid container>
                                          <KeyboardDatePicker
                                              inputProps={{
                                                className: classes.field
                                              }}
                                              autoOk
                                              variant="inline"
                                              format="dd/MM/yyyy"
                                              margin="normal"
                                              id="date-picker1"
                                              value={value}
                                              onChange={date => onChange(date)} 
                                              disabled={readOnly}/>
                                      </Grid>
                                  </MuiPickersUtilsProvider>
                              )}
                              name="date"
                              control={control}
                              defaultValue={null}
                              rules={{
                                  required: "This is required"
                              }}
                          />
                          <FormHelperText>
                              {errors.date && errors.date.message}
                          </FormHelperText>
                      </FormControl>
                    </div>
                  
                    <div style={{display: "flex"}}>
                      <div style={{flexGrow: 1}}/>
                          {!readOnly &&
                              <div>
                                  {error &&
                                      <span style={{color: "red", marginRight: 8}}>{error}</span>
                                  }
                                  <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => {
                                      setReadOnly(true)
                                      refreshCandidateData()
                                      clearErrors()
                                  }}>
                                      Cancel
                                      <Clear style={{marginLeft: 4}} fontSize="small" />
                                  </Button>
                                  {loading ?
                                      <Button variant="contained" color="primary" className={classes.actionButton}>
                                          <CircularProgress size={25} style={{color: "white"}} />
                                      </Button>
                                  :
                                      <Button onClick={handleSubmit(onSave)} variant="contained" color="primary" className={classes.actionButton}>
                                          Save
                                          <Save style={{marginLeft: 4}} fontSize="small" />
                                      </Button>
                                  }
                              </div>
                          }
                        </div>
                  </div>
                </AccordionDetails>
            </Accordion>
    </div>
  );
};
export default IrdCard;
