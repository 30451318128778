import React, { useEffect, useState } from 'react'
import {
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import {
    Paper, Table, TableContainer, TableHead, TableRow,
    TableBody, Chip, Button, MenuItem, IconButton, Menu, TableCell, Collapse, Box, Typography
} from "@material-ui/core";
import {
    ArrowForward, MoreVert
} from "@material-ui/icons"
import { useHistory, Link } from 'react-router-dom';
import { API } from "aws-amplify"
import { StyledTableCell, StyledTableRow } from '../../components/StyledTable';
import { FormatDateTime, GetApplicationStatusColor, GetCandidateStatusColor } from '../../utils';
import { Application, Candidate, GroupedApplication } from '../../types/CandidateTypes';
import { InviteCandidateDialog } from './InviteCandidateDialog';
import { DeclineCandidateDialog } from './DeclineCandidateDialog';
import { NoCvDialog } from './NoCvDialog';
import CancelInterviewDialog from './CancelInterviewDialog';
import OnboardCandidateDialog from './OnboardCandidateDialog';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { stat } from 'fs';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 650,
        }
    })
);

interface ApplicationListProps {
    isCandidate?: boolean;
    isJob?: boolean
    applications: Array<Application>
    user?: any | null
    refreshData: () => void
    status: string
}

export function ApplicationList({ isCandidate, isJob, applications, user, refreshData, status }: ApplicationListProps) {

    const [groupedApplications, setGroupedApplications] = useState<Array<GroupedApplication>>([])
    // const [open, setOpen] = React.useState(false);

    useEffect(() => {
        // console.log(applications)
        let gApplications: Array<GroupedApplication> = []
        applications.forEach((application) => {
            let candidateIndex = gApplications.findIndex((grp) => grp.email === application.email)
            if (candidateIndex >= 0) {
                gApplications[candidateIndex].applications.push(application)

            } else {
                let gApplication = { email: application.email, firstName: application.firstName, lastName: application.lastName, phone: application.phone, applications: [application], status: application.candidate.status }
                gApplications.push(gApplication)
            }

        })
        setGroupedApplications(gApplications)
    }, [applications])

    return (
        <>
            {applications.length > 0 && status === "new" ?
                <TableContainer component={Paper} style={{ marginTop: 16 }}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Total</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupedApplications.length > 0 && groupedApplications.map((candidate) => (
                                <GroupedCandidateRow  refreshData={refreshData} status={status} candidate={candidate} user={user}></GroupedCandidateRow>

                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                : <ApplicationsTable
                    applications={applications}
                    user={user}
                    status={status}
                    refreshData={refreshData} />
            }
        </>
    )
}


interface GroupedCandidateRowProps {
    user?: any | null
    refreshData: () => void
    status: string,
    candidate: GroupedApplication
}

function GroupedCandidateRow({  user, refreshData, status, candidate }: GroupedCandidateRowProps) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment key={candidate.email}>
            <TableRow >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {candidate.firstName}
                </TableCell>
                <TableCell component="th" scope="row">
                    {candidate.lastName}
                </TableCell>
                <TableCell component="th" scope="row">
                    {candidate.email}
                </TableCell>
                <TableCell component="th" scope="row">
                    {candidate.phone}
                </TableCell>
                <TableCell component="th" scope="row">
                    {candidate.applications.length}
                </TableCell>
                <StyledTableCell>
                    <Chip
                        label={candidate.status.toUpperCase()}
                        style={{ backgroundColor: GetCandidateStatusColor(candidate.status), color: "white", fontWeight: "bold" }}
                        size="small" />
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <ApplicationsTable key={candidate.email}
                                status={status}
                                applications={candidate.applications}
                                user={user}
                                refreshData={refreshData} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

interface ApplicationsTableProps {
    isCandidate?: boolean;
    isJob?: boolean
    applications: Array<Application>
    user?: any | null
    refreshData: () => void
    status: string
}

export function ApplicationsTable({ isCandidate, isJob, applications, user, refreshData, status }: ApplicationsTableProps) {

    const classes = useStyles()

    const [selectedApplication, setSelectedApplication] = useState<Application>()
    const [newApplicantActionsMenu, setNewApplicantActionsMenu] = useState<null | HTMLElement>(null)
    const [inviteActionsMenu, setInviteActionsMenu] = useState<null | HTMLElement>(null)
    const [interviewedActionsMenu, setInterviewedActionsMenu] = useState<null | HTMLElement>(null)
    const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false)
    const [showDeclineDialog, setShowDeclineDialog] = useState<boolean>(false)
    const [showNoCvDialog, setOpenNoCvDialog] = useState<boolean>(false)
    const [editInterview, setEditInterview] = useState<boolean>(false)
    const [showCancelInterviewDialog, setShowCancelInterviewDialog] = useState<boolean>(false)
    const [showOnboardDialog, setShowOnboardDialog] = useState<boolean>(false)

    const history = useHistory()

    // const getCandidateData = () => {
    //     if(selectedApplication) {
    //         let id = selectedApplication.sk.split("#")[1]
    //         API.get("", `/candidates/${id}`, {})
    //         .then((response: Candidate) => {
    //             console.log(response.cvFile)
    //             if(response) {
    //                 if(response.cvFile) {
    //                     window.location.href = `https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${response.cvFile}`
    //                 } else {
    //                     setOpenNoCvDialog(true)
    //                 }
    //             } else {
    //                 setOpenNoCvDialog(true)
    //             }
    //         })
    //         .catch((error: any) => {
    //             console.log("Error: fetching candidate data", error)
    //         })
    //     }
    // }

    const getCandidateCv = () => {
        if (selectedApplication?.candidate.cvFile) {
            window.location.href = `https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${selectedApplication.candidate.cvFile}`
        } else {
            setOpenNoCvDialog(true)
        }
    }

    return (
        <>
            {applications.length !== 0 &&
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Job Reference</StyledTableCell>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableCell>Position</StyledTableCell>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>Phone</StyledTableCell>
                                <StyledTableCell>Location</StyledTableCell>
                                <StyledTableCell>Submitted At</StyledTableCell>
                                <StyledTableCell>Application Status</StyledTableCell>
                                <StyledTableCell>Candidate Status</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {applications.length !== 0 && applications.map((a: Application) => (
                                <StyledTableRow key={a.sk}>
                                    <StyledTableCell component="th" scope="row">
                                        {!isJob ?
                                            <Link to={`/jobs/${a.jobRef}`}>
                                                {a.jobRef}
                                            </Link>
                                            :
                                            a.jobRef
                                        }
                                    </StyledTableCell>
                                    <StyledTableCell>{a.firstName}</StyledTableCell>
                                    <StyledTableCell>{a.lastName}</StyledTableCell>
                                    <StyledTableCell>{a.position}</StyledTableCell>
                                    <StyledTableCell>{a.email}</StyledTableCell>
                                    <StyledTableCell>{a.phone}</StyledTableCell>
                                    <StyledTableCell>{a.location}</StyledTableCell>
                                    <StyledTableCell>{FormatDateTime(a.createdAt)}</StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            label={a.status}
                                            style={{ backgroundColor: GetApplicationStatusColor(a.status), color: "white", fontWeight: "bold" }}
                                            size="small" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            label={a.candidate.status.toUpperCase()}
                                            style={{ backgroundColor: GetCandidateStatusColor(a.candidate.status), color: "white", fontWeight: "bold" }}
                                            size="small" />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ marginTop: 8 }}>
                                                {!isCandidate &&
                                                    <Button variant="contained" color="primary" size="small"
                                                        onClick={() => history.push(`/candidates/${a.sk.split("#")[1]}`)}
                                                        style={{ borderRadius: 16 }}>
                                                        <ArrowForward />
                                                    </Button>
                                                }
                                            </div>

                                            <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                if(a.status === "NEW APPLICANT"){
                                                    setNewApplicantActionsMenu(event.currentTarget)

                                                }
                                                
                                                if(a.status === "INVITED"){
                                                    setInviteActionsMenu(event.currentTarget)
                                                }

                                                if(a.status === "INTERVIEWED"){
                                                    setInterviewedActionsMenu(event.currentTarget)
                                                }
                                                
                                                setSelectedApplication(a)
                                            }}>
                                                <MoreVert />
                                            </IconButton>


                                        </div>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }



                <Menu
                    open={Boolean(newApplicantActionsMenu)}
                    anchorEl={newApplicantActionsMenu}
                    onClose={() => setNewApplicantActionsMenu(null)}>

                    <MenuItem onClick={() => {
                        getCandidateCv()
                        setNewApplicantActionsMenu(null)
                    }}>
                        Download CV
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setShowInviteDialog(true)
                        setNewApplicantActionsMenu(null)
                    }}>
                        Invite
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setShowDeclineDialog(true)
                        setNewApplicantActionsMenu(null)
                    }}>
                        Decline
                    </MenuItem>
                </Menu>

            

            


                <Menu
                    open={Boolean(inviteActionsMenu)}
                    anchorEl={inviteActionsMenu}
                    onClose={() => setInviteActionsMenu(null)}>

                    <MenuItem onClick={() => {
                        setEditInterview(true)
                        setShowInviteDialog(true)
                        setInviteActionsMenu(null)
                    }}>
                        Edit
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setShowCancelInterviewDialog(true)
                        setInviteActionsMenu(null)
                    }}>
                        Cancel
                    </MenuItem>
                </Menu>

            

           

                <Menu
                    open={Boolean(interviewedActionsMenu)}
                    anchorEl={interviewedActionsMenu}
                    onClose={() => setInterviewedActionsMenu(null)}>

                    <MenuItem onClick={() => {
                        setShowOnboardDialog(true)
                        setInterviewedActionsMenu(null)
                    }}>
                        Onboard
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setShowDeclineDialog(true)
                        setInterviewedActionsMenu(null)
                    }}>
                        Decline
                    </MenuItem>
                </Menu>

          

            <InviteCandidateDialog
                openDialog={showInviteDialog}
                handleInviteDialog={setShowInviteDialog}
                application={selectedApplication}
                user={user}
                refreshData={refreshData}
                editInterview={editInterview} />

            <DeclineCandidateDialog
                openDialog={showDeclineDialog}
                handleDialog={setShowDeclineDialog}
                application={selectedApplication}
                user={user}
                refreshData={refreshData} />

            <NoCvDialog
                open={showNoCvDialog}
                handleDialog={setOpenNoCvDialog}
                application={selectedApplication}
            />

            <CancelInterviewDialog
                openDialog={showCancelInterviewDialog}
                handleDialog={setShowCancelInterviewDialog}
                application={selectedApplication}
                user={user}
                refreshData={refreshData}
            />

            <OnboardCandidateDialog
                open={showOnboardDialog}
                handleDialog={setShowOnboardDialog}
                application={selectedApplication}
                user={user}
                refreshData={refreshData}
            />
        </>
    )
}