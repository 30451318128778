import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
    Select, MenuItem, Grid, TextField, FormControl, FormLabel, FormHelperText,
    Paper, List, ListItem, ListItemText, ListItemIcon, Accordion, AccordionSummary, AccordionDetails,
    Button, CircularProgress, FormControlLabel, Checkbox
} from "@material-ui/core"
import { ExpandMore, InsertDriveFile, Edit, Clear, Save } from "@material-ui/icons"
import { useForm, Controller } from "react-hook-form";
import Dropzone from 'react-dropzone'
import { QualificationsInfo } from '../../../../types/CandidateTypes';
import { API, Storage } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    input: {
        marginBottom: theme.spacing(3),
        width: "100%"
    },
    label: {
        marginBottom: 6
    },
    header: {
      display: "flex",
      marginBottom: 16
    },
    title: {
      flexGrow: 1
    },
    uploadCard: {
        marginBottom: 40
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    dropzone: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 97,
        paddingBottom: 97,
        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#bdbdbd",
        borderStyle: "dashed",
        backgroundColor: "#eeeeee",
        color: "#757575",
        outline: "none",
        transition: "border .24s ease-in-out"
    },
    error: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 97,
        paddingBottom: 97,
        paddingLeft: 20,
        paddingRight: 20,
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "red",
        borderStyle: "dashed",
        backgroundColor: "#eeeeee",
        color: "#757575",
        outline: "none",
        transition: "border .24s ease-in-out"
    },
    disabled: {
        opacity: 0.4
    },
    editButton: {
        width: 100,
        marginRight: 8
    },
    actionButton: {
        marginRight: 8,
        marginBottom: 8,
        width: 100
    },
    field: {
        '&.MuiInputBase-root.Mui-disabled': {
            color: "#000000"
        },
        '&.MuiInputBase-input.Mui-disabled': {
            color: "#000000"
        }
    }
  }),
);

interface Props {
    data?: QualificationsInfo;
    user?: string
    refreshCandidateData: () => void;
    dataPdf: boolean
    qualificationsExpanded: boolean;
    setQualificationsExpanded: (open: boolean) => void;
}

export default function QualificationsCard({ data, user, refreshCandidateData, dataPdf, qualificationsExpanded, setQualificationsExpanded }: Props) {
    const classes = useStyles()

    const { handleSubmit, watch, control, errors, setValue, clearErrors } = useForm<QualificationsInfo>({
        shouldUnregister: false
    });

    const [readOnly, setReadOnly] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const [info, setInfo] = useState<QualificationsInfo>()

    const hasDriversLicense = watch("hasDriversLicense")
    const hasForkhoistLicense = watch("hasForkhoistLicense")
    const [hasAdditionalLicenses, setHasAdditionalLicenses] = useState<boolean>(false)
    const [hasQualificationDocs, setHasQualificationDocs] = useState<boolean>(false)

    const [uploadedDriversLicense, setDriversLicense] = useState<Array<File>>([])
    const [uploadedForkhoistLicense, setForkhoistLicense] = useState<Array<File>>([])
    const [uploadedAdditionalLicense, setAdditionalLicense] = useState<Array<File>>([])
    const [uploadeQualificationsDocs, setQualificationsDocs] = useState<Array<File>>([])

    useEffect(() => {
        if(data) {
            setInfo(data)
            setValue("hasDriversLicense", data.hasDriversLicense)
            setValue("driversLicenseImage", data.driversLicenseImage)
            setValue("hasForkhoistLicense", data.hasForkhoistLicense)
            setValue("forkhoistLicenseType", data.forkhoistLicenseType)
            setValue("forkhoistLicenseImage", data.forkhoistLicenseImage)
            setValue("additionalLicenseImage", data.additionalLicenseImage)
            setValue("qualifications", data.qualifications)
            setValue("qualificationsDocuments", data.qualificationsDocuments)
        }
    }, [data, setValue]);

    const onSave = (data: QualificationsInfo) => {
        if(user) {
            setLoading(true)
            const params = {
                body: {
                    data: data,
                    section: "qualifications"
                }
            };
            API.post("", `/candidates/${user}/profile/update`,params)
            .then(() => {
                setReadOnly(true);
                setLoading(false);
                refreshCandidateData()
            })
            .catch((error: any) => {
                console.log("ERROR updating candidate qualifications info", error);
                setLoading(false)
                setError("Error: unable to update info")
            })  
        }
    }

    function calcSize(fileSize: number): string {
        if (fileSize < 1000000) {
            return `${Math.round(fileSize/Math.pow(10,3))} KB`
        }
        return `${(fileSize/Math.pow(10,6)).toFixed(1)} MB`
    }

    const handleDriversLicenseDrop = (files: any) => {
        if(user) {
            var objKey = user + "_DRIVERSLICENSE_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("driversLicenseImage", objKey)
                        clearErrors("driversLicenseImage")
                        setDriversLicense(files)
                        
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    function getDriversLicenseDropzoneClass() {
        if(errors.driversLicenseImage) {
            return classes.error;
        } else {
            return classes.dropzone
        }
    }

    const handleForkhoistLicenseDrop = (files: any) => {
        if(user) {
            var objKey = user + "_FORKHOISTLICENSE_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("forkhoistLicenseImage", objKey)
                        clearErrors("forkhoistLicenseImage")
                        setForkhoistLicense(files)
                        
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    function getForkhoistLicenseDropzoneClass() {
        if(errors.forkhoistLicenseImage) {
            return classes.error;
        } else {
            return classes.dropzone
        }
    } 

    const handleAdditionalLicenseDrop = (files: any) => {
        if(user) {
            var objKey = user + "_ADDITIONALICENSE_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("additionalLicenseImage", objKey)
                        clearErrors("additionalLicenseImage")
                        setAdditionalLicense(files)
                        
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
    }

    const getAdditionalLicensesDropzoneClass = () => {
        if(errors.additionalLicenseImage) {
            return classes.error;
        } else {
            return classes.dropzone
        }
    } 

    const handleQualificationsDrop = (files: any) => {
        if(user) {
            var objKey = user + "_QUALIFICATIONDOCS_" + files[0].name;
        
            console.log("FILE UPLOAD", objKey)
        
            setLoading(true)
            Storage.put(objKey, files[0])
            .then(() => {
                Storage.get(objKey, { level: 'public', download:false })
                    .then(() => { 
                        setValue("qualificationsDocuments", objKey)
                        clearErrors("qualificationsDocuments")
                        setQualificationsDocs(files)
                    
                    })
                    .catch((error: any) => { 
                      console.log("ERROR fetching from s3", error);
                    });
            })
            .catch((error: any) => {
              console.log("ERROR uploading to s3", error);
            });
            setLoading(false)
        }
        
    }

    const getQualificationsDropzoneClass = () => {
        if(errors.qualificationsDocuments) {
            return classes.error;
        } else {
            return classes.dropzone
        }
    }

    const getDriversLicenseFileName = (filename: string) => {
        var f = filename.split("DRIVERSLICENSE_")
        return f[1];
    }

    const getForkhoistFileName = (filename: string) => {
        var f = filename.split("FORKHOISTLICENSE_")
        return f[1];
    }

    const getAdditionalLicenseFileName = (filename: string) => {
        var f = filename.split("ADDITIONALICENSE_")
        return f[1];
    }

    const getQualificationFileName = (filename: string) => {
        var f = filename.split("QUALIFICATIONDOCS_")
        return f[1];
    }

    return (
        <div id="qualifications">
            <Accordion 
                style={{marginTop: 32}}
                expanded={qualificationsExpanded || dataPdf}
                onChange={() => setQualificationsExpanded(!qualificationsExpanded)}> 
                <AccordionSummary
                    expandIcon={<ExpandMore />}>
                    <h3>Qualifications, licenses and certificates</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{width: "100%"}}>
                        <div style={{display: "flex"}}>
                            <div style={{flexGrow: 1}}>
                                <p style={{marginBottom: 24}}>Enter your qualification and certificate details.</p>
                            </div>
                            <div>
                                <Button variant="contained" color="primary" className={classes.editButton} onClick={() => setReadOnly(false)}>
                                    Edit
                                    <Edit style={{marginLeft: 4}} fontSize="small" />
                                </Button>
                            </div>
                        </div>

                        <div id="qualifications-div">
                            <h4 style={{marginBottom: 8}}>Driver's License</h4>

                            <FormControl
                                error={Boolean(errors.hasDriversLicense)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}> Do you have a driver's license?</FormLabel>

                                <Controller
                                        render={({onChange, value}) => (
                                            <Select 
                                                className={classes.field}
                                                variant="filled" 
                                                defaultValue=""
                                                disabled={readOnly}
                                                value={value}
                                                onChange={event => {
                                                    let val = event.target.value as string
                                                    onChange(val)
                                                    if(val === "No") {
                                                        clearErrors("driversLicenseImage")
                                                        setValue("driversLicenseImage", "")
                                                    }
                                                }}>
                                                <MenuItem value="">Select an option</MenuItem>
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        )}
                                        name="hasDriversLicense"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                        required: "This is required"
                                        }}
                                    />
                                <FormHelperText>
                                    {errors.hasDriversLicense && errors.hasDriversLicense.message}
                                </FormHelperText>
                            </FormControl>



                            {hasDriversLicense === "Yes" &&

                                <Grid item className={classes.uploadCard} sm={12} lg={6}>
                                    {info?.driversLicenseImage ?
                                        <div>
                                            {(!dataPdf && info?.driversLicenseImage) &&
                                                <Button variant="contained" color="primary" disabled={readOnly} style={{marginBottom: 4}} onClick={() => {
                                                    if(info) {
                                                        setInfo({
                                                            ...info,
                                                            driversLicenseImage: ""
                                                        })
                                                        setValue("driversLicenseImage", "")
                                                    }
                                                }}>
                                                    Upload new file
                                                </Button>
                                            }

                                            <div>
                                                {(info?.driversLicenseImage.endsWith(".png") || info?.driversLicenseImage.endsWith(".jpeg") || info?.driversLicenseImage.endsWith(".jpg")) ?
                                                    <img style={{height: 245}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.driversLicenseImage}`} />
                                                    :
                                                    <div style={{display: "flex"}}>
                                                        <InsertDriveFile />
                                                        <a style={{marginLeft: 8, marginTop: 4}} href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.driversLicenseImage}`}>{getDriversLicenseFileName(info?.driversLicenseImage)}</a>
                                                    </div>
                                                }
                                            </div>         
                                        </div>

                                    : 
                                        <FormControl
                                            error={Boolean(errors.driversLicenseImage)} 
                                            className={classes.input}>

                                            <FormLabel className={classes.label}>Upload Drivers License below.</FormLabel>
                                            <Controller
                                                control={control}
                                                name="driversLicenseImage"
                                                defaultValue=""
                                                rules={{
                                                    required: hasDriversLicense
                                                }}
                                                render={() => (
                                                <div >
                                                    
                                                    <Dropzone onDrop={handleDriversLicenseDrop} multiple={false}
                                                    accept={[
                                                        'image/jpeg',
                                                        'image/png',
                                                        'application/pdf',
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <Paper
                                                        variant="outlined"
                                                        {...getRootProps({className: getDriversLicenseDropzoneClass()})}
                                                        >
                                                        <input {...getInputProps()} name="driversLicenseImage" />
                                                        <p>Drag 'n' drop files here, or click to select files</p>
                                                        </Paper>
                                                    )}
                                                    </Dropzone>
                                                    {uploadedDriversLicense.length !== 0 && 
                                                        <List>
                                                        {uploadedDriversLicense.map((f: File, index: number) => (
                                                        <ListItem key={index}>
                                                            <ListItemIcon>
                                                            <InsertDriveFile />
                                                            </ListItemIcon>
                                                            <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                        </ListItem>
                                                        ))}
                                                    </List>
                                                    }
                                                </div>
                                                )}
                                            />
                                            <FormHelperText>
                                                {errors.driversLicenseImage && "This is required"}
                                            </FormHelperText>
                                        </FormControl>
                                    }
                                </Grid>
                            }

                            <h4 style={{marginBottom: 8}}>Forkhoist License</h4>   

                            <FormControl
                                error={Boolean(errors.hasForkhoistLicense)} 
                                className={classes.input}>

                                <FormLabel className={classes.label}> Do you have a forkhoist license?</FormLabel>

                                <Controller
                                        render={({onChange, value}) => (
                                            <Select 
                                                className={classes.field}
                                                variant="filled" 
                                                defaultValue=""
                                                disabled={readOnly}
                                                value={value}
                                                onChange={event => {
                                                    let val = event.target.value as string
                                                    onChange(val)
                                                    if(val === "No") {
                                                        clearErrors("forkhoistLicenseImage")
                                                        clearErrors("forkhoistLicenseType")
                                                        setValue("forkhoistLicenseImage", "")
                                                        setValue("forkhoistLicenseType", "")
                                                    }
                                                }}>
                                                <MenuItem value="">Select an option</MenuItem>
                                                <MenuItem value="Yes">Yes</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                            </Select>
                                        )}
                                        name="hasForkhoistLicense"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                        required: "This is required"
                                        }}
                                    />
                                <FormHelperText>
                                    {errors.hasForkhoistLicense && errors.hasForkhoistLicense.message}
                                </FormHelperText>
                            </FormControl>

                            {hasForkhoistLicense === "Yes" &&
                                <div>
                                    <FormControl
                                        error={Boolean(errors.forkhoistLicenseType)} 
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>What type do you have?</FormLabel>

                                        <Controller
                                            as={
                                                <Select 
                                                    className={classes.field}
                                                    variant="filled" 
                                                    defaultValue=""
                                                    disabled={readOnly}>
                                                    <MenuItem value="">Select an option</MenuItem>
                                                    <MenuItem value="Counter balance">Counter balance</MenuItem>
                                                    <MenuItem value="Reach">Reach</MenuItem>
                                                    <MenuItem value="Stock picker man up">Stock picker/ man up</MenuItem>
                                                    <MenuItem value="Pellet truck">Pellet truck</MenuItem>
                                                </Select>
                                            }
                                            name="forkhoistLicenseType"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                            required: "This is required"
                                            }}
                                        />
                                        <FormHelperText>
                                            {errors.forkhoistLicenseType && errors.forkhoistLicenseType.message}
                                        </FormHelperText>
                                    </FormControl>

                                    {(!dataPdf && info?.forkhoistLicenseImage) &&
                                        <div>
                                            <Button variant="contained" color="primary" disabled={readOnly} style={{marginBottom: 4}} onClick={() => {
                                                if(info) {
                                                    setInfo({
                                                        ...info,
                                                        forkhoistLicenseImage: ""
                                                    })
                                                    setValue("forkhoistLicenseImage", "")
                                                }
                                            }}>
                                                Upload new file
                                            </Button>
                                        </div>
                                    }


                                    <Grid item className={classes.uploadCard} sm={12} lg={6}>
                                        {info?.forkhoistLicenseImage ?
                                            <div>
                                                {(info?.forkhoistLicenseImage.endsWith(".png") || info?.forkhoistLicenseImage.endsWith(".jpeg") || info?.forkhoistLicenseImage.endsWith(".jpg")) ?
                                                    <img style={{height: 245}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.forkhoistLicenseImage}`} />
                                                    :
                                                    <div style={{display: "flex"}}>
                                                        <InsertDriveFile />
                                                        <a style={{marginLeft: 8, marginTop: 4}} href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.forkhoistLicenseImage}`}>{getForkhoistFileName(info?.forkhoistLicenseImage)}</a>
                                                    </div>
                                                }
                                            </div>     
                                        : 
                                            <FormControl
                                                error={Boolean(errors.forkhoistLicenseImage)} 
                                                className={classes.input}>

                                                <FormLabel className={classes.label}>Upload Forkhoist License below.</FormLabel>
                                                <Controller
                                                    control={control}
                                                    name="forkhoistLicenseImage"
                                                    defaultValue=""
                                                    rules={{
                                                        required: hasForkhoistLicense
                                                    }}
                                                    render={() => (
                                                    <div >
                                                        
                                                        <Dropzone onDrop={handleForkhoistLicenseDrop} multiple={false}
                                                        accept={[
                                                            'image/jpeg',
                                                            'image/png',
                                                            'application/pdf',
                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <Paper
                                                            variant="outlined"
                                                            {...getRootProps({className: getForkhoistLicenseDropzoneClass()})}
                                                            >
                                                            <input {...getInputProps()} name="forkhoistLicenseImage" />
                                                            <p>Drag 'n' drop files here, or click to select files</p>
                                                            </Paper>
                                                        )}
                                                        </Dropzone>
                                                        {uploadedForkhoistLicense.length !== 0 && 
                                                            <List>
                                                            {uploadedForkhoistLicense.map((f: File, index: number) => (
                                                            <ListItem key={index}>
                                                                <ListItemIcon>
                                                                <InsertDriveFile />
                                                                </ListItemIcon>
                                                                <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                            </ListItem>
                                                            ))}
                                                        </List>
                                                        }
                                                    </div>
                                                    )}
                                                />
                                                <FormHelperText>
                                                    {errors.forkhoistLicenseImage && "This is required"}
                                                </FormHelperText>
                                            </FormControl>
                                        }
                                    </Grid>
                                </div>
                            }

                            {(!dataPdf || (dataPdf && info?.additionalLicenseImage)) &&
                                <h4 style={{marginBottom: 8}}>Additional Licenses</h4> 
                            }
                            
                            {(!dataPdf && info?.additionalLicenseImage) &&
                                <div>
                                    <Button variant="contained" color="primary" disabled={readOnly} style={{marginBottom: 4}} onClick={() => {
                                        if(info) {
                                            setInfo({
                                                ...info,
                                                additionalLicenseImage: ""
                                            })
                                            setValue("additionalLicenseImage", "")
                                            setHasAdditionalLicenses(true)
                                        }
                                    }}>
                                        Upload new file
                                    </Button>
                                </div>
                            }

                            <Grid item className={classes.uploadCard} sm={12} lg={6}>

                                {info?.additionalLicenseImage ?
                                    <div>
                                        {(info?.additionalLicenseImage.endsWith(".png") || info?.additionalLicenseImage.endsWith(".jpeg") || info?.additionalLicenseImage.endsWith(".jpg")) ?
                                            <img style={{height: 245}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.additionalLicenseImage}`}  />
                                            :
                                            <div style={{display: "flex"}}>
                                                <InsertDriveFile />
                                                <a style={{marginLeft: 8, marginTop: 4}} href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.additionalLicenseImage}`}>{getAdditionalLicenseFileName(info?.additionalLicenseImage)}</a>
                                            </div>
                                        }
                                    </div> 
                                : 
                                    <div>

                                        {!dataPdf &&
                                            <FormControlLabel 
                                            style={{marginBottom: 8}} 
                                            control={<Checkbox id="checked" 
                                            checked={hasAdditionalLicenses} 
                                            onChange={() => {
                                                if(hasAdditionalLicenses) {
                                                    clearErrors("additionalLicenseImage")
                                                }
                                                setHasAdditionalLicenses(!hasAdditionalLicenses)
                                            }} 
                                            disabled={readOnly} />} 
                                            label="Upload Additional Licenses"/> 
                                        }

                                        {hasAdditionalLicenses && 
                                            <FormControl
                                                error={Boolean(errors.additionalLicenseImage)} 
                                                className={classes.input}>

                                                <FormLabel className={classes.label}>Upload Additional Licenses below.</FormLabel>
                                                <Controller
                                                    control={control}
                                                    name="additionalLicenseImage"
                                                    defaultValue=""
                                                    rules={{
                                                        required: hasAdditionalLicenses
                                                    }}
                                                    render={() => (
                                                    <div >
                                                        
                                                        <Dropzone onDrop={handleAdditionalLicenseDrop} multiple={false} disabled={readOnly}
                                                        accept={[
                                                            'image/jpeg',
                                                            'image/png',
                                                            'application/pdf',
                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <Paper
                                                            variant="outlined"
                                                            {...getRootProps({className: getAdditionalLicensesDropzoneClass()})}
                                                            >
                                                            <input {...getInputProps()} name="additionalLicenseImage" />
                                                            <p>Drag 'n' drop files here, or click to select files</p>
                                                            </Paper>
                                                        )}
                                                        </Dropzone>
                                                        {uploadedAdditionalLicense.length !== 0 && 
                                                            <List>
                                                            {uploadedAdditionalLicense.map((f: File, index: number) => (
                                                            <ListItem key={index}>
                                                                <ListItemIcon>
                                                                <InsertDriveFile />
                                                                </ListItemIcon>
                                                                <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                            </ListItem>
                                                            ))}
                                                        </List>
                                                        }
                                                    </div>
                                                    )}
                                                />
                                                <FormHelperText>
                                                    {errors.additionalLicenseImage && "This is required"}
                                                </FormHelperText>
                                            </FormControl>
                                        }
                                    </div>
                                }
                            </Grid>

                            {(!dataPdf || (dataPdf && info?.qualificationsDocuments)) &&
                                <>
                                    <h4 style={{marginBottom: 8}}>Qualifications</h4>   
                                    <FormControl
                                        className={classes.input}>

                                        <FormLabel className={classes.label}>Enter Qualifications</FormLabel>

                                        <Controller
                                            as={
                                                <TextField
                                                    inputProps={{
                                                        className: classes.field
                                                    }}
                                                    placeholder="Enter qualifications"
                                                    fullWidth
                                                    variant="filled"
                                                    multiline
                                                    rows={3}
                                                    disabled={readOnly}
                                                    />
                                            }
                                            name="qualifications"
                                            control={control}
                                            defaultValue=""
                                        />
                                    </FormControl>
                                </>
                            }
                            
                            {(!dataPdf && info?.qualificationsDocuments) &&
                                <div>
                                    <Button variant="contained" color="primary" disabled={readOnly} style={{marginBottom: 4}} onClick={() => {
                                        if(info) {
                                            setInfo({
                                                ...info,
                                                qualificationsDocuments: ""
                                            })
                                            setValue("qualificationsDocuments", "")
                                            setHasQualificationDocs(true)
                                        }
                                    }}>
                                        Upload new file
                                    </Button>
                                </div>
                            }

                            <Grid item className={classes.uploadCard} sm={12} lg={6}>
                                {info?.qualificationsDocuments ?
                                    
                                    <div>
                                        {(info?.qualificationsDocuments.endsWith(".png") || info?.qualificationsDocuments.endsWith(".jpeg") || info?.qualificationsDocuments.endsWith(".jpg")) ?
                                            <img style={{height: 245}} src={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.qualificationsDocuments}`}  />
                                            :
                                            <div style={{display: "flex"}}>
                                                <InsertDriveFile />
                                                <a style={{marginLeft: 8, marginTop: 4}} href={`https://lr-onboarding-candidate-files.s3-ap-southeast-2.amazonaws.com/public/${info?.qualificationsDocuments}`}>{getQualificationFileName(info?.qualificationsDocuments)}</a>
                                            </div>
                                        }
                                    </div> 
                                    
                                : 
                                    <div>
                                        {!dataPdf &&
                                            <FormControlLabel 
                                                style={{marginBottom: 8}} 
                                                control={<Checkbox id="checked" 
                                                checked={hasQualificationDocs}
                                                onChange={() => {
                                                    if(hasQualificationDocs) {
                                                        clearErrors("qualificationsDocuments")
                                                    }
                                                    setHasQualificationDocs(!hasQualificationDocs)
                                                }} 
                                                disabled={readOnly} />} 
                                                label="Upload Qualification Documents"/> 
                                        }
                                        
                                        {hasQualificationDocs && 
                                            <FormControl
                                                error={Boolean(errors.qualificationsDocuments)} 
                                                className={classes.input}>

                                                <FormLabel className={classes.label}>Upload Qualification Documents below.</FormLabel>
                                                <Controller
                                                    control={control}
                                                    name="qualificationsDocuments"
                                                    defaultValue=""
                                                    rules={{
                                                        required: hasQualificationDocs
                                                    }}
                                                    render={() => (
                                                    <div >
                                                        
                                                        <Dropzone onDrop={handleQualificationsDrop} multiple={false} disabled={readOnly}
                                                        accept={[
                                                            'image/jpeg',
                                                            'image/png',
                                                            'application/pdf',
                                                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <Paper
                                                            variant="outlined"
                                                            {...getRootProps({className: getQualificationsDropzoneClass()})}
                                                            >
                                                            <input {...getInputProps()} name="qualificationsDocuments" />
                                                            <p>Drag 'n' drop files here, or click to select files</p>
                                                            </Paper>
                                                        )}
                                                        </Dropzone>
                                                        {uploadeQualificationsDocs.length !== 0 && 
                                                            <List>
                                                            {uploadeQualificationsDocs.map((f: File, index: number) => (
                                                            <ListItem key={index}>
                                                                <ListItemIcon>
                                                                <InsertDriveFile />
                                                                </ListItemIcon>
                                                                <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                            </ListItem>
                                                            ))}
                                                        </List>
                                                        }
                                                    </div>
                                                    )}
                                                />
                                                <FormHelperText>
                                                    {errors.qualificationsDocuments && "This is required"}
                                                </FormHelperText>
                                            </FormControl>
                                        }
                                    </div>
                                }
                            </Grid>
                        </div>
                
                        <div style={{display: "flex"}}>
                            <div style={{flexGrow: 1}}/>
                                {!readOnly &&
                                    <div>
                                        {error &&
                                            <span style={{color: "red", marginRight: 8}}>{error}</span>
                                        }
                                        <Button variant="contained" color="secondary" className={classes.actionButton} onClick={() => {
                                            setReadOnly(true)
                                            refreshCandidateData()
                                            clearErrors()
                                        }}>
                                            Cancel
                                            <Clear style={{marginLeft: 4}} fontSize="small" />
                                        </Button>
                                        {loading ?
                                            <Button variant="contained" color="primary" className={classes.actionButton}>
                                                <CircularProgress size={25} style={{color: "white"}} />
                                            </Button>
                                        :
                                            <Button onClick={handleSubmit(onSave)} variant="contained" color="primary" className={classes.actionButton}>
                                                Save
                                                <Save style={{marginLeft: 4}} fontSize="small" />
                                            </Button>
                                        }
                                    </div>
                                }
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
