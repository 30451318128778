import React, { useState, useEffect } from 'react'
import { 
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import { 
    Grid, TextField, Button, Dialog, DialogContent, DialogTitle, 
    FormControl, FormLabel, FormHelperText, Stepper, Step, StepLabel,
    FormControlLabel, RadioGroup, Radio, Slider, FormGroup, Checkbox,
    CircularProgress, Chip, IconButton, DialogActions, Select, MenuItem
} from "@material-ui/core"; 
import { Cancel, Clear, Edit } from "@material-ui/icons";
import { useForm, Controller } from "react-hook-form";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { API } from "aws-amplify";
import { SkillGroup, SkillsDto } from '../../../types/SkillTypes';
import { Autocomplete } from "@material-ui/lab";
import { CandidateSkillsDto, SkillType } from '../../../types/CandidateTypes';
import { CandidateInterviewDetail } from '../../../types/InterviewTypes';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        input: {
            width: "100%",
            marginBottom: theme.spacing(2)
        },
        label: {
            marginBottom: 6
        },
        iconContainer: {
            transform: 'scale(1)',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        formInput: {
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        checkboxAvailability: {
            marginTop: 22,
            marginBottom: 8
        },
        toTimePicker: {
            marginLeft: 16,
            marginRight: 32
        },
        timePickerRow: {
            display: "flex", 
            marginTop: 16
        }
    })
);

interface Props {
    candidateId: string;
    user?: any | null
    openDialog: boolean;
    handleDialog: (open: boolean) => void;
    getInterviews: () => void;
    activeStep: number;
    setActiveStep: (step: number) => void;
    interview: CandidateInterviewDetail
}

export default function CandidateInterviewDialog({ candidateId, user, openDialog, handleDialog, getInterviews, activeStep, setActiveStep, interview }: Props) {

    const classes = useStyles()


    const getSteps = (): Array<string> =>  {
        let arr = new Array(9);
        arr.fill("");
        return arr;
    }

    const steps = getSteps()

    const incrementActiveStep = () => {
        setActiveStep(activeStep + 1)
    }

    const decrementActiveStep = () => {
        setActiveStep(activeStep - 1)
    }

    const getStepContent = (stepIndex: number): JSX.Element => {
        switch(stepIndex) {
            case 0:
                return <Step1
                    incrementActiveStep={incrementActiveStep}
                    handleDialog={handleDialog}
                    user={user} />
            
            case 1:
                return <Step2 
                    incrementActiveStep={incrementActiveStep}
                    decrementActiveStep={decrementActiveStep}/>
            case 2:
                return <Step3 
                    user={user}
                    candidateId={candidateId}
                    incrementActiveStep={incrementActiveStep}
                    decrementActiveStep={decrementActiveStep}/>
            case 3: 
                return <Step4
                    incrementActiveStep={incrementActiveStep}
                    decrementActiveStep={decrementActiveStep}/>
            case 4:
                return <Step5
                    incrementActiveStep={incrementActiveStep}
                    decrementActiveStep={decrementActiveStep} />
            case 5:
                return <Step6
                    incrementActiveStep={incrementActiveStep}
                    decrementActiveStep={decrementActiveStep} />
            case 6:
                return <Step7
                    incrementActiveStep={incrementActiveStep}
                    decrementActiveStep={decrementActiveStep} />
            case 7:
                return <Step8
                    incrementActiveStep={incrementActiveStep}
                    decrementActiveStep={decrementActiveStep} />
            case 8:
                return <Step9 
                    decrementActiveStep={decrementActiveStep}
                    handleDialog={handleDialog}
                    candidateId={candidateId}
                    user={user}
                    getInterviews={getInterviews}
                    interview={interview} />
            default:
                return (
                    <div></div>
                )
        }
    }

    return (
        <Dialog fullWidth maxWidth={'md'} open={openDialog} onClose={() => handleDialog(false)} disableBackdropClick>
            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1, padding: "16px 32px"}}>
                    <h2>
                        {interview.scheduledInterview.interviewTitle} Interview
                    </h2>
                </div>
                <div>
                    <IconButton onClick={() => {
                        handleDialog(false)
                        setActiveStep(0)
                    }}>
                        <Clear />
                    </IconButton>
                </div>

            </div>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label: string, i: number) => (
                        <Step key={i}>
                        <StepLabel classes={{
                        iconContainer: classes.iconContainer
                        }}> {label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <div style={{margin: 16}}>
                    {getStepContent(activeStep)}
                </div>
                
            </DialogContent>
        </Dialog>
    )
}


export interface Step1Form {
    consultantName: string;
    availMonday: boolean;
    availTuesday: boolean;
    availWednesday: boolean;
    availThursday: boolean;
    availFriday: boolean;
    availSaturday: boolean;
    availSunday: boolean;
    monFrom: string;
    monTo: string;
    tueFrom: string;
    tueTo: string;
    wedFrom: string;
    wedTo: string;
    thuFrom: string;
    thuTo: string;
    friFrom: string;
    friTo: string;
    satFrom: string;
    satTo: string;
    sunFrom: string;
    sunTo: string;
    monAllDay: boolean;
    tueAllDay: boolean;
    wedAllDay: boolean;
    thuAllDay: boolean;
    friAllDay: boolean;
    satAllDay: boolean;
    sunAllDay: boolean;
    availabilityComments:string;
}

interface Step1Props {
    incrementActiveStep: () => void;
    handleDialog: (open: boolean) => void;
    user?: any | null
}

function Step1({ incrementActiveStep, handleDialog, user }: Step1Props) {

    const classes = useStyles();

    const { handleSubmit, control, errors, watch, setValue, reset } = useForm<Step1Form>({
        defaultValues: {
            consultantName: user.username,
            availMonday: true,
            availTuesday: true,
            availWednesday: true,
            availThursday: true,
            availFriday: true,
            availSaturday: true,
            availSunday: true,
            monAllDay: true,
            tueAllDay: true,
            wedAllDay: true,
            thuAllDay: true,
            friAllDay: true,
            satAllDay: true,
            sunAllDay: true

        }
    });

    useEffect(() => {
        let jsonForm = localStorage.getItem('step1Form')
        if(jsonForm) {
            let form: Step1Form = JSON.parse(jsonForm)
            reset(form)
        }
    }, [])

    const onSubmit = (data: Step1Form) => {
        localStorage.setItem('step1Form', JSON.stringify(data))
        incrementActiveStep()
    }

    return (
        <div>

            <FormControl
                error={Boolean(errors.consultantName)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Consultant name</FormLabel>

                <Controller
                    as={
                        <TextField 
                            placeholder="Enter consultant name"
                            fullWidth
                            variant="filled"
                            error={errors.consultantName ? true : false}
                            helperText={errors.consultantName?.message}/>
                    }
                    name="consultantName"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
            </FormControl>

            <h3>Availability</h3>
            <div style={{display: "flex"}}>
                
                <FormGroup>
                    <FormControl
                        error={Boolean(errors.availMonday)}
                        className={classes.checkboxAvailability}>

                        <Controller
                            render={(props) => (
                                <FormControlLabel 
                                    label="Monday"
                                    control={
                                        <Checkbox 
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(!e.target.checked) {
                                                    setValue("monAllDay", false)
                                                    setValue("monFrom", null)
                                                    setValue("monTo", null)
                                                }
                                            }} 
                                            checked={props.value} 
                                        />
                                    }  
                                />
                            )}
                            name="availMonday"
                            control={control}
                            />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.availTuesday)}
                        className={classes.checkboxAvailability}>

                        <Controller
                            render={(props) => (
                                <FormControlLabel 
                                    label="Tuesday"
                                    control={
                                        <Checkbox 
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(!e.target.checked) {
                                                    setValue("tueAllDay", false)
                                                    setValue("tueFrom", null)
                                                    setValue("tueTo", null)
                                                }
                                            }} 
                                            checked={props.value} 
                                        />
                                    }  
                                />
                            )}
                            name="availTuesday"
                            control={control}
                            />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.availWednesday)}
                        className={classes.checkboxAvailability}>

                        <Controller
                            render={(props) => (
                                <FormControlLabel 
                                    label="Wednesday"
                                    control={
                                        <Checkbox 
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(!e.target.checked) {
                                                    setValue("wedAllDay", false)
                                                    setValue("wedFrom", null)
                                                    setValue("wedTo", null)
                                                }
                                            }} 
                                            checked={props.value} 
                                        />
                                    }  
                                />
                            )}
                            name="availWednesday"
                            control={control}
                            />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.availThursday)}
                        className={classes.checkboxAvailability}>

                        <Controller
                            render={(props) => (
                                <FormControlLabel 
                                    label="Thursday"
                                    control={
                                        <Checkbox 
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(!e.target.checked) {
                                                    setValue("thuAllDay", false)
                                                    setValue("thuFrom", null)
                                                    setValue("thuTo", null)
                                                }
                                            }} 
                                            checked={props.value} 
                                        />
                                    }  
                                />
                            )}
                            name="availThursday"
                            control={control}
                            />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.availFriday)}
                        className={classes.checkboxAvailability}>

                        <Controller
                            render={(props) => (
                                <FormControlLabel 
                                    label="Friday"
                                    control={
                                        <Checkbox 
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(!e.target.checked) {
                                                    setValue("friAllDay", false)
                                                    setValue("friFrom", null)
                                                    setValue("friTo", null)
                                                }
                                            }} 
                                            checked={props.value} 
                                        />
                                    }  
                                />
                            )}
                            name="availFriday"
                            control={control}
                            />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.availSaturday)}
                        className={classes.checkboxAvailability}>

                        <Controller
                            render={(props) => (
                                <FormControlLabel 
                                    label="Saturday"
                                    control={
                                        <Checkbox 
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(!e.target.checked) {
                                                    setValue("satAllDay", false)
                                                    setValue("satFrom", null)
                                                    setValue("satTo", null)
                                                }
                                            }} 
                                            checked={props.value} 
                                        />
                                    }  
                                />
                            )}
                            name="availSaturday"
                            control={control}
                            />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.availSunday)}
                        className={classes.checkboxAvailability}>

                        <Controller
                            render={(props) => (
                                <FormControlLabel 
                                    label="Sunday"
                                    control={
                                        <Checkbox 
                                            onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(!e.target.checked) {
                                                    setValue("sunAllDay", false)
                                                    setValue("sunFrom", null)
                                                    setValue("sunTo", null)
                                                }
                                            }} 
                                            checked={props.value} 
                                        />
                                    }  
                                />
                            )}
                            name="availSunday"
                            control={control}
                            />
                    </FormControl>
                </FormGroup>
                <div>
                    <div style={{display: "flex"}}>
                        <FormControl
                            error={Boolean(errors.monFrom)}>

                            <FormLabel className={classes.label}>From time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availMonday") === false || watch("availMonday") === undefined || watch("monAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="monFrom"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.monFrom && errors.monFrom.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.monTo)}
                            className={classes.toTimePicker}>

                            <FormLabel className={classes.label}>To time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availMonday") === false || watch("availMonday") === undefined || watch("monAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="monTo"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.monTo && "This is required"}
                            </FormHelperText>
                        </FormControl>
                    </div>

                    <div className={classes.timePickerRow}>
                        <FormControl
                            error={Boolean(errors.tueFrom)}>

                            <FormLabel className={classes.label}>From time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availTuesday") === false || watch("availTuesday") === undefined || watch("tueAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="tueFrom"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.tueFrom && errors.tueFrom.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.tueTo)}
                            className={classes.toTimePicker}>

                            <FormLabel className={classes.label}>To time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availTuesday") === false || watch("availTuesday") === undefined || watch("tueAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="tueTo"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.tueTo && errors.tueTo.message}
                            </FormHelperText>
                        </FormControl>
                    </div>

                    <div className={classes.timePickerRow}>
                        <FormControl
                            error={Boolean(errors.wedFrom)}>

                            <FormLabel className={classes.label}>From time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availWednesday") === false || watch("availWednesday") === undefined || watch("wedAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="wedFrom"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.wedFrom && errors.wedFrom.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.wedTo)}
                            className={classes.toTimePicker}>

                            <FormLabel className={classes.label}>To time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availWednesday") === false || watch("availWednesday") === undefined || watch("wedAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="wedTo"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.wedTo && errors.wedTo.message}
                            </FormHelperText>
                        </FormControl>
                    </div>

                    <div className={classes.timePickerRow}>
                        <FormControl
                            error={Boolean(errors.thuFrom)}>

                            <FormLabel className={classes.label}>From time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availThursday") === false || watch("availThursday") === undefined || watch("thuAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="thuFrom"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.thuFrom && errors.thuFrom.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.thuTo)}
                            className={classes.toTimePicker}>

                            <FormLabel className={classes.label}>To time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availThursday") === false || watch("availThursday") === undefined || watch("thuAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="thuTo"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.thuTo && errors.thuTo.message}
                            </FormHelperText>
                        </FormControl>
                    </div>

                    <div className={classes.timePickerRow}>
                        <FormControl
                            error={Boolean(errors.friFrom)}>

                            <FormLabel className={classes.label}>From time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availFriday") === false || watch("availFriday") === undefined || watch("friAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="friFrom"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.friFrom && errors.friFrom.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.friTo)}
                            className={classes.toTimePicker}>

                            <FormLabel className={classes.label}>To time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availFriday") === false || watch("availFriday") === undefined || watch("friAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="friTo"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.friTo && errors.friTo.message}
                            </FormHelperText>
                        </FormControl>
                    </div>

                    <div className={classes.timePickerRow}>
                        <FormControl
                            error={Boolean(errors.satFrom)}>

                            <FormLabel className={classes.label}>From time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availSaturday") === false || watch("availSaturday") === undefined || watch("satAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="satFrom"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.satFrom && errors.satFrom.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.satTo)}
                            className={classes.toTimePicker}>

                            <FormLabel className={classes.label}>To time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availSaturday") === false || watch("availTuesday") === undefined || watch("satAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="satTo"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.satTo && errors.satTo.message}
                            </FormHelperText>
                        </FormControl>
                    </div>

                    <div className={classes.timePickerRow}>
                        <FormControl
                            error={Boolean(errors.sunFrom)}>

                            <FormLabel className={classes.label}>From time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availSunday") === false || watch("availSunday") === undefined || watch("sunAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="sunFrom"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.sunFrom && errors.sunFrom.message}
                            </FormHelperText>
                        </FormControl>

                        <FormControl
                            error={Boolean(errors.sunTo)}
                            className={classes.toTimePicker}>

                            <FormLabel className={classes.label}>To time</FormLabel>

                            <Controller
                                render={({onChange, value}) => (
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid container>
                                            <KeyboardTimePicker
                                                ampm
                                                variant="inline"
                                                value={value}
                                                minutesStep={30}
                                                onChange={timeStamp => onChange(timeStamp)}
                                                disabled={watch("availSunday") === false || watch("availSunday") === undefined || watch("sunAllDay") === true}/>
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                )}
                                name="sunTo"
                                control={control}
                                defaultValue={null}
                            />
                            <FormHelperText>
                                {errors.sunTo && errors.sunTo.message}
                            </FormHelperText>
                        </FormControl>
                    </div>
                </div>
                <div style={{flexGrow: 1}}>
                    <div>
                        <FormControl
                            error={Boolean(errors.monAllDay)}
                            className={classes.checkboxAvailability}>

                            <Controller
                                render={(props) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(e.target.checked) {
                                                    setValue("monFrom", null)
                                                    setValue("monTo", null)
                                                }
                                            }} 
                                            checked={props.value} />
                                        } 
                                        label="All Day" />
                                )}
                                name="monAllDay"
                                control={control}
                                />
                        </FormControl>
                    </div>
                    
                    <div>
                        <FormControl
                            error={Boolean(errors.tueAllDay)}
                            className={classes.checkboxAvailability}>

                            <Controller
                                render={(props) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(e.target.checked) {
                                                    setValue("tueFrom", null)
                                                    setValue("tueTo", null)
                                                }
                                            }} 
                                            checked={props.value} />
                                        } 
                                        label="All Day" />
                                )}
                                name="tueAllDay"
                                control={control}
                                />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.wedAllDay)}
                            className={classes.checkboxAvailability}>

                            <Controller
                                render={(props) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(e.target.checked) {
                                                    setValue("wedFrom", null)
                                                    setValue("wedTo", null)
                                                }
                                            }} 
                                            checked={props.value} />
                                        } 
                                        label="All Day" />
                                )}
                                name="wedAllDay"
                                control={control}
                                />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.thuAllDay)}
                            className={classes.checkboxAvailability}>

                            <Controller
                                render={(props) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(e.target.checked) {
                                                    setValue("thuFrom", null)
                                                    setValue("thuTo", null)
                                                }
                                            }} 
                                            checked={props.value} />
                                        } 
                                        label="All Day" />
                                )}
                                name="thuAllDay"
                                control={control}
                                />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.friAllDay)}
                            className={classes.checkboxAvailability}>

                            <Controller
                                render={(props) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(e.target.checked) {
                                                    setValue("friFrom", null)
                                                    setValue("friTo", null)
                                                }
                                            }} 
                                            checked={props.value} />
                                        } 
                                        label="All Day" />
                                )}
                                name="friAllDay"
                                control={control}
                                />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.satAllDay)}
                            className={classes.checkboxAvailability}>

                            <Controller
                                render={(props) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(e.target.checked) {
                                                    setValue("satFrom", null)
                                                    setValue("satTo", null)
                                                }
                                            }} 
                                            checked={props.value} />
                                        } 
                                        label="All Day" />
                                )}
                                name="satAllDay"
                                control={control}
                                />
                        </FormControl>
                    </div>

                    <div>
                        <FormControl
                            error={Boolean(errors.sunAllDay)}
                            className={classes.checkboxAvailability}>

                            <Controller
                                render={(props) => (
                                    <FormControlLabel 
                                        control={
                                            <Checkbox onChange={e => {
                                                props.onChange(e.target.checked)
                                                if(e.target.checked) {
                                                    setValue("sunFrom", null)
                                                    setValue("sunTo", null)
                                                }
                                            }} 
                                            checked={props.value} />
                                        } 
                                        label="All Day" />
                                )}
                                name="sunAllDay"
                                control={control}
                                />
                        </FormControl>
                    </div>
                    <div>
                        <FormControl
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>Availability Comments</FormLabel>

                            <Controller
                                as={
                                    <TextField 
                                        placeholder="Enter details"
                                        fullWidth
                                        variant="filled"
                                        multiline
                                        rows={4}/>
                                }
                                name="availabilityComments"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </div>
                    
                </div>
            </div>
            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button
                        onClick={() => handleDialog(false)}
                        className={classes.backButton}>
                        Cancel
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface Step2Form {
    minimumWage: string;
    requestedWage: string;
}

interface StepProps {
    incrementActiveStep: () => void;
    decrementActiveStep: () => void;
}

function Step2({ incrementActiveStep, decrementActiveStep }: StepProps) {

    const classes = useStyles();

    const { handleSubmit, control, errors, watch, setValue } = useForm<Step2Form>();

    useEffect(() => {
        let jsonForm = localStorage.getItem('step2Form')
        if(jsonForm) {
            let form: Step2Form = JSON.parse(jsonForm)
            setValue("minimumWage", form.minimumWage)
            setValue("requestedWage", form.requestedWage)
        }
    }, [])

    const onSubmit = (data: Step2Form) => {
        localStorage.setItem('step2Form', JSON.stringify(data))
        incrementActiveStep()
    }

    return (
        <div>
            <FormControl
                error={Boolean(errors.minimumWage)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Are you willing to work for minimum wage?</FormLabel>

                <Controller
                    as={
                        <RadioGroup>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    }
                    name="minimumWage"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                    
                />

                <FormHelperText>
                    {errors.minimumWage && errors.minimumWage.message}
                </FormHelperText>
            </FormControl>

            {watch("minimumWage") === "No" &&
                <FormControl
                    error={Boolean(errors.requestedWage)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>What is your preferred wage?</FormLabel>

                    <Controller
                        as={
                            <TextField 
                                placeholder="Enter wage"
                                fullWidth
                                variant="filled"
                                error={errors.requestedWage ? true : false}
                                helperText={errors.requestedWage?.message}/>
                        }
                        name="requestedWage"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            }

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button
                        onClick={() => decrementActiveStep()}
                        className={classes.backButton}>
                        Back
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface Step3Props {
    user?: any | null;
    candidateId: string;
    incrementActiveStep: () => void;
    decrementActiveStep: () => void;
}

interface Step3Form {
    skills: Array<SkillType>;
}

function Step3({ incrementActiveStep, decrementActiveStep, user, candidateId }: Step3Props) {

    const classes = useStyles()

    const [loading, setLoading] = useState<boolean>(false)
    const [skills, setSkills] = useState<Array<SkillType>>([])
    const [skillGroups, setSkillGroups] = useState<Array<string>>([])
    const [skillData, setSkillData] = useState<Array<SkillGroup>>([])
    const [candidateSkills, setCandidateSkills] = useState<Array<string>>([])
    const [isDeleting, setDeleting] = useState<boolean>(false)
    const [openCreateNewSkillDialog, setOpenCreateNewSkillDialog] = useState<boolean>(false)

    const { handleSubmit, control, setValue, getValues } = useForm<Step3Form>({
        defaultValues: {
            skills: []
        }
    });

    useEffect(() => {
        let isMounted = true;
        
        API.get("", "/skills", {})
        .then((response: SkillsDto) => {
            if(isMounted) {
                if(response) {
                    setSkillData(response.skills)
                    handleSkills(response.skills)
                }
            }
        })
        .catch((error: any) => {
            console.log("Error fetching skills", error)
        })

        API.get("", `/candidates/${candidateId}/skills`, {})
        .then((response: CandidateSkillsDto) => {
            if(isMounted) {
                if(response) {
                    if(response.candidateSkills) {
                        setCandidateSkills(response.candidateSkills)
                    }
                }
            }
        })
        .catch((error: any) => {
            console.log("Error fetching candidate skills", error)
        })

        return () => {
            isMounted = false;
        }
        
    }, [])

    const getSkills = (newSkill: NewSkillForm) => {
        API.get("", "/skills", {})
        .then((response: SkillsDto) => {
            if(response) {
                setSkillData(response.skills)
                handleSkills(response.skills)
                
                setValue("skills", [...getValues("skills"), ...[{group: newSkill.group, skill: newSkill.skill}]])
            }
        })
        .catch((error: any) => {
            console.log("Error fetching skills", error)
        })
    }

    const handleSkills = (skills: Array<SkillGroup>) => {
        let allSkills: Array<SkillType> = []
        let groups: Array<string> = []
        skills.forEach((skillGroup: SkillGroup) => {
            skillGroup.Skills.forEach((skill: string) => {
                allSkills.push({skill: skill, group: skillGroup.Group})
            })
            groups.push(skillGroup.Group)
        })

        setSkills(allSkills)
        setSkillGroups(groups)

    }

    const getCandidateSkills = () => {
        let isMounted = true;
        API.get("", `/candidates/${candidateId}/skills`, {})
        .then((response: CandidateSkillsDto) => {
            if(isMounted) {
                if(response) {
                    if(response.candidateSkills) {
                        setCandidateSkills(response.candidateSkills)
                    }
                }
            }
        })
        .catch((error: any) => {
            console.log("Error fetching candidate skills", error)
        })

        return () => {
            isMounted = false;
        }
    }

    const onSubmit = (data: Step3Form) => {
        if(user) {
            if(data.skills.length !== 0) {
                let skills: Array<string> = [];
                data.skills.forEach((skill: SkillType) => {
                    skills.push(`${skill.group}#${skill.skill}`)
                })
                
                setLoading(true)
                const params = {
                    body:{
                        data: skills,
                        candidate: candidateId,
                        user: user.username
                    }
                };
    
                API.post("", "/candidates/skills/setSkills", params)
                .then(() => {
                    setLoading(false)
                    incrementActiveStep()
                })
                .catch((error: any) => {
                    console.log("ERROR: posting skills", error)
                    setLoading(false)
                })
    
            } else {
                incrementActiveStep()
            }
        }
    }

    const deleteSkill = (skill: string) => {
        let isMounted = true;
        if(user) {
            setDeleting(true)
            const params = {
                body:{
                    data: skill,
                    candidate: candidateId,
                    user: user.username
                }
            };
    
            API.post("", "/candidates/skills/removeSkill", params)
            .then(() => {
                if(isMounted) {
                    getCandidateSkills()
                    setDeleting(false)
                }
            })
            .catch((error: any) => {
                if(isMounted) {
                    setDeleting(false)
                }
                console.log("ERROR: deleting skill", error)
            })
        }

        return () => {
            isMounted = false;
        }
    }

    return (
        <div>
            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                    <h3>Add Candidate Skills</h3>
                </div>
                <div>
                    <Button onClick={() => setOpenCreateNewSkillDialog(true)} disabled={skills.length === 0}>
                        <Edit style={{marginRight: 4}} />
                        Create New Skill
                    </Button>
                </div>
            </div>

            <FormControl 
                style={{width: "100%", marginBottom: 16}}>

                <FormLabel className={classes.label}>Add Skill</FormLabel>
                <Controller 
                    render={(props) => (
                        <Autocomplete
                            {...props}
                            multiple
                            options={skills}
                            groupBy={(option) => option.group}
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    placeholder="Add Skill" 
                                    variant="outlined" />
                            )}
                            getOptionLabel={(option) => option.skill}
                            getOptionSelected={(option, value) => option.group === value.group && option.skill === value.skill}
                            onChange={(_, data) => props.onChange(data)}
                        />
                    )}
                    name="skills"
                    control={control}
                />
            </FormControl>

            {candidateSkills.length !== 0 &&
                <div style={{marginBottom: 16}}>
                    <h4>Candidate's current skills</h4>

                    <Grid container spacing={2}>
                        {candidateSkills.length !== 0 && candidateSkills.map((skill: string, i: number) => (
                            <Grid item xs={6} md={4} key={i}>
                                <Chip
                                    style={{ backgroundColor: "#fff9c4" }}
                                    label={skill.split("#")[1]}
                                    clickable={false}
                                    deleteIcon={<Cancel style={{color: "#f57f17"}}/>}
                                    onDelete={() => {
                                        setCandidateSkills(candidateSkills.filter((s: string) => s !== skill))
                                        deleteSkill(skill)
                                    }}
                                    disabled={isDeleting}/>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            }


            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}></div>
                <div>
                    <Button
                        onClick={() => decrementActiveStep()}
                        className={classes.backButton}>
                        Back
                    </Button>
                
                    {loading ? 
                        <Button variant="contained" color="primary" style={{width: 64}}>
                            <CircularProgress size={25} style={{color: "white"}}/>
                        </Button>
                    :
                        <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" style={{width: 64}}>
                            Next
                        </Button>
                    }
                </div>
            </div>

            <CreateNewSkillDialog
                open={openCreateNewSkillDialog}
                handleDialog={setOpenCreateNewSkillDialog}
                skillGroups={skillGroups}
                skillData={skillData}
                getSkills={getSkills}
            />
        </div>
    )
}

interface Step4Form {
    ref1Company: string;
    ref1Contact: string;
    ref1Phone: string;
    ref1Email: string
    ref2Company: string;
    ref2Contact: string;
    ref2Phone: string;
    ref2Email: string;
    ref1StartDate: string;
    ref1EndDate: string;
    ref2StartDate: string;
    ref2EndDate: string;
}

function Step4({ incrementActiveStep, decrementActiveStep }: StepProps) {

    const classes = useStyles();

    const { handleSubmit, control, errors, setValue } = useForm<Step4Form>();

    useEffect(() => {
        let jsonForm = localStorage.getItem('step4Form')
        if(jsonForm) {
            let form: Step4Form = JSON.parse(jsonForm)
            setValue("ref1Company", form.ref1Company)
            setValue("ref1Contact", form.ref1Contact)
            setValue("ref1Phone", form.ref1Phone)
            setValue("ref1Email", form.ref1Email)
            setValue("ref2Company", form.ref2Company)
            setValue("ref2Contact", form.ref2Contact)
            setValue("ref2Phone", form.ref2Phone)
            setValue("ref2Email", form.ref2Email)
            setValue("ref1StartDate", form.ref1StartDate)
            setValue("ref1EndDate", form.ref1EndDate)
            setValue("ref2StartDate", form.ref2StartDate)
            setValue("ref2EndDate", form.ref2EndDate)
        }
    }, [])

    const onSubmit = (data: Step4Form) => {
        localStorage.setItem('step4Form', JSON.stringify(data))
        incrementActiveStep()
    }

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <div>
                        <h3 style={{marginBottom: 8}}>Reference 1</h3>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Company</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Company"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref1Company"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Position</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Position"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref1Position"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>


                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Contact Person</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Contact Person"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref1Contact"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Phone</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Phone"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref1Phone"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Email</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Email"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref1Email"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Start Date</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        id="date"
                                        // label="Birthday"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        variant="filled"
                                        // className={classes.textField}
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                        }}
                                    />
                                }
                                name="ref1StartDate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>End Date</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        id="date"
                                        // label="Birthday"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        variant="filled"
                                        // className={classes.textField}
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                        }}
                                    />
                                }
                                name="ref1EndDate"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                        
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <div>
                        <h3 style={{marginBottom: 8}}>Reference 2</h3>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Company</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Company"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref2Company"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Position</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Position"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref2Position"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Contact Person</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Contact Person"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref2Contact"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Phone</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Phone"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref2Phone"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Email</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        placeholder="Enter Email"
                                        fullWidth
                                        variant="filled"
                                        size="small"/>
                                }
                                name="ref2Email"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>

                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>Start Date</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        id="date"
                                        // label="Birthday"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        variant="filled"
                                        // className={classes.textField}
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                        }}
                                    />
                                }
                                name="ref2StartDate"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This is required"
                                }} 
                            />
                        </FormControl>
                        <FormControl
                            className={classes.input}>

                            <FormLabel className={classes.label}>End Date</FormLabel>

                            <Controller
                                as={
                                    <TextField
                                        id="date"
                                        // label="Birthday"
                                        type="date"
                                        // defaultValue="2017-05-24"
                                        variant="filled"
                                        // className={classes.textField}
                                        InputLabelProps={{
                                        shrink: true,
                                       
                                        }}
                                    />
                                }
                                name="ref2EndDate"
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </div>
                </Grid>
            </Grid>

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button
                        onClick={() => decrementActiveStep()}
                        className={classes.backButton}>
                        Back
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface Step5Form {
    transport: string;
    transportComments: string;
    travelDistance: number;
    travelDistanceComments: string;
}

function Step5({ incrementActiveStep, decrementActiveStep }: StepProps) {

    const classes = useStyles();

    const { handleSubmit, control, errors, watch, setValue } = useForm<Step5Form>();

    useEffect(() => {
        let jsonForm = localStorage.getItem('step5Form')
        if(jsonForm) {
            let form: Step5Form = JSON.parse(jsonForm)
            setValue("transport", form.transport)
            setValue("transportComments", form.transportComments)
            setValue("travelDistance", form.travelDistance)
        }
    }, [])

    const onSubmit = (data: Step5Form) => {
        localStorage.setItem('step5Form', JSON.stringify(data))
        incrementActiveStep()
    }

    return (
        <div>
            <FormControl
                error={Boolean(errors.transport)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Do you have reliable transport?</FormLabel>

                <Controller
                    as={
                        <RadioGroup>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    }
                    name="transport"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}  
                />
                <FormHelperText>
                    {errors.transport && errors.transport.message}
                </FormHelperText>
            </FormControl>

            {watch("transport") === "No" &&
                <FormControl
                    error={Boolean(errors.transportComments)} 
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>How will you get to work?</FormLabel>

                    <Controller
                        as={
                            <TextField 
                                placeholder="Enter details"
                                fullWidth
                                variant="filled"
                                error={errors.transportComments ? true : false}
                                helperText={errors.transportComments?.message}/>
                        }
                        name="transportComments"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            }

            <FormControl
                error={Boolean(errors.travelDistance)} 
                className={classes.formInput}>

                <FormLabel style={{marginBottom: 36}}>How far are you willing to travel to work? (km's)</FormLabel>

                <Controller
                    render={(props) => (
                        <Slider
                          {...props}
                          onChange={(_, value) => {
                            props.onChange(value);
                          }}
                          valueLabelDisplay="on"
                          max={100}
                          min={0}
                          step={10}
                        />
                    )}
                    name="travelDistance"
                    control={control}
                    defaultValue={0}
                    rules={{
                        required: "This is required"
                    }}
                />
            </FormControl>

            <FormControl
                className={classes.formInput}>

                <FormLabel className={classes.label}>Comments on travel distance</FormLabel>

                <Controller
                    as={
                        <TextField 
                            placeholder="Enter details"
                            fullWidth
                            variant="filled"
                            multiline
                            rows={4}/>
                    }
                    name="travelDistanceComments"
                    control={control}
                    defaultValue=""
                />
            </FormControl>

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button
                        onClick={() => decrementActiveStep()}
                        className={classes.backButton}>
                        Back
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

interface Step6Form {
    forkliftLicense: string;
    forkliftLicenseHolderDuration: string;
    shelfHeight: string;
    doubleDeepStacking: boolean;
    forkliftComments: string;
}

function Step6({ incrementActiveStep, decrementActiveStep }: StepProps) {
    
    const classes = useStyles(); 

    const { handleSubmit, control, errors, watch, setValue } = useForm<Step6Form>();

    useEffect(() => {
        let jsonForm = localStorage.getItem('step6Form')
        if(jsonForm) {
            let form: Step6Form = JSON.parse(jsonForm)
            setValue("forkliftLicense", form.forkliftLicense)
            setValue("forkliftLicenseHolderDuration", form.forkliftLicenseHolderDuration)
            setValue("shelfHeight", form.shelfHeight)
            setValue("doubleDeepStacking", form.doubleDeepStacking)
            setValue("forkliftComments", form.forkliftComments)
        }
    }, [])

    const onSubmit = (data: Step6Form) => {
        localStorage.setItem('step6Form', JSON.stringify(data))
        incrementActiveStep()
    }

    return (
        <div>
            <FormControl
                error={Boolean(errors.forkliftLicense)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Do you have a forklift license?</FormLabel>

                <Controller
                    as={
                        <RadioGroup>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    }
                    name="forkliftLicense"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.forkliftLicense && errors.forkliftLicense.message}
                </FormHelperText>
            </FormControl>

            {watch("forkliftLicense") === "Yes" &&
                <div>
                    <FormControl
                        error={Boolean(errors.forkliftLicenseHolderDuration)} 
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>How long have you had a forklift license?</FormLabel>

                        <Controller
                            as={
                                <TextField 
                                    placeholder="Enter details"
                                    fullWidth
                                    variant="filled"
                                    error={errors.forkliftLicenseHolderDuration ? true : false}
                                    helperText={errors.forkliftLicenseHolderDuration?.message}/>
                            }
                            name="forkliftLicenseHolderDuration"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}
                        />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.shelfHeight)} 
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>What shelf height have you worked at?</FormLabel>

                        <Controller
                            as={
                                <TextField 
                                    placeholder="Enter details"
                                    fullWidth
                                    variant="filled"
                                    error={errors.shelfHeight ? true : false}
                                    helperText={errors.shelfHeight?.message}/>
                            }
                            name="shelfHeight"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}
                        />
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.doubleDeepStacking)} 
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>Have you worked with double deep stacking?</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            }
                            name="doubleDeepStacking"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}
                        />

                        <FormHelperText>
                            {errors.doubleDeepStacking && errors.doubleDeepStacking.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.forkliftComments)} 
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>Other comments</FormLabel>

                        <Controller
                            as={
                                <TextField 
                                    placeholder="Enter details"
                                    fullWidth
                                    variant="filled"
                                    multiline
                                    rows={4}/>
                            }
                            name="forkliftComments"
                            control={control}
                            defaultValue=""
                        />
                    </FormControl>
                </div>
            }

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button
                        onClick={() => decrementActiveStep()}
                        className={classes.backButton}>
                        Back
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface Step7Form {
    pickPackingExperience: string;
    rfScanner: string;
    voicePacking: string;
    packingComments: string;
}

function Step7({ incrementActiveStep, decrementActiveStep }: StepProps) {

    const classes = useStyles();

    const { handleSubmit, control, errors, watch, setValue } = useForm<Step7Form>();

    useEffect(() => {
        let jsonForm = localStorage.getItem('step7Form')
        if(jsonForm) {
            let form: Step7Form = JSON.parse(jsonForm)
            setValue("pickPackingExperience", form.pickPackingExperience)
            setValue("rfScanner", form.rfScanner)
            setValue("voicePacking", form.voicePacking)
            setValue("packingComments", form.packingComments)
        }
    }, [])

    const onSubmit = (data: Step7Form) => {
        localStorage.setItem('step7Form', JSON.stringify(data))
        incrementActiveStep()
    }

    return (
        <div>
            <FormControl
                error={Boolean(errors.pickPackingExperience)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Do you have pick/packing experience?</FormLabel>

                <Controller
                    as={
                        <RadioGroup>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    }
                    name="pickPackingExperience"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.pickPackingExperience && errors.pickPackingExperience.message}
                </FormHelperText>
            </FormControl>

            {watch("pickPackingExperience") === "Yes" &&
                <div>
                    <FormControl
                        error={Boolean(errors.rfScanner)} 
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>Have you used an RF Scanner?</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            }
                            name="rfScanner"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}
                        />

                        <FormHelperText>
                            {errors.rfScanner && errors.rfScanner.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        error={Boolean(errors.voicePacking)} 
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>Have you done any voice picking?</FormLabel>

                        <Controller
                            as={
                                <RadioGroup>
                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                </RadioGroup>
                            }
                            name="voicePacking"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This is required"
                            }}
                        />
                        <FormHelperText>
                            {errors.voicePacking && errors.voicePacking.message}
                        </FormHelperText>
                    </FormControl>

                    <FormControl
                        className={classes.formInput}>

                        <FormLabel className={classes.label}>Other comments</FormLabel>

                        <Controller
                            as={
                                <TextField 
                                    placeholder="Enter details"
                                    fullWidth
                                    variant="filled"
                                    multiline
                                    rows={4}/>
                            }
                            name="packingComments"
                            control={control}
                            defaultValue=""
                        />
                    </FormControl>
                </div>
            }

             <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button
                        onClick={() => decrementActiveStep()}
                        className={classes.backButton}>
                        Back
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface Step8Form {
    liftAbility: string;
    ppe: string;
    workRequest: string;
    medication: string;
    medicationComments: string;
}

function Step8({ incrementActiveStep, decrementActiveStep }: StepProps) {

    const classes = useStyles();

    const { handleSubmit, control, errors, watch, setValue } = useForm<Step8Form>();

    useEffect(() => {
        let jsonForm = localStorage.getItem('step8Form')
        if(jsonForm) {
            let form: Step8Form = JSON.parse(jsonForm)
            setValue("liftAbility", form.liftAbility)
            setValue("ppe", form.ppe)
            setValue("workRequest", form.workRequest)
            setValue("medication", form.medication)
            setValue("medicationComments", form.medicationComments)
        }
    }, [])

    const onSubmit = (data: Step8Form) => {
        localStorage.setItem('step8Form', JSON.stringify(data))
        incrementActiveStep()
    }

    return (
        <div>
            <FormControl
                error={Boolean(errors.liftAbility)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Can you lift 25kg male and females 16kg?</FormLabel>

                <Controller
                    as={
                        <RadioGroup>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    }
                    name="liftAbility"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.liftAbility && errors.liftAbility.message}
                </FormHelperText>
            </FormControl>

            <FormControl
                error={Boolean(errors.ppe)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Do you have PPE?</FormLabel>

                <Controller
                    as={
                        <RadioGroup>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    }
                    name="ppe"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.ppe && errors.ppe.message}
                </FormHelperText>
            </FormControl>

            <FormControl
                className={classes.formInput}>

                <FormLabel className={classes.label}>Is there anything you do not want to do?</FormLabel>

                <Controller
                    as={
                        <TextField 
                            placeholder="Enter details"
                            fullWidth
                            variant="filled"
                            multiline
                            rows={4}/>
                    }
                    name="workRequest"
                    control={control}
                    defaultValue=""
                />
            </FormControl>

            <FormControl
                error={Boolean(errors.medication)} 
                className={classes.formInput}>

                <FormLabel className={classes.label}>Do you take any prescribed medication that may inhibit you to work?</FormLabel>

                <Controller
                    as={
                        <RadioGroup>
                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                            <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                    }
                    name="medication"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }}
                />
                <FormHelperText>
                    {errors.medication && errors.medication.message}
                </FormHelperText>
            </FormControl>

            {watch("medication") === "Yes" &&
                <FormControl
                    error={Boolean(errors.medicationComments)}
                    className={classes.formInput}>

                    <FormLabel className={classes.label}>What prescription medication are you taking?</FormLabel>

                    <Controller
                        as={
                            <TextField 
                                placeholder="Enter details"
                                fullWidth
                                variant="filled"
                                multiline
                                rows={4}/>
                        }
                        name="medicationComments"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>
            }

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    <Button
                        onClick={() => decrementActiveStep()}
                        className={classes.backButton}>
                        Back
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                        Next
                    </Button>
                </div>
            </div>
        </div>
    )
}

interface Step9Props {
    decrementActiveStep: () => void;
    handleDialog: (open: boolean) => void;
    candidateId: string;
    user?: any | null
    getInterviews: () => void;
    interview: CandidateInterviewDetail
}

interface Step9Form {
    additionalComments: string
}

function Step9({ decrementActiveStep, handleDialog, candidateId, user, getInterviews, interview }: Step9Props) {

    const classes = useStyles();

    const { handleSubmit, control, errors, watch, setValue } = useForm<Step9Form>();
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    useEffect(() => {
        let jsonForm = localStorage.getItem('step9Form')
        if(jsonForm) {
            let form: Step9Form = JSON.parse(jsonForm)
            setValue("additionalComments", form.additionalComments)
        }
    }, [])

    const onSubmit = (data: Step9Form) => {
        if(user && candidateId) {
            let step1, step2, step3, step4, step5, step6, step7, step8
        
            let jsonForm1 = localStorage.getItem('step1Form')
            if(jsonForm1) {
                step1 = JSON.parse(jsonForm1)
            }
            let jsonForm2 = localStorage.getItem('step2Form')
            if(jsonForm2) {
                step2 = JSON.parse(jsonForm2)
            }
            let jsonForm3 = localStorage.getItem('step3Form')
            if(jsonForm3) {
                step3 = JSON.parse(jsonForm3)
            }
            let jsonForm4 = localStorage.getItem('step4Form')
            if(jsonForm4) {
                step4 = JSON.parse(jsonForm4)
            }
            let jsonForm5 = localStorage.getItem('step5Form')
            if(jsonForm5) {
                step5 = JSON.parse(jsonForm5)
            }
            let jsonForm6 = localStorage.getItem('step6Form')
            if(jsonForm6) {
                step6 = JSON.parse(jsonForm6)
            }
            let jsonForm7 = localStorage.getItem('step7Form')
            if(jsonForm7) {
                step7 = JSON.parse(jsonForm7)
            }
            let jsonForm8 = localStorage.getItem('step8Form')
            if(jsonForm8) {
                step8 = JSON.parse(jsonForm8)
            }
            
            let formData = {
                ...step1,
                ...step2,
                ...step3,
                ...step4,
                ...step5,
                ...step6,
                ...step7,
                ...step8,
                ...data
            }
    
            setLoading(true)
    
            var params = {
                body: {
                    data: formData,
                    user: user.username,
                    id: candidateId,
                    interview: interview
                }
            }
    
            API.post("", '/candidates/interview', params)
            .then(() => {
                setLoading(false)
                handleDialog(false)
                getInterviews()
            })
            .catch((error: any) => {
                console.log("Unable to post interview", error)
                setLoading(false)
                setError("Unable to add interview")
            })
    
        } else {
            setError("Unable to add interview")
        }
    }

    return (
        <div>

            <FormControl
                className={classes.input}>

                <FormLabel className={classes.label}>Available Date</FormLabel>

                <Controller
                    as={
                        <TextField
                            id="date"
                            // label="Birthday"
                            type="date"
                            // defaultValue="2017-05-24"
                            variant="filled"
                            // className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            
                            }}
                        />
                    }
                    name="availableDate"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: "This is required"
                    }} 
                />
            </FormControl>
            <FormControl
                className={classes.formInput}>

                <FormLabel className={classes.label}>Additional Comments</FormLabel>

                <Controller
                    as={
                        <TextField 
                            placeholder="Enter details"
                            fullWidth
                            variant="filled"
                            multiline
                            rows={4}/>
                    }
                    name="additionalComments"
                    control={control}
                    defaultValue=""
                />
            </FormControl>

            <div style={{display: "flex"}}>
                <div style={{flexGrow: 1}}>
                </div>
                <div>
                    {error &&
                        <span style={{color: "red"}}>{error}</span>
                    }
                    <Button onClick={() => decrementActiveStep()}  color="primary">
                        Back
                    </Button>
                    {loading ?
                        <Button variant="contained" color="primary">
                            <CircularProgress size={25} style={{color: "white"}} />
                        </Button>
                    :
                        <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary">
                            Finish
                        </Button>
                    }
                </div>
            </div>
        </div>
    )
}

interface CreateNewSkillDialogProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    skillGroups: Array<string>
    skillData: Array<SkillGroup>
    getSkills: (newSkill: NewSkillForm) => void;
}

interface NewSkillForm {
    group: string;
    skill: string;
}

function CreateNewSkillDialog({ open, handleDialog, skillGroups, skillData, getSkills }: CreateNewSkillDialogProps) {

    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setApiError] = useState<string>()

    const { handleSubmit, control, errors } = useForm<NewSkillForm>();

    const onSubmit = (data: NewSkillForm) => {
        setLoading(true)

        let index = -1;
        for(let i = 0; i < skillData.length; i++) {
            if(skillData[i].Group === data.group) {
                index = i
                for(let j = 0; j < skillData[i].Skills.length; j++) {
                    if(skillData[i].Skills[j] === data.skill) {
                        setLoading(false)
                        setApiError("Skill already exists")
                        return
                    }
                }
            }
        }

        if(index >= 0) {
            skillData[index].Skills.push(data.skill)
        } 

        let params = {
            body: {
                data: skillData
            }
        }

        API.post("", "/skills/setSkills", params)
        .then(() => {
            setLoading(false)
            handleDialog(false)
            getSkills(data)
        })
        .catch((error: any) => {
            console.log("Error: creating new skill", error)
            setLoading(false)
            setApiError("Error: unable to process request")
        })
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Create New Skill
            </DialogTitle>
            <DialogContent>

                <p>This creates a new skill in the master list and adds it to the candidate.</p>
  
                <FormControl
                    error={Boolean(errors.group)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>Skill Group</FormLabel>

                    <Controller
                        render={({ onChange, value}) => (
                            <Select variant="filled"
                                defaultValue=""
                                displayEmpty={true}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    setApiError(undefined)
                                }}>
                                <MenuItem value="">Select a Title</MenuItem>
                                {skillGroups.length > 0 && skillGroups.map((group: string, i: number) => (
                                    <MenuItem value={group} key={i}>{group}</MenuItem>
                                ))}
                            </Select>
                        )}
                        name="group"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                    <FormHelperText>
                        {errors.group && errors.group.message}
                    </FormHelperText>
                </FormControl>

                <FormControl
                    error={Boolean(errors.skill)} 
                    className={classes.input}>

                    <FormLabel className={classes.label}>New Skill</FormLabel>

                    <Controller
                        render={({ onChange, value}) => (
                            <TextField
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    setApiError(undefined)
                                }}
                                placeholder="Enter skill"
                                fullWidth
                                variant="filled"
                                error={errors.skill ? true : false}
                                helperText={errors.skill?.message}/>
                        )}
                        name="skill"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This is required"
                        }}
                    />
                </FormControl>

            </DialogContent>
            <DialogActions>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: 1}}></div>
                    <div>
                        {error &&
                            <span style={{color: "red", marginRight: 8}}>{error}</span>
                        }
                        <Button
                            style={{marginRight: 8}}
                            onClick={() => handleDialog(false)}>
                            Cancel
                        </Button>
                    
                        {loading ? 
                            <Button variant="contained" color="primary" style={{width: 80}}>
                                <CircularProgress size={25} style={{color: "white"}}/>
                            </Button>
                        :
                            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" style={{width: 80}}>
                                Submit
                            </Button>
                        }
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}