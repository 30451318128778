import React, { useState } from 'react'
import { 
    Dialog, DialogTitle, 
    DialogContent, DialogActions, Button, CircularProgress
} from '@material-ui/core'
import { InterviewTemplateInfo } from '../../../types/InterviewTypes'
import { API } from "aws-amplify";


interface Props {
    open: boolean
    handleDialog: (open: boolean) => void;
    template: InterviewTemplateInfo | undefined
    getInterviewTemplates: () => void
}

export default function DeleteTemplateDialog({ open, handleDialog, template, getInterviewTemplates }: Props) {

    const [loading, setLoading] = useState<boolean>(false)
    const [apiError, setApiError] = useState<string>()

    const onSubmit = () => {
        setLoading(true)
        let params = {
            body: {
                template: template
            }
        }
        API.post("", "/cms/interview-templates/delete", params)
        .then(() => {
            setLoading(false)
            handleDialog(false)
            getInterviewTemplates()
        })
        .catch((error: any) => {
            setLoading(false)
            setApiError("Error: unable to delete template")
            console.log("Error: deleting template", error)
        })
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Delete Template
            </DialogTitle>
            <DialogContent>
                <p>
                    Are you sure you want to delete the {template?.templateName} interview template?
                </p>
            </DialogContent>

            <DialogActions>
                {apiError &&
                    <span style={{marginRight: 8, color: "red"}}>{apiError}</span>
                }
                <Button variant="contained" color="secondary" onClick={() => handleDialog(false)}>
                    Cancel
                </Button>
                {loading ?
                    <Button variant="contained" color="primary" style={{width: 90}}>
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button variant="contained" color="primary" style={{width: 90}} onClick={() => onSubmit()}>
                        Submit
                    </Button>
                }

            </DialogActions>
        </Dialog>
    )
}
