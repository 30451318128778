import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { 
    Card, CardHeader, CardContent, Button, Chip, Hidden
} from "@material-ui/core"; 
import { Add, Delete, Cancel } from "@material-ui/icons"
import { API } from "aws-amplify";
import { SkillGroup, SkillsDto } from '../../../types/SkillTypes';
import AddGroupDialog from "../dialogs/AddGroupDialog";
import AddSkillDialog from '../dialogs/AddSkillDialog';
import DeleteSkillDialog from '../dialogs/DeleteSkillDialog';
import DeleteGroupDialog from '../dialogs/DeleteGroupDialog';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        skillItemChip: {
            marginRight: 8,
            marginTop: 8,
            backgroundColor: "#fff9c4"
        }
    })
);

interface Props {
    user: any | null
    loading: boolean
    setLoading: (loading: boolean) => void;
    selectedTab: number
}

export default function Skills({ user, loading, setLoading, selectedTab } : Props) {

    const classes = useStyles()

    const [openAddGroupDialog, setOpenAddGroupDialog] = useState<boolean>(false)
    const [openAddSkillDialog, setOpenAddSkillDialog] = useState<boolean>(false)
    const [openDeleteSkillDialog, setOpenDeleteSkillDialog] = useState<boolean>(false)
    const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState<boolean>(false)
    const [skills, setSkills] = useState<Array<SkillGroup>>([])
    const [selectedSkillGroup, setSelectedSkillGroup] = useState<string>()
    const [skillToDelete, setSkillToDelete] = useState<string>()

    useEffect(() => {
        if(selectedTab === 1) {
            let isMounted = true;
        
            //only call api when user is valid
            if(user) {
                setLoading(true)
                API.get("", "/skills", {})
                .then((response: SkillsDto) => {
                    if(isMounted) {
                        if(response) {
                            setSkills(response.skills)
                        }
                        setLoading(false)
                    }
                })
                .catch((error: any) => {
                    console.log("Error fetching skills", error)
                    setLoading(false)
                })
            }
            return () => {
                isMounted = false;
            }
        }
        
    }, [user, selectedTab])

    function handleOpenAddSkillDialog(group: string) {
        setSelectedSkillGroup(group)
        setOpenAddSkillDialog(true)
    }

    const handleAddGroupDialog = (open: boolean) => {
        setOpenAddGroupDialog(open)
    }

    const handleAddSkillDialog = (open: boolean) => {
        setOpenAddSkillDialog(open)
    }

    
    function handleDeleteSkillDialog(open: boolean) {
        setOpenDeleteSkillDialog(open)
    }

    function handleOpenDeleteSkillDialog(skill: string, group: string) {
        setSkillToDelete(skill)
        setSelectedSkillGroup(group)
        setOpenDeleteSkillDialog(true)
    }

    function handleDeleteGroupDialog(open: boolean) {
        setOpenDeleteGroupDialog(open);
    }

    function handleOpenDeleteGroupDialog(group: string) {
        setSelectedSkillGroup(group)
        setOpenDeleteGroupDialog(true)
    }


    return (
        <div>
            <Card>
                <CardHeader 
                    title="Skills" 
                    action={
                        <Button variant="contained" color="primary" onClick={() => handleAddGroupDialog(true)} disabled={loading}>
                            <Add />
                            Add Group
                        </Button>
                    }/>
                <CardContent>
                    {skills.length !== 0 && skills.map((skillGroup: SkillGroup) => (
                        <Card key={skillGroup.Group} style={{marginTop: 16}} raised>
                            <CardHeader 
                                title={skillGroup.Group} 
                                titleTypographyProps={{variant: "h6"}}
                                action={
                                    <div>
                                        <Hidden xsDown>
                                            <Button style={{marginRight: 8}} variant="contained" color="primary" onClick={() => handleOpenDeleteGroupDialog(skillGroup.Group)} disabled={loading}>
                                                <Delete />
                                                Remove Group
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => handleOpenAddSkillDialog(skillGroup.Group)} disabled={loading}>
                                                <Add />
                                                Add Skill
                                            </Button>
                                        </Hidden>
                                        <Hidden smUp>
                                            <div>
                                                <Button variant="contained" color="primary" onClick={() => handleOpenDeleteGroupDialog(skillGroup.Group)} disabled={loading}>
                                                    <Delete />
                                                    Remove Group
                                                </Button>
                                            </div>
                                            <div style={{textAlign: "end"}}>
                                                <Button style={{marginTop: 8}} variant="contained" color="primary" onClick={() => handleOpenAddSkillDialog(skillGroup.Group)} disabled={loading}>
                                                    <Add />
                                                    Add Skill
                                                </Button>
                                            </div>
                                        </Hidden>
                                    </div>
                                }/>

                            <CardContent>
                                {skillGroup.Skills.length !== 0 && skillGroup.Skills.map((skill: string) => (
                                    <Chip 
                                        key={skill} 
                                        className={classes.skillItemChip}
                                        label={skill}
                                        clickable={false}
                                        deleteIcon={<Cancel style={{color: "#f57f17"}}/>}
                                        onDelete={() => handleOpenDeleteSkillDialog(skill, skillGroup.Group)}
                                        disabled={loading}/>
                                ))}
                                {skillGroup.Skills.length === 0 && 
                                    <div style={{marginLeft: 8}}>
                                        No skills found.
                                    </div>
                                }
                            </CardContent>
                        </Card>
                    ))}
                </CardContent>
            </Card>

            <AddGroupDialog openDialog={openAddGroupDialog} handleGroupDialog={handleAddGroupDialog} skills={skills} />
            <AddSkillDialog openDialog={openAddSkillDialog} handleSkillDialog={handleAddSkillDialog} group={selectedSkillGroup} skills={skills} />
            <DeleteSkillDialog openDialog={openDeleteSkillDialog} handleDeleteDialog={handleDeleteSkillDialog}  group={selectedSkillGroup} skill={skillToDelete} skills={skills} />
            <DeleteGroupDialog openDialog={openDeleteGroupDialog} handleDeleteDialog={handleDeleteGroupDialog} group={selectedSkillGroup} skills={skills} setSkills={setSkills}/>
        </div>
    )
}
