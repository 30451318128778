import React, { useState } from 'react'
import { 
    Button, Dialog, DialogTitle, DialogContent, DialogActions, 
    CircularProgress
} from "@material-ui/core";
import { API } from "aws-amplify";
import { SkillGroup } from '../../../types/SkillTypes';

interface DeleteSkillDialogProps {
    openDialog: boolean
    handleDeleteDialog: (open: boolean) => void
    group?: string
    skill?: string
    skills: Array<SkillGroup>
}

export default function DeleteSkillDialog({ openDialog, handleDeleteDialog, skill, skills, group }: DeleteSkillDialogProps) {

    const [loading, setLoading] = useState<boolean>(false) 

    function handleDeleteSkill() {
        setLoading(true)

        if(skills.length !== 0) {
            for(let i = 0; i < skills.length; i++) {
                if(skills[i].Group === group) {
                    let newSkills = skills[i].Skills.filter(x => x !== skill)
                    skills[i].Skills = newSkills
                    break;
                }
            }
        }
        
        const params = {
            body:{
                data: skills,
            }
        };

        API.post("", "/skills/setSkills", params)
        .then((response: any) => {
            setLoading(false)
            handleDeleteDialog(false)
        })
        .catch((error: any) => {
            console.log("ERROR: posting skills", error)
            setLoading(false)
        })
    }

    return (
        <Dialog fullWidth maxWidth={'sm'} open={openDialog} onClose={() => handleDeleteDialog(false)}>
            <DialogTitle>
                Delete Skill
            </DialogTitle>
            <DialogContent>
                {skill ?
                    <span>Are you sure you want to delete {skill} skill</span>
                :
                    <span>Are you sure you want to delete this skill</span>
                }
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => handleDeleteDialog(false)}>
                    Cancel
                </Button>
                {loading ? 
                    <Button variant="contained" color="primary">
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button onClick={() => handleDeleteSkill()} variant="contained" color="primary" style={{backgroundColor: "#f57f17"}}>
                        Delete
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}