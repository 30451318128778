import React, { useState } from 'react'
import { 
    Button, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, CircularProgress
} from "@material-ui/core";
import { CandidateDetailDto } from '../../../types/CandidateTypes';
import { GenerateCandidateDataPDF } from '../pdf';

interface CandidateDataPdfProps {
    open: boolean;
    handleDialog: (open: boolean) => void;
    candidateData: CandidateDetailDto | undefined
    setAccordionExpanded: (v: boolean) => void;
    currentTab: number;
    setCurrentTab: (tab: number) => void;
}

export function CandidateDataPdfDialog({ open, handleDialog, candidateData, setAccordionExpanded, currentTab, setCurrentTab }: CandidateDataPdfProps) {

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const onSubmit = async () => {
        setLoading(true)
        let error = await GenerateCandidateDataPDF(candidateData, setAccordionExpanded, currentTab, setCurrentTab, handleDialog)
        setLoading(false)
        if(error) {
            setError("Error: unable to generate pdf")
        } else {
            handleDialog(false)
        }
    }

    return (
        <Dialog open={open} onClose={() => handleDialog(false)}>
            <DialogTitle>
                Generate Candidate Data PDF
            </DialogTitle>
            <DialogContent>
                
                <DialogContentText>Are you sure you want to generate this candidate's data pdf?</DialogContentText>
                                
            </DialogContent>
            <DialogActions>
                {error &&
                    <span style={{color: "red", marginRight: 4}}>{error}</span>
                }
                <Button color="primary" style={{width: 80}} onClick={() => handleDialog(false)}>
                    No
                </Button>
                {loading ? 
                    <Button variant="contained" color="primary" style={{width: 80}}>
                        <CircularProgress size={25} style={{color: "white"}}/>
                    </Button>
                :
                    <Button variant="contained" color="primary" style={{width: 80}} 
                        onClick={async () => onSubmit()}>
                        Yes
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}