import React, { useEffect, useState } from 'react'
import { 
    makeStyles, createStyles, Theme
} from '@material-ui/core/styles';
import { 
    Divider, Grid, Card, Tabs, Tab, CardHeader, CardContent, Paper, Typography, Button,
    ButtonBase, LinearProgress, Chip, IconButton, Menu, MenuItem, Accordion, 
    AccordionSummary, AccordionDetails
} from "@material-ui/core"; 
import { useParams } from 'react-router-dom';
import { 
    CheckCircle, Error, Add, Cancel, Notifications, MoreVert, PictureAsPdf, ExpandMore, FastForward
} from "@material-ui/icons"
import PersonalInfoCard from './tabs/detail/PersonalInfoCard';
import WorkHistoryCard from './tabs/detail/WorkHistoryCard';
import IndustrialTestCard from './tabs/detail/IndustrialTestCard';
import AdditionalInfoCard from './tabs/detail/AdditionalInfoCard';
import RightToWorkCard from './tabs/detail/RightToWorkCard';
import QualificationsCard from './tabs/detail/QualificationsCard';
import EmploymentContractCard from './tabs/detail/EmploymentContractCard';
import IrdCard from './tabs/detail/IrdCard';
import PayrollCard from './tabs/detail/PayrollCard';
import TabPanel from '../../components/TabPanel';
import {
    Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot,
    TimelineContent
} from "@material-ui/lab";
import { API } from "aws-amplify";
import { SkillGroup } from '../../types/SkillTypes';
import { 
    CandidateDetailDto, CandidateHistoryDto, CandidateSkillsDto, 
    Application, ActionsInfo, CmsActionsInfo
} from '../../types/CandidateTypes';
import { ApplicationsTable } from '../applications/ApplicationsTable';
import { FormatDateTime, GetHistoryItemColor, GetHistoryItemIcon } from '../../utils';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { DataPrivacyInfo, Candidate, CandidateActionsInfo, CandidateNoteInfo } from '../../types/CandidateTypes';
import { SkillsDialog } from './dialogs/SkillsDialog';
import { DeleteSkillDialog } from './dialogs/DeleteSkillDialog';
import { ActionsDialog } from './dialogs/ActionsDialog';
import { CandidateDataPdfDialog } from './dialogs/CandidateDataPdfDialog';
import CandidateInterviews from './tabs/interview/CandidateInterviews';
import CandidateNotesTab from './tabs/CandidateNotes';
import { differenceInYears } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => 
    createStyles({
        header: {
            display: "flex"
        },
        divider: {
            marginBottom: 16
        },
        categoryCard: {
            display: "flex",
            padding: 16,
            width: "100%",
            "&:hover": {
                backgroundColor: "#eeeeee"
            }
        },
        table: {
            minWidth: 650,
        },
        paper: {
            padding: '6px 16px',
        },
        secondaryTail: {
            backgroundColor: theme.palette.secondary.main,
        },
        input: {
            width: "100%",
            marginBottom: theme.spacing(2)
        },
        label: {
            marginBottom: 6
        },
        progress: {
            backgroundColor: "#ff9800"
        },
        progressBackground: {
            backgroundColor: "#ffe0b2"
        },
        skillItemChip: {
            marginRight: 8,
            marginTop: 8,
            backgroundColor: "#fff9c4"
        },
        actionDialogTabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
            minWidth: 175
        },
        actionDialogTabPanel: {
            padding: 32
        }
    })
);

interface ParamTypes {
    candidateId: string;
}

interface Props {
    user?: any | null;
}


export default function CandidateDetail({ user }: Props) {
    const classes = useStyles()
    const { candidateId } = useParams<ParamTypes>()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [candidateData, setCandidateData] = useState<CandidateDetailDto | undefined >(undefined)
    
    const [openSkillsDialog, setOpenSkillsDialog] = useState<boolean>(false);
    const [candidateSkills, setCandidateSkills] = useState<Array<SkillGroup>>([])
    const [openDeleteSkillDialog, setOpenDeleteSkillDialog] = useState<boolean>(false)
    const [skillToDelete, setSkillToDelete] = useState<string>()
    const [selectedGroup, setSelectedGroup] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false);
    const [candidateHistory, setCandidateHistory] = useState<Array<CandidateHistoryDto>>([])
    const [candidateNotes, setCandidateNotes] = useState<Array<CandidateNoteInfo>>([])
    
    const [applications, setApplications] = useState<Array<Application>>([])
    const [openActionsDialog, setOpenActionsDialog] = useState<boolean>(false)

    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false)
    const [additionalInfoExpanded, setAdditionalInfoExpanded] = useState<boolean>(false)
    const [rightToWorkExpanded, setRightToWorkExpanded] = useState<boolean>(false)
    const [qualificationsExpanded, setQualificationsExpanded] = useState<boolean>(false)
    const [workHistoryExpanded, setWorkHistoryExpanded] = useState<boolean>(false)
    const [industrialTestExpanded, setIndustrialTestExpanded] = useState<boolean>(false)
    const [employmentContractExpanded, setEmploymentContractExpanded] = useState<boolean>(false)
    const [irdExpanded, setIrdExpanded] = useState<boolean>(false)
    const [payrollExpanded, setPayrollExpanded] = useState<boolean>(false)

    const [optionsMenu, setOptionsMenu] = useState<null | HTMLElement>(null)
    const [openConfirmCandidatePdf, setOpenConfirmCandidatePdf] = useState<boolean>(false)

    const [openConfirmPrivacyDialog, setOpenConfirmPrivacyDialog] = useState<boolean>(false);
    const [privacyLoading, setPrivacyLoading] = useState<boolean>(false)
    const [privacyError, setPrivacyError] = useState<string>()
    const [privacyRequest, setPrivacyRequest] = useState<DataPrivacyInfo>()
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [deleteError, setDeleteError] = useState<string>()
    const [deleteRequest, setDeleteRequest] = useState<DataPrivacyInfo>()

    const [openConfirmFastTrackDialog, setOpenConfirmFastTrackDialog] = useState<boolean>(false)
    const [fastTrackLoading, setFastTrackLoading] = useState<boolean>(false)
    const [fastTrackError, setFastTrackError] = useState<string>()

    const [cmsActions, setCmsActions] = useState<Array<CmsActionsInfo>>([])
    const [candidateActions, setCandidateActions] = useState<Array<CandidateActionsInfo>>([])

    const [interviewTabActive, setInterviewTabActive] = useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
        setInterviewTabActive(newValue === 2)
    };

    useEffect(() => {
        if(selectedTab === 0) {
            setLoading(true)

            API.get("", `/candidates/${candidateId}/details`, {})
            .then((data: CandidateDetailDto) => {
                setCandidateData(data)
                if(data.applications) {
                    setApplications(data.applications)
                }
                if(data.dataPrivacy) {
                    data.dataPrivacy.forEach((request: DataPrivacyInfo) => {
                        if(request.sk.includes("DELETE")) {
                            setDeleteRequest(request)
                        }
                        if(request.sk.includes("PRIVACY")) {
                            setPrivacyRequest(request)
                        } 
                    })
                }
                if(data.cmsActions) {
                    formatCmsActions(data.cmsActions, data.detail, data.candidateActions)
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("ERROR: fetching candidate details", error);
            })
            
        } else if (selectedTab === 3) {
            setLoading(true)
            
            API.get("", `/candidates/${candidateId}/skills`, {})
            .then((data: CandidateSkillsDto) => {
                if(data) {
                    handleCandidateSkills(data.candidateSkills)
                } else {
                    setCandidateSkills([])
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("ERROR: fetching candidate skills", error)
                setLoading(false)
            })
        } else if (selectedTab === 4) {
            // setLoading(true)
            getCandidateNotes()
            
           
        } else if (selectedTab === 5) {
            setLoading(true)
            
            API.get("", `/candidates/${candidateId}/history`, {})
            .then((data: Array<CandidateHistoryDto>) => {
                if(data) {
                    setCandidateHistory(data.sort((a, b) => a.sk < b.sk ? 1 : -1))
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("ERROR: fetching candidate history", error)
                setLoading(false)
            })
        }
        
    }, [candidateId, selectedTab]);

    const getCandidateNotes = ()=>{
        // API.get("", `/candidates/${candidateId}/notes`, {})
        // .then((data: Array<CandidateNoteInfo>) => {
        //     if(data) {
        //         setCandidateNotes(data.sort((a, b) => a.postedAt < b.postedAt ? 1 : -1))
        //     }
        //     setLoading(false)
        // })
        // .catch((error: any) => {
        //     console.log("ERROR: fetching candidate Notes", error)
        //     setLoading(false)
        // })

    }

    const formatCmsActions = (data: Array<ActionsInfo>, candidate: Candidate, candidateData: Array<CandidateActionsInfo>) => {
        let groups = Array<CmsActionsInfo>()

        data.forEach((action: ActionsInfo) => {

            action.completed = false
            action.display = true
            action.selected = false

            let candidateAction = candidateData.find((c: CandidateActionsInfo) => c.group === action.group && c.label === action.label)
            if(candidateAction) {
                action.selected = true
                if(candidateAction.completed) {
                    action.completed = true
                }
            }
            
            if(action.label === "Upload CV") {
                if(candidate.cvFile) {
                    action.display = false
                }
            }

            if(action.label === "Complete Right to Work Form") {
                if(!candidate.rightToWorkSkipped) {
                    action.display = true
                }
            }

            if(action.label === "Complete Qualifications Form") {
                if(!candidate.qualificationsSkipped) {
                    action.display = true
                }
            }

            let groupIdx = groups.findIndex((a: CmsActionsInfo) => a.group === action.group)
            if(groupIdx >= 0) {
                groups[groupIdx].actions.push(action)
            } else {

                let newGroup: CmsActionsInfo = {
                    group: action.group,
                    actions: [action]
                }

                groups.push(newGroup)
            }
        })

        let first = "General";
        groups.sort((a, b) => a.group === first ? -1 : b.group === first ? 1 : 0)

        setCmsActions(groups)
        setCandidateActions(candidateData)
    }

    const getCandidateData = () => {
        if(user) {
            setLoading(true)
            API.get("", `/candidates/${candidateId}/details`, {})
            .then((response: CandidateDetailDto) => {
                if(response) {
                    setCandidateData(response)
                    if(response.applications) {
                        setApplications(response.applications)
                    }

                    if(response.dataPrivacy) {
                        response.dataPrivacy.forEach((request: DataPrivacyInfo) => {
                            if(request.sk.includes("DELETE")) {
                                setDeleteRequest(request)
                            }
                            if(request.sk.includes("PRIVACY")) {
                                setPrivacyRequest(request)
                            } 
                        })
                    }

                    if(response.cmsActions) {
                        formatCmsActions(response.cmsActions, response.detail, response.candidateActions)
                    }
                }
                setLoading(false)
            })
            .catch((error: any) => {
                console.log("Error: fetching candidate details", error)
                setLoading(false)
            })
        }
    }

    const getCandidateSkills = () => {
        setLoading(true)
            
        API.get("", `/candidates/${candidateId}/skills`, {})
        .then((data: CandidateSkillsDto) => {
            if(data.candidateSkills) {
                handleCandidateSkills(data.candidateSkills)
            } else {
                setCandidateSkills([])
            }
            setLoading(false)
        })
        .catch((error: any) => {
            console.log("ERROR: fetching candidate skills", error)
            setLoading(false)
        })
    }

    const handleCandidateSkills = (skills: Array<string>) => {
        let skillGroups: Array<SkillGroup> = [];

        let groups = getGroups(skills)
        groups.forEach((group: string) => {
            let groupSkills = getSkillsByGroup(skills, group)
            skillGroups.push({ Group: group, Skills: groupSkills })
        })

        setCandidateSkills(skillGroups)
    }

    function getGroups(skills: Array<string>): Set<string> {
        let groups: Set<string> = new Set();

        skills.forEach((skill: string) => {
            let pair = skill.split('#');
            groups.add(pair[0]);
        })

        return groups;
    }

    function getSkillsByGroup(skills: Array<string>, group: string): Array<string> {
        let groupSkills: Array<string> = [];

        skills.forEach((skill: string) => {
            let pair = skill.split('#')
            if(pair[0] === group) {
                groupSkills.push(pair[1])
            }
        })

        return groupSkills
    }

    const handleOpenDeleteCandidateSkill = (skill: string, group: string) => {
        setSkillToDelete(skill)
        setSelectedGroup(group)
        setOpenDeleteSkillDialog(true)
    }

    const handleDeleteCandidateSkill = (open: boolean) => {
        setOpenDeleteSkillDialog(open)
    }

    const handleSkillsDialog = (open: boolean) => {
        setOpenSkillsDialog(open)
    }

    const privacyOnSubmit = () => {
        if(candidateData && candidateData?.detail) {
            setPrivacyLoading(true)
            let params = {
                body: {
                    id: candidateId,
                    firstName: candidateData.detail.firstName,
                    surname: candidateData.detail.surname,
                    email: candidateData.detail.email,
                    type: "privacy"
                }
            }
            API.post("", "/candidates/data-privacy/request", params)
            .then(() => {
                setPrivacyLoading(false)
                setOpenConfirmPrivacyDialog(false)
                getCandidateData()
            })
            .catch((error: any) => {
                setPrivacyLoading(false)
                setPrivacyError("Error: request failed")
                console.log("Error: unable to request data privacy", error)
            })
        } else {
            setPrivacyError("Error: request failed")
        }
    }

    const deleteOnSubmit = () => {
        if(candidateData && candidateData?.detail) {
            setDeleteLoading(true)
            let params = {
                body: {
                    id: candidateId,
                    firstName: candidateData.detail.firstName,
                    surname: candidateData.detail.surname,
                    email: candidateData.detail.email,
                    type: "delete"
                }
            }
            API.post("", "/candidates/data-privacy/request", params)
            .then(() => {
                setDeleteLoading(false)
                setOpenConfirmDeleteDialog(false)
                getCandidateData()
            })
            .catch((error: any) => {
                setDeleteLoading(false)
                setDeleteError("Error: request failed")
                console.log("Error: unable to request data delete", error)
            })
        } else {
            setDeleteError("Error: request failed")
        }
    }

    const fastTrackOnSubmit = () => {
        if(candidateData && candidateData?.detail && user) {
            setFastTrackLoading(true)
            let params = {
                body: {
                    id: candidateId,
                    firstName: candidateData.detail.firstName,
                    surname: candidateData.detail.surname,
                    user: user.username
                }
            }
            API.post("", "/candidates/fasttrack/request", params)
            .then(() => {
                setFastTrackLoading(false)
                setOpenConfirmFastTrackDialog(false)
                getCandidateData()
            })
            .catch((error: any) => {
                setFastTrackLoading(false)
                setFastTrackError("Error: request failed")
                console.log("Error: unable to create post to fasttrack request", error)
            })
        }
    }

    return (
        <div>
            <div className={classes.header}>
                <h2 style={{flexGrow: 1}}>
                    Candidate Detail
                </h2>
                <div>
                    {selectedTab === 0 &&
                        <div style={{marginTop: 12}}>
                            <Button variant="contained" color="primary" onClick={() => setOpenActionsDialog(true)} disabled={loading}>
                                Actions
                                <Notifications fontSize="small" style={{marginLeft: 4}} />
                            </Button> 
                            <Button variant="contained" color="primary" style={{marginLeft: 8}} onClick={() => setOpenConfirmFastTrackDialog(true)} 
                            disabled={loading || candidateData?.detail.postedToFastTrack }>
                               {/* {differenceInYears( new Date(), new Date(candidateData?.profile.dob!)) < 16? "Candidate under 16": 'Post to FastTrack'}  */}
                                <FastForward fontSize="small" style={{marginLeft: 4}} />
                            </Button>
                            <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setOptionsMenu(event.currentTarget)}>
                                <MoreVert />
                            </IconButton>

                            <Menu style={{marginTop: 48}} open={Boolean(optionsMenu)} anchorEl={optionsMenu} onClose={() => setOptionsMenu(null)}>
                                <MenuItem onClick={() => {
                                    setOpenConfirmCandidatePdf(true)
                                    setOptionsMenu(null)
                                }}>
                                    Candidate Data PDF
                                    <PictureAsPdf style={{marginLeft: 8}} />
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                </div>
            </div>

            <CandidateDataPdfDialog 
                open={openConfirmCandidatePdf}
                handleDialog={setOpenConfirmCandidatePdf}
                candidateData={candidateData}
                setAccordionExpanded={setAccordionExpanded}
                currentTab={selectedTab}
                setCurrentTab={setSelectedTab}/>

            <ActionsDialog
                open={openActionsDialog}
                handleDialog={setOpenActionsDialog}
                user={user}
                candidateId={candidateId}
                cmsActions={cmsActions}
                candidateActions={candidateActions}
                getCandidateDetails={getCandidateData} />

            <ConfirmDialog
                open={openConfirmFastTrackDialog}
                handleDialog={setOpenConfirmFastTrackDialog}
                dialogTitle="Post to FastTrack Request"
                dialogDescription="Are you sure you want to submit a post to FastTrack request for this candidate?"
                loading={fastTrackLoading}
                setLoading={setFastTrackLoading}
                error={fastTrackError}
                setError={setFastTrackError}
                onSubmit={fastTrackOnSubmit}
            />

            <Tabs value={selectedTab} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="General" />
                <Tab label="Applications" />
                <Tab label="Interviews" />
                <Tab label="Skills" />
                <Tab label="Notes" />
                <Tab label="History" />
            </Tabs>

            <Divider />
            {loading && 
                <LinearProgress
                    className={classes.progressBackground} 
                    classes={{barColorPrimary: classes.progress}}/>
            }

            <TabPanel value={selectedTab} index={0}>
                {!loading && 
                    <div>
                        <Grid container spacing={2} style={{marginBottom: 32, marginTop: 16}}>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase className={classes.categoryCard} onClick={() => document?.getElementById("personal-info")?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2" >
                                                Personal Information
                                                </Typography>
                                        </div>
                                        {candidateData?.profile ? 
                                        <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard} 
                                        onClick={() => {
                                            setAdditionalInfoExpanded(true)
                                            document?.getElementById("additional-info")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                Additional Information
                                            </Typography>
                                        </div>
                                        {candidateData?.additionalInfo ? 
                                        <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard} 
                                        onClick={() => {
                                            setRightToWorkExpanded(true)
                                            document?.getElementById("right-to-work")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                Right To Work
                                            </Typography>
                                        </div>
                                        {candidateData?.rightToWork ? 
                                        <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard} 
                                        onClick={() => {
                                            setQualificationsExpanded(true)
                                            document?.getElementById("qualifications")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                Qualifications
                                            </Typography>
                                        </div>
                                        {candidateData?.qualifications ? 
                                        <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard} 
                                        onClick={() => {
                                            setWorkHistoryExpanded(true)
                                            document?.getElementById("work-history")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                Work History
                                            </Typography>
                                        </div>
                                        {candidateData?.workHistory ? 
                                        <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard}
                                        onClick={() => {
                                            setIndustrialTestExpanded(true)
                                            document?.getElementById("industrial-test")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                Industrial Test
                                            </Typography>
                                        </div>
                                        {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Industrial Test")?.completed ?
                                            <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Industrial Test") &&
                                                    <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                }
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard}
                                        onClick={() => {
                                            setEmploymentContractExpanded(true)
                                            document?.getElementById("employment-contract")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                Employment Contract
                                            </Typography>
                                        </div>
                                        {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Job Placement")?.completed ?
                                            <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Job Placement") &&
                                                    <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                }
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard}
                                        onClick={() => {
                                            setIrdExpanded(true)
                                            document?.getElementById("ird")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                IRD
                                            </Typography>
                                        </div>
                                        {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Job Placement")?.completed ?
                                            <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Job Placement") &&
                                                    <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                }
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <Card raised>
                                    <ButtonBase 
                                        className={classes.categoryCard}
                                        onClick={() => {
                                            setPayrollExpanded(true)
                                            document?.getElementById("payroll")?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                        }}>
                                        <div style={{flexGrow: 1}}>
                                            <Typography variant="subtitle2">
                                                Payroll
                                            </Typography>
                                        </div>
                                        {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Job Placement")?.completed ?
                                            <div>
                                                <CheckCircle fontSize="small" style={{color: "#9ccc65", marginLeft: 4}}/>
                                            </div>
                                        :
                                            <div>
                                                {candidateActions?.find((c: CandidateActionsInfo) => c.label === "Job Placement") &&
                                                    <Notifications fontSize="small" style={{color: "#9e9e9e"}} />
                                                }
                                                <Error fontSize="small" style={{color: "#ffa726", marginLeft: 4}}/>
                                            </div>
                                        }
                                    </ButtonBase>
                                </Card>
                            </Grid>
                        </Grid>

                        <PersonalInfoCard data={candidateData?.profile} detail={candidateData?.detail} user={user} refreshCandidateData={getCandidateData}/>
                        <AdditionalInfoCard data={candidateData?.additionalInfo} user={candidateId} refreshCandidateData={getCandidateData} dataPdf={accordionExpanded} additionalInfoExpanded={additionalInfoExpanded} setAdditionalInfoExpanded={setAdditionalInfoExpanded} />
                        <RightToWorkCard data={candidateData?.rightToWork} user={candidateId} refreshCandidateData={getCandidateData} dataPdf={accordionExpanded} rightToWorkExpanded={rightToWorkExpanded} setRightToWorkExpanded={setRightToWorkExpanded}/>
                        <QualificationsCard data={candidateData?.qualifications} user={candidateId} refreshCandidateData={getCandidateData} dataPdf={accordionExpanded} qualificationsExpanded={qualificationsExpanded} setQualificationsExpanded={setQualificationsExpanded}/>
                        <WorkHistoryCard data={candidateData?.workHistory} user={candidateId} refreshCandidateData={getCandidateData} dataPdf={accordionExpanded} workHistoryExpanded={workHistoryExpanded} setWorkHistoryExpanded={setWorkHistoryExpanded}/>
                        <IndustrialTestCard data={candidateData?.industrialTest} dataPdf={accordionExpanded} industrialTestExpanded={industrialTestExpanded} setIndustrialTestExpanded={setIndustrialTestExpanded}/>
                        <EmploymentContractCard employmentContractExpanded={employmentContractExpanded} setEmploymentContractExpanded={setEmploymentContractExpanded}/>
                        <IrdCard data={candidateData?.ird} candidate={candidateId} user={user?.username} refreshCandidateData={getCandidateData} dataPdf={accordionExpanded} irdExpanded={irdExpanded} setIrdExpanded={setIrdExpanded}/>
                        <PayrollCard data={candidateData?.payroll} candidate={candidateId} user={user?.username} refreshCandidateData={getCandidateData} dataPdf={accordionExpanded} payrollExpanded={payrollExpanded} setPayrollExpanded={setPayrollExpanded}/>

                        <Accordion 
                            style={{marginTop: 32 , backgroundColor: "#fffde7"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}>
                                <h3>Data & Privacy</h3>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{width: "100%"}}>
                                    <div style={{display: "flex"}}>
                                        <div style={{flexGrow: 1}}>
                                            <div>
                                                <strong>Data Privacy Request</strong>
                                            </div>
                                            <div>
                                                <span>By requesting this candidate's data, a document will be generated containing all information stored on the candidate.</span>
                                            </div>
                                        </div>
                                        <div>
                                            {privacyRequest ?
                                                <div style={{paddingTop: 20}}>
                                                    Requested At: <strong>{FormatDateTime(privacyRequest.requestedAt)}</strong>
                                                </div>
                                            :
                                                <Button variant="contained" color="secondary" onClick={() => setOpenConfirmPrivacyDialog(true)}>
                                                    Request
                                                </Button>
                                            }
                                        </div>
                                    </div>

                                    <div style={{display: "flex", marginTop: 32}}>
                                        <div style={{flexGrow: 1}}>
                                            <div>
                                                <strong>Data Delete Request</strong>
                                            </div>
                                            <div>
                                                <span>By requesting to delete this candidate's data, all information stored on the candidate will be deleted.</span>
                                            </div>
                                        </div>
                                        <div>
                                            {deleteRequest ?
                                                <div style={{paddingTop: 20}}>
                                                    Requested At: <strong>{FormatDateTime(deleteRequest.requestedAt)}</strong>
                                                </div>
                                            :
                                                <Button variant="contained" color="secondary" onClick={() => setOpenConfirmDeleteDialog(true)}>
                                                    Request
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    
                        <ConfirmDialog
                            open={openConfirmPrivacyDialog}
                            handleDialog={setOpenConfirmPrivacyDialog}
                            dialogTitle="Data Privacy Request"
                            dialogDescription="Are you sure you want to request all data collected from this candidate's account?"
                            onSubmit={privacyOnSubmit}
                            loading={privacyLoading}
                            setLoading={setPrivacyLoading}
                            error={privacyError}
                            setError={setPrivacyError} />

                        <ConfirmDialog
                            open={openConfirmDeleteDialog}
                            handleDialog={setOpenConfirmDeleteDialog}
                            dialogTitle="Data Delete Request"
                            dialogDescription="Are you sure you want to request to delete all data collected from this candidate's account?"
                            onSubmit={deleteOnSubmit}
                            loading={deleteLoading}
                            setLoading={setDeleteLoading}
                            error={deleteError}
                            setError={setDeleteError} />
                    </div>
                }
            </TabPanel>

            <TabPanel value={selectedTab} index={1}>
                <div id="applications-div" style={{marginTop: 8, marginBottom: 8}}>
                    <ApplicationsTable
                        isCandidate={true}
                        applications={applications}
                        user={user}
                        refreshData={getCandidateData} status={''}                    />
                </div>
            </TabPanel>

            <TabPanel value={selectedTab} index={2}>
                <CandidateInterviews active={interviewTabActive} loading={loading} setLoading={setLoading} candidateId={candidateId} user={user} accordionExpanded={accordionExpanded} candidateDetail={candidateData?.detail} />
                
            </TabPanel>

            <TabPanel value={selectedTab} index={3}>
                <div style={{display: "flex", marginBottom: 16, marginTop: 16}}>
                    <div style={{flexGrow: 1}}></div>
                    <div>
                        <Button variant="contained" color="primary" onClick={() => handleSkillsDialog(true)}>
                            <Add />
                            Add Skills
                        </Button>
                    </div>
                </div>
                
                {!loading &&
                    <div>
                        <Card>
                            <CardHeader title="Skills"/>
                            <CardContent>
                                {candidateSkills.length !== 0 && candidateSkills.map((skillGroup: SkillGroup) => (
                                    <Card key={skillGroup.Group} style={{marginTop: 8}} raised>
                                        <CardHeader 
                                            title={skillGroup.Group} 
                                            titleTypographyProps={{variant: "h6"}}/>

                                        <CardContent>
                                            {skillGroup.Skills.length !== 0 && skillGroup.Skills.map((skill: string) => (
                                                <Chip 
                                                    key={skill} 
                                                    className={classes.skillItemChip}
                                                    label={skill}
                                                    clickable={false}
                                                    deleteIcon={<Cancel style={{color: "#f57f17"}}/>}
                                                    onDelete={() => handleOpenDeleteCandidateSkill(skill, skillGroup.Group)}/>
                                            ))}
                                            {skillGroup.Skills.length === 0 && 
                                                <div style={{marginLeft: 8}}>
                                                    No skills found.
                                                </div>
                                            }
                                        </CardContent>
                                    </Card>
                                ))}
                                {candidateSkills.length === 0 && 
                                    <p>No skills found.</p>
                                }
                            </CardContent>
                        </Card>
                    </div>
                }

                <DeleteSkillDialog 
                    openDialog={openDeleteSkillDialog} 
                    handleDeleteDialog={handleDeleteCandidateSkill} 
                    candidateId={candidateId} 
                    skill={skillToDelete} 
                    group={selectedGroup} 
                    getCandidateSkills={getCandidateSkills}
                    user={user}/>

                <SkillsDialog 
                    openDialog={openSkillsDialog} 
                    handleSkillDialog={handleSkillsDialog} 
                    candidateId={candidateId} 
                    getCandidateSkills={getCandidateSkills} 
                    user={user}/>

            </TabPanel>

                {/* Notes Tab */}
            <TabPanel value={selectedTab} index={4}>
                <CandidateNotesTab active={interviewTabActive}  candidateId={candidateId} user={user}  />
                
            </TabPanel>

                {/* History Tab */}
            <TabPanel value={selectedTab} index={5} >
                <div>
                    {candidateHistory.length !== 0 && 
                        <Card style={{marginTop: 16}}>
                            <CardHeader title="History Timeline"/>
                            <CardContent>
                                <Timeline align="alternate">
                                    {candidateHistory.length !== 0 && candidateHistory.map((item: CandidateHistoryDto, index: number) => (
                                        <TimelineItem key={index}>
                                            <TimelineSeparator>
                                            <TimelineDot style={{backgroundColor: GetHistoryItemColor(item.historyType)}}>
                                                {GetHistoryItemIcon(item.historyType)}
                                            </TimelineDot>
                                            {index !== candidateHistory.length - 1 && 
                                                <TimelineConnector />
                                            }
                                            </TimelineSeparator>
                                            <TimelineContent>
                                            <Paper elevation={3} style={{padding: '6px 16px'}}>
                                                <Typography variant="h6" component="h1">
                                                    {item.historyType}
                                                </Typography>
                                                <Typography variant="body1" component="h1">
                                                    {item.description}
                                                </Typography>
                                                <Typography variant="caption">
                                                   {`${item.createdBy} - ${FormatDateTime(item.createdAt)}`}
                                                </Typography>
                                            </Paper>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            </CardContent>
                        </Card>
                    }
                </div>
            </TabPanel>
            
        </div>
    )
}