import React, { useEffect, useState } from 'react';
import AppShell from './components/AppShell';
import Container from "@material-ui/core/Container"
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import JobApplications from "./routes/applications/JobApplications";
import CandidateDetail from './routes/candidates/CandidateDetail';
import Candidates from './routes/candidates/Candidates';
import Admin from "./routes/admin/Admin";
import Jobs from "./routes/jobs/Jobs";
import JobDetails from "./routes/jobs/JobDetails";
import DataPrivacy from "./routes/DataPrivacy";
import Interviews from "./routes/interviews/Interviews";

import { Auth } from "aws-amplify";
import { Authenticator, Button, Heading, useAuthenticator, useTheme, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import FastTrackQueue from './routes/FastTrackQueue';

function App() {

  // const [user, setUser] = useState<any | null>(null)

  // useEffect(() => {
    
  //   getUser().then((user: any) => {
  //     setUser(user);
  //   });

  // }, []);

  //   async function getUser() {
  //     try {
  //           const userData = await Auth.currentAuthenticatedUser();
  //           return userData;
  //       } catch (e) {

  //           return console.log('Not signed in');
  //       }
  //   }


  

  return (
    <Authenticator hideSignUp={true} variation="modal" >
      {({ signOut, user }) => (
        
        <Router>
          <AppShell user={user} signOut={signOut}>
            <Container maxWidth="xl">
              <Switch>
                <Route path="/interviews">
                  <Interviews user={user} />
                </Route>
                <Route path="/fasttrack-requests">
                  <FastTrackQueue user={user} />
                </Route>
                <Route path="/data-privacy">
                  <DataPrivacy user={user} />
                </Route>
                <Route path="/jobs/:jobRef">
                  <JobDetails user={user} />
                </Route>
                <Route path="/jobs">
                  <Jobs user={user} />
                </Route>
                <Route path="/admin">
                  <Admin user={user} />
                </Route>
                <Route path="/candidates/:candidateId">
                  <CandidateDetail user={user} />
                </Route>
                <Route path="/candidates" component={Candidates} />
                <Route path="/">
                  <JobApplications user={user} />
                </Route>
              </Switch>
            </Container>
          </AppShell>
        </Router>
      )}
    </Authenticator>
    

  );
}

export default App;